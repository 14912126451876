import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { GridSearchResponse } from '../../grid/models/grid-search-response.model';
import { InnerGridApi } from '../../mass-pay/entity/models/consts/inner-grid-api.const';
import { MessagesType } from '../models/enums/messages-type.enum';
import { GridApiRoutes } from '../../grid/models/enums/grid-api.routes';
import { InnerGridTabs } from '../../mass-pay/entity/models/enums/inner-grid-tabs.enum';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { MatchingRequest } from '../components/messages-matching/models/matching-request.model';
import { MatchingType } from '../components/messages-matching/models/matching-type.model';
import { GridApiService } from '../../grid/services/grid-api.service';
import { GridColumn } from '@fgpp-ui/components';
import { HttpParams } from '@angular/common/http';
import { unmemoize, unmemoizeKeyListPrefix } from '../../core/services/memoized.service';
import { GridApiCacheService } from '../../grid/services/grid-api-cache.service';

@Injectable()
export class MessagesApiService extends GridApiService {

  getInnerAllGridData(searchRequest: GridSearchRequest, messagesType: MessagesType, id: string): Observable<Array<GridSearchResponse>> {
    const requests = [];
    (InnerGridApi[messagesType].api).forEach(tab => {
      const request = this.getInnerGridData(searchRequest, messagesType, id, tab);
      requests.push(request);
    });
    return forkJoin<Array<GridSearchResponse>>(requests);
  }

  getInnerGridData(searchRequest: GridSearchRequest, messagesType: MessagesType, id: string, tab: string): Observable<GridSearchResponse> {
    let url;
    if (tab === InnerGridTabs.LINKED_FILES && messagesType === MessagesType.BATCHES) {
      url = `${GridApiRoutes.do}/${messagesType}/${id}/${tab}/null/${GridApiRoutes.search}`;
    } else {
      url = `${GridApiRoutes.do}/${messagesType}/${id}/${tab}/${GridApiRoutes.search}`;
    }
    return this.http.post<GridSearchResponse>(url, searchRequest);
  }

  matchingTypes(queueType: MessagesType, uid: string): Promise<Array<MatchingType>> {
    return this.http.get<Array<MatchingType>>(`${GridApiRoutes.do}/${queueType}/${uid}/matchingTypes`).toPromise();
  }

  createMatch(queueType: MessagesType, uid: string, matchingTypeId: string, matchedUid: string[], body: MatchingRequest): Promise<void> {
    return this.http.post<void>(`${GridApiRoutes.do}/${queueType}/${uid}/createMatch`, body).toPromise();
  }

  @unmemoize({
    keyResolver: ({ }: any, baseUrl: string, httpParams?: HttpParams) =>
      GridApiCacheService.getCacheKeyGridColumns(baseUrl, httpParams)
  })
  saveColumns(gridColumns: GridColumn[], baseUrl: string, httpParams?: HttpParams,applyAllQueues=false): Promise<void> {
    if(applyAllQueues){
      return this.saveColumnsApplyAll(gridColumns, baseUrl, httpParams);
    }
    return super.saveColumns(gridColumns, baseUrl, httpParams);
  }

  @unmemoizeKeyListPrefix({
    keyResolver: ({ }: any, baseUrl: string, httpParams?: HttpParams) =>
      GridApiCacheService.getCacheMessagesKeyGridColumns(baseUrl, httpParams)
  })
  saveColumnsApplyAll(gridColumns: GridColumn[], baseUrl: string, httpParams?: HttpParams): Promise<void> {
    httpParams = new HttpParams().set('queueId','*');
    return super.saveColumns(gridColumns, baseUrl, httpParams);
  }

  @unmemoize({
    keyResolver: (baseUrl: string, httpParams?: HttpParams) =>
      GridApiCacheService.getCacheKeyGridColumns(baseUrl, httpParams)
  })
  deleteColumnSettings(baseUrl: string, httpParams?: HttpParams): Promise<void> {
    return super.deleteColumnSettings(baseUrl, httpParams);
  }

  multiSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string): Promise<any> {
    return this.http.post(`${baseUrl}${GridApiRoutes.multiSearch}`, searchRequest).toPromise();
  }

}
