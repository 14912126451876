import { Injectable } from '@angular/core';
import { MessageGridColumnsService } from './message-grid-column.service';
import { GridColumn } from '@fgpp-ui/components';
import { ColumnTypes } from '@fgpp-ui/components';

@Injectable()
export class MessageMatchingGridColumnService extends MessageGridColumnsService {

  get matchingTypeColumn(): GridColumn {
    return {
      'type': ColumnTypes.TEXT,
      'name': 'MATCHING_TYPE',
      'displayName': 'Matching Type',
      'pinned': false,
       'sort': null,
      'sortable': false,
      'width': 150,
      'refinable': false,
      'facetable': false,
      'presentAsIcon': false
    };
  }
}
