import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainViewComponent } from '../base-view/components/main-view/main-view.component';
import { TabTaskBarResolver } from '../resolvers/tab-task-bar.resolver';
import { TaskBarItemComponentResolver } from '../resolvers/task-bar-item-component-resolver';
import { PowerBiReportsComponent } from '../insight/power-bi/components/power-bi-reports/power-bi-reports.component';
import { ArchiveComponent } from './archive.component';
import { MainTab } from '../core/navigation/models';
import { Entity } from '../shared/models/enums/entity.enum';
import { LoadTypeResolver } from '../profiles/routing/resolvers/load-type.resolver';
import { NavigationTreeResolver } from '../profiles/routing/resolvers/navigation-tree.resolver';
import { InsightTaskbarGuard } from '../insight/guards/insight-task-bar.guard';
import { liquidityRoutes } from '../liquidity/routing/liquidity-routing.module';
import { RedirectTaskBarItemGuard } from '../profiles/routing/guards/redirect-task-bar-item.guard';
import { RedirectRulesGuard } from '../rules/routing/guards/redirect-rules.guard';
import { RedirectArchiveGuard } from './guards/redirect-archive.guard';
import { NavigationGuardService } from '../core/navigation/services/navigation-guard.service';

export const ArchiveRoutes: Routes = [
  { path: '',
    component: MainViewComponent,
    data: {
      tab: MainTab.ARCHIVE,
    },
    // canActivate: [RedirectArchiveGuard]
  // },
  // {
  //   path: ':taskBarItem',
  //   component: MainViewComponent,
  //   data: {
  //     tab: MainTab.ARCHIVE,
  //     type: Entity.ARCHIVE
  //   },
    resolve: {
      taskBar: TabTaskBarResolver,
      // taskBarComponent: TaskBarItemComponentResolver,
      // 'navigationTree': NavigationTreeResolver,
    },
    children: [
      {
        path: '',
        component: ArchiveComponent,
        canActivate: [NavigationGuardService],
        runGuardsAndResolvers: 'always',
      },
    ]
  },
];
@NgModule({
  imports: [
    RouterModule.forChild(ArchiveRoutes)
  ],
  providers: [

  ],
  exports: [RouterModule]
})
export class ArchiveRoutingModule { }
