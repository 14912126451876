import { AccountGroupsComponent } from '../profiles/components/account-groups/account-groups.component';
import { ErrcodesComponent } from '../profiles/components/errcodes/errcodes.component';
import { TransferMethodsComponent } from '../profiles/components/transfer-methods/transfer-methods.component';
import { AdvisingComponent } from '../profiles/components/advising/advising.component';
import { IdentifiersComponent } from '../profiles/components/identifiers/identifiers.component';
import { MaskingComponent } from '../profiles/components/masking/masking.component';
import { StandingOrderComponent } from '../profiles/components/standing-order/standing-order.component';
import { DepartmentComponent } from '../profiles/components/department/department.component';
import { MessageCodewordsComponent } from '../profiles/components/message-codewords/message-codewords.component';
import { DrawdownComponent } from '../profiles/components/drawdown/drawdown.component';
import { SystemParametersComponent } from '../profiles/components/system-parameters/system-parameters.component';
import { ReasonsComponent } from '../profiles/components/reasons/reasons.component';
import { UserVacationComponent } from '../profiles/components/user-vacation/user-vacation.component';
import {CountryInfoComponent} from "../profiles/components/country-info/country-info.component";
import {GenericProfileComponent} from "../profiles/components/generic-profile/generic-profile.component";
import {
  AccountAdditionalOwnersComponent
} from '../profiles/components/account-additional-owners/account-additional-owners.component';
import { UniqueIdFormatComponent } from '../profiles/components/unique-id-format/unique-id-format.component';
import {GenericTaskComponent} from "../tasks/components/generic-task/generic-task.component";
import { CurrencyCfgComponent } from '../profiles/components/currency-cfg/currency-cfg.component';
import { BillingComponent } from '../profiles/components/billing/billing.component';
import { CompanyComponent } from '../profiles/components/company/company.component';
import { PaymentAddressComponent } from '../profiles/components/payment-address/payment-address.component';
import {TimeZonesComponent} from "../profiles/components/time-zones/time-zones.component";
import { CutoffTimesComponent } from '../profiles/components/cutoff-times/cutoff-times.component';
import {ParticipantAvailabilityComponent} from '../profiles/components/participant-availability/participant-availability.component';
import {DocumentTemplateComponent} from "../profiles/components/document-template/document-template.component";
import {InterfaceTypesComponent} from "../profiles/components/interface-types/interface-types.component";
import {CallerComponent} from "../profiles/components/caller/caller.component";
import {
  CallerAssociatedAccountComponent
} from "../profiles/components/caller-associated-account/caller-associated-account.component";


export class ProfileComponentLoader {

  public static readonly profileCodeToComponent = {
    '4': UserVacationComponent,
    '520': AccountGroupsComponent,
    '82': TransferMethodsComponent,
    '443': ErrcodesComponent,
    '444': AdvisingComponent,
    '53': ReasonsComponent,
    '40': GenericProfileComponent,
    '16': StandingOrderComponent,
    //'39': RateUsageComponent, GPD-172808 disable this line until full implementation of the new profile
    '188': IdentifiersComponent,
    '659': MaskingComponent,
    '46': DepartmentComponent,
    '449': MessageCodewordsComponent,
    '458': AccountAdditionalOwnersComponent,
    '113': DrawdownComponent,
    '55': GenericProfileComponent,
    '114': GenericProfileComponent,
    '54': SystemParametersComponent,
    '202': GenericTaskComponent,
    '50': CountryInfoComponent,
    '51': CurrencyCfgComponent,
    '461': GenericProfileComponent,
    '78': GenericProfileComponent,
    '693': UniqueIdFormatComponent,
    '75': GenericProfileComponent,
    '45': GenericProfileComponent,
    '212': GenericTaskComponent,
    '213': GenericTaskComponent,
    '250' : GenericTaskComponent,
    '251' : GenericTaskComponent,
    '252' : GenericTaskComponent,
    '253': GenericTaskComponent,
    '254': GenericTaskComponent,
    '255': GenericTaskComponent,
    '200': BillingComponent,
    '284': GenericTaskComponent,
    '210': GenericTaskComponent,
    '211': GenericTaskComponent,
    '285': GenericTaskComponent,
    '442': GenericTaskComponent,
    '459': CompanyComponent,
    '482': GenericTaskComponent,
    '946': GenericTaskComponent,
    '951': GenericTaskComponent,
    '619': GenericTaskComponent,
    '588': PaymentAddressComponent,
    '183': TimeZonesComponent,
    '26': CutoffTimesComponent,
    '593': GenericProfileComponent,
    '586': ParticipantAvailabilityComponent,
    '952': DocumentTemplateComponent,
    '288': CallerAssociatedAccountComponent,
    '456': InterfaceTypesComponent,
    '287': CallerComponent,
    '963': GenericTaskComponent
  };

/*  public static getProfileComponent(profileId, taskCode): ComponentType<NewAbstractProfileFormComponent> {
    let component = ProfileComponentLoader.profileCodeToComponent[profileId];
    if (component == null && !taskCode) {
      component = GenericProfileComponent;
    } else if (taskCode && !component) {
      component = GenericTaskComponent;
    }
    return component;
  }*/

  public static isMigratedProfile(profileId: string): boolean {
    return !!ProfileComponentLoader.profileCodeToComponent[profileId];
  }
}
