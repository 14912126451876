import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IActivityTrackerMapper } from './interfaces/activity-tracker-mapper.interface';
import {ActivityLogItemResponse} from "../../../common/models/activity-log-item-response.model";
import {RuleActivityLogItem} from "../../../common/models/rule-activity-log-item.model";
import {ActivityLogType} from "../../../common/models/enums/activity-log-type.enum";
import {ActivityLogItem} from "../../../common/models/activity-log-item.model";
import {AuditActivityLogItem} from "../../../common/models/audit-activity-log-item.model";
import {ErrorActivityLogItem} from "../../../common/models/error-activity-log-item.model";


@Injectable({
  providedIn: 'root'
})
export class ActivityTrackerMapperService implements IActivityTrackerMapper {

  constructor( protected translateService: TranslateService) { }

  mapActivityLogItemResponseList(activityLogItemResponseList: Array<ActivityLogItemResponse>): Array<ActivityLogItem> {
    return activityLogItemResponseList.map(activityLogItemResponse => {
      switch ( activityLogItemResponse.source ) {
        case ActivityLogType.ERROR:
          return ActivityTrackerMapperService.mapErrorActivityLogItemResponse(activityLogItemResponse);
        case ActivityLogType.RULE:
          return this.mapRuleActivityLogItemResponseToAudit(activityLogItemResponse);
        case ActivityLogType.AUDIT:
          return ActivityTrackerMapperService.mapAuditActivityLogItemResponse(activityLogItemResponse);
      }
    });
  }

  private mapRuleActivityLogItemResponseToAudit( activityLogItemResponse: ActivityLogItemResponse): RuleActivityLogItem {
    const ruleName = activityLogItemResponse.name;
    const typeName = activityLogItemResponse.typeName;
    const actionName = activityLogItemResponse.actionName;
    return {
      code: activityLogItemResponse.typeId,
      date: activityLogItemResponse.date,
      type: activityLogItemResponse.source,
      description: this.translateService.instant('activityTracker.rule.description', { ruleName, typeName, actionName })
    };
  }

  private static mapAuditActivityLogItemResponse(activityLogItemResponse: ActivityLogItemResponse): AuditActivityLogItem {
    return {
      date: activityLogItemResponse.date,
      type: activityLogItemResponse.source,
      description: activityLogItemResponse.description,
      userName: activityLogItemResponse.user,
      detailsOfChange: activityLogItemResponse.detailsOfChange
    }
  }

  private static mapErrorActivityLogItemResponse( activityLogItemResponse: ActivityLogItemResponse): ErrorActivityLogItem {
    return {
      code: activityLogItemResponse.code,
      date: activityLogItemResponse.date,
      type: activityLogItemResponse.source,
      description: activityLogItemResponse.description,
    };
  }
}
