import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NavigationTabConfig } from '../../core/navigation/models';
import { MenuService } from '../../core/services/menu.service';

@Injectable({ providedIn: 'root' })
export class NavigationTabsResolver implements Resolve<Array<NavigationTabConfig>> {

  constructor(private menuService: MenuService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Array<NavigationTabConfig> {
    const items = this.menuService.getMenuItem('main').items;
    const keys = Object.keys(items);
    keys.sort((key1: string, key2: string) => items[key1].position - items[key2].position);

    return keys.map((key: string, index: number) => {
      const item = items[key];
      return {
        id: key,
        alias: item.alias,
        icon: item.icon,
        width: 0,
        index: index,
        routerLink: item.routerLink,
        stateName: item.stateName
      };
    });
  }
}
