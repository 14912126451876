import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ProfilesNavigationService} from "../../services/profiles-navigation.service";
import {ProfilesTree} from "../../../core/navigation/sitemap/models";

@Injectable({ providedIn: 'root' })
export class NavigationTreeResolver implements Resolve<ProfilesTree> {
  constructor(private profilesNavigationService: ProfilesNavigationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<ProfilesTree>|Promise<ProfilesTree>|ProfilesTree {
    return this.profilesNavigationService.selectProfiles(route.data.tab, route.paramMap.get('taskBarItem') || route.routeConfig.path);
  }
}
