import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TaskBarService } from '../core/navigation/task-bar/services/task-bar.service';

@Injectable({ providedIn: 'root' })
export class TabTaskBarResolver implements Resolve<any> {

  constructor(private taskBarService: TaskBarService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    const tab = route.parent.routeConfig.path;
    return this.taskBarService.getTaskBar(tab);
  }

}
