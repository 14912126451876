import { Injectable } from '@angular/core';
import { Formats } from '../models/formats.model';
import { ColumnTypes } from '@fgpp-ui/components';
import { Formatters } from '../../grid/models/formatters.model';
import { DEFAULT_FORMATS } from '../models/default-formats.const';
import dayjs, { Dayjs } from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(CustomParseFormat);

export const TRUE_ICON = 'check';
export const FALSE_ICON = 'close';

export const POINT = '.';
export const COMMA = ',';
export const EMPTY = '';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  private _formats: Formats = { ...DEFAULT_FORMATS };
  private _formatters: Formatters;
  private readonly timeFormats = ['HH:mm:ssa', 'HH:mma', 'HH:mm:ss', 'HHmmss', 'HH:mm', 'HHa', 'HH'];

  get dateFormat(): string {
    return this.formats.dateFormat;
  }

  get timeFormat(): string {
    return this.formats.timeFormat;
  }

  get datetimeFormat(): string {
    return this.dateFormat + ' ' + this.timeFormat;
  }

  get decimalSeparator(): string {
    return this.formats.decimalSeparator;
  }

  get thousandSeparator(): string {
    return this.formats.thousandSeparator;
  }

  get locale(): string {
    return this.formats.language;
  }

  get roundNumber(): boolean {
    return this.formats.roundNumber;
  }

  get itemsPerPage(): number {
    return this.formats.itemsPerPage;
  }

  get timeMode(): number {
    return this.formats.timeMode;
  }

  get formats(): Formats {
    return this._formats;
  }

  constructor() {
    this._formatters = this.getFormatters();
  }

  getFormatter(columnType: ColumnTypes) {
    return this._formatters[columnType];
  }

  toNumberFormat(value: any, precision?: string, maxNumber?: number): string {
    const isPrecisionZero = precision === '0';
    if (isNaN(parseInt(value))) {
      return value || EMPTY;
    }
    let result = this.addThousandSeparator(value);
    if (parseInt(precision) || isPrecisionZero) {
      result = this.updatePrecision(result, precision, maxNumber, isPrecisionZero);
    }
    return result;
  }

  toDateFormat(value: Date | Dayjs | string): string {
    return !!value ? dayjs(value).format(this.dateFormat.toUpperCase()) : EMPTY;
  }

  toDateTimeFormat(value: Date | Dayjs | string): string {
    const datetimeFormat = `${this.dateFormat.toUpperCase()} ${this.timeFormat}`;
    return !!value ? dayjs(value).format(datetimeFormat) : EMPTY;
  }

  toTimeFormat(value: Date | Dayjs | string): string {
    return !!value ? dayjs(value, this.timeFormats).format(this.timeFormat) : EMPTY;
  }

  toBooleanFormat(value: any): string {
    return value && value !== '0' ? TRUE_ICON : FALSE_ICON;
  }

  toNumber(value: string): number {
    if (value) {
      value = value.toString().split(this.thousandSeparator).join(EMPTY)
        .replace(this.decimalSeparator, POINT);
    }
    return Number(value);
  }

  toISOString(value: Dayjs): string {
    return value.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  }

  private addThousandSeparator(value: any): string {
    value = value.toString().replace(COMMA, EMPTY);
    const parts = value.split(POINT);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);
    return parts.join(this.decimalSeparator);
  }

  private updatePrecision(value: string, precision: string, maxNumber: number, isPrecisionZero?: boolean) {
    const numOfDigitsBeforePrecision = 1;
    const decimalSeparatorIdx = value.indexOf(this.decimalSeparator);
    const newPrecision = parseInt(precision);
    if (decimalSeparatorIdx === -1 && (!maxNumber || value.length <= (maxNumber - newPrecision - 1))) {
      if (!isPrecisionZero) {
        return value + this.decimalSeparator + '0'.repeat(newPrecision);
      }
      return value;
    } else if (decimalSeparatorIdx === 0 && (!maxNumber || value.length <= (maxNumber - numOfDigitsBeforePrecision))) {
      return '0'.repeat(numOfDigitsBeforePrecision) + value;
    } else {
      const numOfRemainingDecimalDigits = newPrecision - value.substr(decimalSeparatorIdx + 1).length;
      if (numOfRemainingDecimalDigits > 0 && (!maxNumber || value.length + numOfRemainingDecimalDigits <= maxNumber)) {
        return value + '0'.repeat(numOfRemainingDecimalDigits);
      } else {
        if (numOfRemainingDecimalDigits < 0) {
          value = this.toNumber(value).toFixed(newPrecision);
           return this.addThousandSeparator(value);
        }
      }
      return value;
    }
  }

  private getFormatters(): Formatters {
    return {
      NUMBER: this.toNumberFormat.bind(this),
      DECIMAL: this.toNumberFormat.bind(this),
      NUM_NO_DEC: this.toNumberFormat.bind(this),
      TIME: this.toTimeFormat.bind(this),
      DATE_TIME: this.toDateTimeFormat.bind(this),
      DATE: this.toDateFormat.bind(this),
      BOOL: this.toBooleanFormat.bind(this)
    };
  }

  dateParser(value: string): Date {
    const reggie = /(((\d{4})-(\d{2})-(\d{2}))|((\d{2})-(\d{2})-(\d{4})))( (\d{2}):(\d{2}):(\d{2}))*/;
    const dateArray = reggie.exec(value);
    if (dateArray == null) {
      return;
    }
    const dateTimeArray: string[] = value.split(' ');
    const dateStr: string = dateTimeArray[0];
    const timeStr: string = dateTimeArray[1];
    const dateArr: any[] = dateStr.split('-');
    let timeArr: any[] = [null, null, null];
    if (typeof timeStr !== 'undefined') {
      timeArr = timeStr.split(':');
    }
    const fourDigitsRegex = /(\d{4})/;
    const twoDigitsRegex = /(\d{2})/;

    if (fourDigitsRegex.exec(dateArr[0])) {
      return new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], timeArr[2]);
    } else if (twoDigitsRegex.exec(dateArr[0])) {
      return new Date(dateArr[2], dateArr[1] - 1, dateArr[0], timeArr[0], timeArr[1], timeArr[2]);
    }
  }

  parseDate(value: Date | string): any {
    let dateObject = value;

    const isInvalidDate = (date: Date | string): boolean => {
      const _date = date.toString();
      return ((_date === 'Invalid Date') || (_date.indexOf('undefined') !== -1) || (_date.indexOf('NaN') !== -1));
    };
    if (typeof (value) === 'string') {
      try {
        dateObject = this.dateParser(value);
        if (isInvalidDate(dateObject)) {
          dateObject = new Date(value);
        }
        if (isInvalidDate(dateObject)) {
          dateObject = value;
        }
      } catch (e) {
        dateObject = value;
      }
    }
    return dateObject;
  }
}
