import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableRow } from '@fgpp-ui/components';
import { take } from 'rxjs';
import { RuleCommonService } from '../../../rules/single-rule/services/rule-common.service';
import { AuditTrailsRulesProfilesManagementService } from '../../services/audit-trails-rules-profiles-management.service';

@Component({
  selector: 'app-audit-trail-rules-container',
  templateUrl: './audit-trail-rules-container.component.html',
  styleUrls: ['./audit-trail-rules-container.component.scss']
})
export class AuditTrailRulesContainerComponent implements OnInit {

  @Input() ruleId;
  @Input() ruleProfileId;
  @Output() rowClicked = new EventEmitter<TableRow>();

  constructor(private ruleCommonService: RuleCommonService,
              public auditTrailsRulesProfilesManagementService: AuditTrailsRulesProfilesManagementService) {
  }

  ngOnInit() {
    this.auditTrailsRulesProfilesManagementService.resetSearchRequest();
    this.ruleCommonService.auditTrailVisible.pipe(take(1)).subscribe(() => {
      this.auditTrailsRulesProfilesManagementService.ruleId = this.ruleId;
      this.auditTrailsRulesProfilesManagementService.ruleProfileId = this.ruleProfileId;
      this.auditTrailsRulesProfilesManagementService.resetGridData();
      this.auditTrailsRulesProfilesManagementService.invokeGridDataChange(true);
    });
  }

  toggleAuditTrail() {
    this.ruleCommonService.toggleAuditTrail();
  }

  onRowClick(row: TableRow) {
    this.rowClicked.emit(row);
  }
}

