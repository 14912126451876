import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileAttachmentManagerComponent } from '../../../../../../attachment/src/public-api';
import { FileAttachmentApiService } from './services/file-attachment-api.service';
import { UploaderRequestHeaders } from '../../../../../../attachment/src/lib/file-attachment/file-attachment-form/models/uploader-request-headers.model';
import { FileAttachmenConfiguration } from '../../../../../../attachment/src/lib/file-attachment-manager/models/file-attachment-configuration.model';
import { FileAttachmentMapping } from './models/file-attachment-mapping.model';
import { FileAttachmentConfigService } from './services/file-attachment-config.service';
import { FileStatus } from '../../../../../../attachment/src/lib/file-attachment/file-attachment-form/models/enums/file-status.enum';
import { FileAttachmentDialogOutput } from './models/file-attachment-dialog-output.model';
import { DialogFileAttachmentConfig } from './models/dialog-file-attachment-config.model';
import { FileAttachmentManagerComponentConfig } from '../../../../../../attachment/src/lib/file-attachment-manager/models/file-attachment-manager-component-config.model';
import { SourceDetails } from './models/source-details.model';
import { FileUploaderConfiguration } from '../../../../../../attachment/src/lib/file-attachment/file-attachment-form/models/file-uploader-configuration.model';
import { FileAttachmentDialogMapperService } from './services/file-attachment-dialog-mapper.service';
import { TextBuilderFileAttachmentDialogService } from './services/texts-builder-file-attachment-dialog.service';
import { FileAttachmentManagerTexts } from '../../../../../../attachment/src/lib/file-attachment-manager/models/file-attachment-manager-texts.model';
import { FileTranslationService } from '../../../core/translations/services/file-translation.services';
import { FileMimeType } from '../../models/enums/file-mime-type.enum';
import { SpinnerComponent } from '../../../ui-components/spinner/spinner.component';
import { FormatsService } from '../../services/formats.service';
import { ColumnTypes } from '@fgpp-ui/components';
import { AuthenticationService } from '../../../authentication/services/authentication.service';

@Component({
    selector: 'app-file-attachment-dialog',
    templateUrl: './file-attachment-dialog.component.html',
    styleUrls: ['./file-attachment-dialog.component.scss']
})
export class FileAttachmentDialogComponent implements OnInit {

    @ViewChild('manager') fileAttachmentManagerComponent: FileAttachmentManagerComponent;
    fileAttachmentsList: Array<FileAttachmentMapping>;
    fileAttachmenConfig: FileAttachmenConfiguration;
    showConfirmView = false;
    attachmentChanged = false;
    texts: FileAttachmentManagerTexts;
    spinnerComponent = SpinnerComponent;
    constructor(public dialogRef: MatDialogRef<FileAttachmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogFileAttachmentConfig,
        protected authenticationService: AuthenticationService,
        private formatService: FormatsService,
        private fileAttachmentApiService: FileAttachmentApiService,
        private fileAttachmentConfigService: FileAttachmentConfigService,
        private fileAttachmentDialogMapperService: FileAttachmentDialogMapperService,
        private textBuilderService: TextBuilderFileAttachmentDialogService,
        private fileTranslationService: FileTranslationService) {
            this.fileAttachmentsList = new Array<FileAttachmentMapping>();
        }

     ngOnInit(): void {
        const managerComponentConfig: FileAttachmentManagerComponentConfig = {
            ...this.data,
            fileAttachmentApiService: this.fileAttachmentApiService,
            dateFormatParser: this.formatService.getFormatter(ColumnTypes.DATE_TIME)
        };
        this.getAttachmentList().then(result => {
            this.fileAttachmentsList = result;
        });

        this.fileAttachmenConfig = {
            fileUploaderConfiguration: this.getUploaderConfig(),
            fileAttachmentManagerConfig: managerComponentConfig
        };

        this.initTexts();
     }


    onClose(): void {
        if (this.fileAttachmentManagerComponent.fileStatus === FileStatus.SELECTED) {
            this.showConfirmView = true;
            return;
        }

        this.sendAttachmentListData();
    }

    setAttachmentChanged() {
        this.attachmentChanged = true;
    }

    public sendAttachmentListData() {
        const attachmentsList = this.fileAttachmentDialogMapperService.mapFileAttachmentRowToFileAttachmentMapping(
            this.fileAttachmentManagerComponent.fileAttachmentsList,
            this.data.sourceUid,
            this.data.sourceTypeId);

        const dialogOutput: FileAttachmentDialogOutput = {
            fileAttachmentsList: attachmentsList,
            attachmentChanged: this.attachmentChanged
        };

        this.dialogRef.close(dialogOutput);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    returnToForm(): void {
        this.showConfirmView = false;
    }

    private getUploaderConfig(): FileUploaderConfiguration {
        return {
            ...this.fileAttachmentConfigService.getAttachmentConfiguration(),
            requestHeaders: this.createHeaders()
        };
    }

    private createHeaders(): Array<UploaderRequestHeaders> {
        const headers = [];
        const accessToken = this.authenticationService.accessToken;
        if (accessToken) {
            headers.push({ name: 'Authorization', value: accessToken });
        }
        headers.push({ name: 'session-id', value: this.authenticationService.sessionId });
        return headers;
    }

   private async getAttachmentList(): Promise<Array<FileAttachmentMapping>> {
       if (this.data.attachmentList && this.data.attachmentList.length > 0) {
           return this.data.attachmentList;
       }

       const sourceDetails: SourceDetails = {
            sourceUid: this.data.sourceUid,
            sourceTypeId: this.data.sourceTypeId
       };
      const result = await this.fileAttachmentApiService.getAttachmentList(sourceDetails).toPromise();
      return result.attachments;
   }

   private initTexts() {
        const fileSizeInMB = (this.fileAttachmenConfig.fileUploaderConfiguration.maxFileSizeInByte / 1024) / 1024;
        const fileTypes = this.buildFileTypes(this.fileAttachmenConfig.fileUploaderConfiguration.contentType);
        this.textBuilderService.fileSizeLimit = fileSizeInMB.toString();
        this.textBuilderService.fileTypes = fileTypes;
        this.texts = this.textBuilderService.buildTexts();
   }

   private buildFileTypes(conyentTypes: Array<any>): string {
        const fileTypes = [];
        conyentTypes.forEach( (item: FileMimeType) => {
            fileTypes.push(this.fileTranslationService.translate(item));
        });

        return fileTypes.join(', ');
   }
}
