import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, map, of } from 'rxjs';
import { Rule, RuleCriteria, RuleStatuses, RuleSubType } from '../../single-rule/models';
import { RuleCommonService } from '../../single-rule/services/rule-common.service';
import { RuleConditionsAlgorithmsService } from '../../single-rule/services/rule-conditions-algorithms.service';
import { RuleConditionsService } from '../../single-rule/services/rule-conditions.service';
import { RuleService } from '../../single-rule/services/rule.service';
import { RuleStatusesResolver } from './rule-statuses.resolver';

@Injectable()
export class RuleResolver implements Resolve<{ rule: Rule, statuses: RuleStatuses }> {

  constructor(private ruleCommonService: RuleCommonService,
              private ruleConditionsService: RuleConditionsService,
              private ruleConditionsAlgorithmsService: RuleConditionsAlgorithmsService,
              private ruleService: RuleService,
              private ruleStatusesResolver: RuleStatusesResolver) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ rule: Rule, statuses: RuleStatuses }> {
    let rule$: Observable<Rule>;
    if (route.queryParams.mode === 'create') {
      rule$ = of(this.createNewRule(route));
    } else {
      rule$ = this.ruleService.doGetRule(route.queryParams.ruleId, route.queryParams.loadType);
    }
    return rule$.pipe(map((rule: Rule) => {
      this.ruleCommonService.rule = rule;
      this.ruleCommonService.originalRule = JSON.parse(JSON.stringify(rule));
      const statuses = this.ruleStatusesResolver.resolve(route, state);
      return { rule, statuses };
    }));
  }

  private createNewRule(route: ActivatedRouteSnapshot): Rule {
    const newRule = {} as Rule;
    newRule.OFFICE = route.queryParams.office;
    newRule.CONDITIONS = {} as RuleCriteria;
    newRule.BASE_CONDITION_IND = 0;
    newRule.DEPARTMENT = '';
    newRule.DESCRIPTION = '';
    newRule.SEC_RULE_ACTION_UID = '0';
    newRule.RULE_ACTION_UID = '0';
    newRule.RULE_TYPE_ID = '';
    newRule.RULE_NAME = '';
    newRule.RULE_SUB_TYPE = '0';
    newRule.PROFILE_CHANGE_STATUS = 'PN';
    newRule.REC_STATUS = 'NW';
    newRule.conditionsBank = [];
    newRule.flatConditionsBank = [];
    newRule.actionDetails = [];
    newRule.actionDetails.actionContentSource = '';
    newRule.subType = { profileId: '' } as RuleSubType;

    this.ruleConditionsService.conditions = this.ruleConditionsAlgorithmsService.fromBinaryTreeToNTree(JSON.parse(JSON.stringify(newRule.CONDITIONS)));

    return newRule;
  }
}
