import { Component, ContentChild, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableRow } from '@fgpp-ui/components';
import { ConditionOperand, ConditionOperator, ConditionsBankNode, ContentType, LogicalField, OperandDataType, SimpleCondition } from '../../models';
import { AllowedTypesMap, InnerConditionsAllowedTypesMap } from '../../models/consts/rule-config.const';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleConditionsActionsService } from '../../services/rule-conditions-actions.service';
import { RuleConditionsService } from '../../services/rule-conditions.service';
import { RuleService } from '../../services/rule.service';
import { SimpleConditionLogicService } from '../../services/simple-condition-logic.service';

@Component({
  selector: 'app-simple-condition',
  templateUrl: './simple-condition.component.html',
  styleUrls: ['./simple-condition.component.scss']
})
export class SimpleConditionComponent implements OnInit {

  @Input() simpleCondition: SimpleCondition;

  @ContentChild('dragHandle', { static: true }) dragHandleTemplate: TemplateRef<ElementRef>;

  readonly rightOperandControls = {
    'drilldown': false,
    'input': false,
    'autocomplete': false,
    'select': false
  };
  leftOperandDataType: OperandDataType = undefined;
  rightOperandDataType: OperandDataType = undefined;
  isOperatorChangedOnInit = false;
  operatorList = [];
  rightOperandTypes = [];
  isLeftOperandValid: boolean;
  isRightOperandValid: boolean;
  operatorChangedOnInitAlertText = '';
  leftOperandInvalidMsg: string;
  rightOperandInvalidMsg: string;
  leftOperandFocus: boolean;
  isRightOperandInputDisabled: boolean;
  shouldPreventMultiSelect: boolean;
  drillDownSource: string;
  rightOperandInParamsFilter: { [key: string]: string };
  rightOperandOptions: Array<string>;

  private _isLeftOperandHasContentSource = false;
  private _isLeftOperandStartsWithColon = false;
  private _isRuleOpenedForEditOnFirstTime = false;
  private _previousLeftOperandType: string;

  constructor(private translateService: TranslateService,
              private ruleCommonService: RuleCommonService,
              public ruleConditionsService: RuleConditionsService,
              private ruleConditionsActionsService: RuleConditionsActionsService,
              private ruleService: RuleService,
              private simpleConditionLogicService: SimpleConditionLogicService) { }

  ngOnInit(): void {
    this.isLeftOperandValid = this.isOperandInvalid(this.simpleCondition.leftOperand, false);
    this.isRightOperandValid = this.isOperandInvalid(this.simpleCondition.rightOperand, true);
    this.leftOperandInvalidMsg = this.translateService.instant('rules.left_operand_invalid_msg', {
      lastOperator: this.simpleCondition.conditionOperator
    });
    this.rightOperandInvalidMsg = this.translateService.instant('rules.value_in_operand_is_invalid');
    this._previousLeftOperandType = this.simpleConditionLogicService.getOperandDataType(this.simpleCondition.leftOperand);
    this.showControlOnRightOperand('input');
    this.replaceOperatorIfNeeded();
    this.leftOperandChanged(this.simpleCondition.leftOperand);
  }

  onDrillDownValuesSelected(selectedRows: Array<TableRow>): void {
    const operand = this.simpleCondition.leftOperand as LogicalField;
    this.simpleCondition.rightOperand.alias =
      this.simpleConditionLogicService.extractOutputFromDrilldown(operand, selectedRows, this.drillDownSource);
    this.rightOperandChanged();
  }

  get keyId() {
    const operand = this.simpleCondition.leftOperand as LogicalField;
    return this.simpleConditionLogicService.getFieldValue(operand, this.drillDownSource);
  }

  leftOperandChanged(newValue: ConditionOperand | string): void {
    const oldValue = this.simpleCondition.leftOperand as ConditionOperand | string;
    this.simpleCondition.leftOperand = newValue as ConditionOperand;
    const fieldFromConditionBank = this.checkOnSpecialRightOperand(newValue as ConditionOperand);
    if (fieldFromConditionBank !== null) {
      newValue = fieldFromConditionBank;
      this.isLeftOperandValid = true;
    } else {
      this.isLeftOperandValid =
        this.isOperandInvalid(this.simpleCondition.leftOperand, false);
    }

    if (typeof this.simpleCondition.conditionOperator !== 'undefined') {
      //if conditionOperator is undefined it means that the previous change to the operator already changed the lastOperator in the error text.
      this.leftOperandInvalidMsg = this.translateService.instant('rules.left_operand_invalid_msg', {
        lastOperator: this.simpleCondition.conditionOperator
      });
    }
    let leftOperandTypeChanged = false;
    this.leftOperandFocus = false;

    if (newValue && (newValue as ConditionOperand).alias === '') {
      //An edge case in which drag and drop from left operand to right operand causes UI crash
      this.operatorList = [];
      this.simpleCondition.conditionOperator = null;
      this.clearRightOperand();
      this.showControlOnRightOperand('input');
      return;
    }


    const type = this.simpleConditionLogicService.getOperandDataType(newValue as ConditionOperand);
    if (type !== this._previousLeftOperandType) {
      leftOperandTypeChanged = true;
      this._previousLeftOperandType = type;
    }


    if (!this.checkEquals(newValue as ConditionOperand, oldValue as ConditionOperand) || leftOperandTypeChanged) {
      //can be false when we insert new row / open a rule for edit, on these cases we do not want to reset right operand
      this.clearRightOperand();
      this.showControlOnRightOperand('input');
    }

    if (newValue === oldValue && newValue !== '') {
      //the if statement is true when editing a rule
      this._isRuleOpenedForEditOnFirstTime = true;
    }

    if (newValue === oldValue && newValue === '') {
      setTimeout(() => this.leftOperandFocus = true);
    } else {
      this.leftOperandFocus = false;
    }

    this._isLeftOperandHasContentSource = false;
    this.operatorList =
      this.simpleConditionLogicService.getOperatorsForOperand(newValue as ConditionOperand); //will return a list filtered according to type and source

    //if current operator is not in the list of available operators reset it to the first item in the list
    if ((newValue !== oldValue) || this.operatorList.findIndex((operator) => operator.value === this.simpleCondition.conditionOperator) === -1) {
      this.simpleCondition.conditionOperator = (this.operatorList[0] || {}).value;
    }


    if (type === OperandDataType.BASE_CONDITION) {
      this.leftOperandDataType = OperandDataType.BASE_CONDITION;
    } else {
      this.leftOperandDataType = type;
    }

    if ((newValue as LogicalField).pruleContentsource != null) {
      this._isLeftOperandHasContentSource = true;
    }

    if (typeof this.simpleCondition.conditionOperator !== 'undefined') {
      this.adjustRightOperandAllowedTypes(this.leftOperandDataType);

      //We need timeout 0 in order to make it happen on the next render cycle
      //we have to clear the controls in right operand before we apply new one
      setTimeout(() => {
        this.adjustControlOnRightOperand(this.simpleCondition.conditionOperator);
        if (this._isRuleOpenedForEditOnFirstTime) {
          this._isRuleOpenedForEditOnFirstTime = false;
        }
      });
    }
  }

  /**
   *
   * @param newValue
   * @param oldValue
   * @returns {boolean}
   */
  private checkEquals(newValue: ConditionOperand, oldValue: ConditionOperand): boolean {
    if (typeof newValue !== 'object' || typeof oldValue !== 'object') {
      return newValue === oldValue;
    }
    if ((newValue as LogicalField).id == null || (oldValue as LogicalField).id == null) {
      return JSON.stringify(newValue) === JSON.stringify(oldValue);
    }
    const oldValueId = ((oldValue as LogicalField).id.indexOf(':') === 0) ? (oldValue as LogicalField).id.substring(1) : (oldValue as LogicalField).id;
    const newValueId = ((newValue as LogicalField).id.indexOf(':') === 0) ? (newValue as LogicalField).id.substring(1) : (newValue as LogicalField).id;

    return oldValueId === newValueId;
  }

  rightOperandChanged(newValue?: ConditionOperand): void {
    if (newValue && (newValue as ConditionsBankNode).contentType !== ContentType.VALUE) {
      this.simpleCondition.rightOperand = newValue;
    }
    this.isRightOperandValid = this.isOperandInvalid(this.simpleCondition.rightOperand, true);
  }

  private clearRightOperand(): void {
    this.showControlOnRightOperand(null);
    this.isRightOperandInputDisabled = false;
    this.simpleCondition.rightOperand = this.simpleConditionLogicService.getEmptyOperand();
  }

  private showControlOnRightOperand(control: string): void {
    for (const key in this.rightOperandControls) {
      if (this.rightOperandControls.hasOwnProperty(key)) {
        this.rightOperandControls[key] = false;
      }
    }

    if (control != null) {
      this.rightOperandControls[control] = true;
    }
  }

  private adjustControlOnRightOperand(operator: ConditionOperator | ''): void {
    this.shouldPreventMultiSelect = true; //we can't choose more than one row in drill down except for "in system list" operator

    if (this.simpleConditionLogicService.isInnerConditionsOperator(operator)) {
      if (!this.simpleCondition.innerConditions) {
        this.openInnerConditionBlock();
      }
      this.showControlOnRightOperand('autocomplete');
      return;
    } else {
      delete this.simpleCondition.innerConditions; //we need to remove this property in order for it to be created next time we'll choose matching operator
    }

    switch (operator.toLowerCase()) {
      case '=':
      case '<>':
        this.handleRightOperand();
        break;
      case 'is':
      case 'is not':
        this.simpleCondition.rightOperand.type = ContentType.VALUE;
        this.decideOnRightOperand();
        break;
      case 'between':
        this.simpleCondition.rightOperand.type = 'Between';
        this.showControlOnRightOperand('input');
        break;
      case 'in value list':
      case 'not in value list':
        if (this._isLeftOperandHasContentSource && this._isLeftOperandStartsWithColon && this.ruleService.execTypeBindWhere) {
          this.showControlOnRightOperand('autocomplete');
        } else {
          const valueListDrillDownParams = this.simpleConditionLogicService.getValueListParams();
          this.simpleCondition.rightOperand.type = 'InValueList';
          this.drillDownSource = valueListDrillDownParams.source;
          this.rightOperandInParamsFilter = valueListDrillDownParams.filter;
          this.showControlOnRightOperand('drilldown');
        }
        break;
      case 'in list':
      case 'not in list':
        this.simpleCondition.rightOperand.type = 'InList';
        this.handleRightOperand();
        break;
      case 'in':
      case 'not in':
      case 'in system list':
      case 'not in system list':
        if (this._isLeftOperandHasContentSource && this._isLeftOperandStartsWithColon && this.ruleService.execTypeBindWhere) {
          this.showControlOnRightOperand('autocomplete');
        } else {
          //Those operators are available only for left operands with content source
          this.simpleCondition.rightOperand.type = 'InList';
          this.shouldPreventMultiSelect = false; //we can't choose more than one row in drill down except for "in system list" operator
          this.makeRightOperandWithContentSource();
        }
        break;
      default:
        this.showControlOnRightOperand('autocomplete');
        break;
    }
  }

  private handleRightOperand(): void {
    if (this._isLeftOperandHasContentSource) {
      this._isLeftOperandStartsWithColon && this.ruleService.execTypeBindWhere ? this.showControlOnRightOperand('autocomplete') :
        this.makeRightOperandWithContentSource();
    } else {
      this.showControlOnRightOperand('autocomplete');
    }
  }
  private decideOnRightOperand(): void {
    switch (this.leftOperandDataType) {
      case OperandDataType.BASE_CONDITION:
        this.showControlOnRightOperand('input');
        this.isRightOperandInputDisabled = true;
        this.simpleCondition.rightOperand.alias = 'MET';
        break;
      case OperandDataType.BOOL:
        this.showControlOnRightOperand('select');
        this.rightOperandOptions = ['TRUE', 'FALSE'];
        if (this.rightOperandOptions.indexOf(
          this.simpleCondition.rightOperand.alias) === -1) {
          this.simpleCondition.rightOperand.alias = 'TRUE';
        }
        break;
      default:
        this.showControlOnRightOperand('input');
        this.isRightOperandInputDisabled = true;
        this.simpleCondition.rightOperand.alias = 'EMPTY';
        break;
    }
  }

  onConditionOperatorChange(operator: ConditionOperator): void {
    this.simpleCondition.conditionOperator = operator;
    if (this.isOperatorChangedOnInit) {
      this.isOperatorChangedOnInit = false;
      this.ruleCommonService.operatorsChangedOnInit--;
    }
    this.clearRightOperand();

    //We need timeout 0 in order to make it happen on the next render cycle
    //we have to clear the controls in right operand before we apply new one
    setTimeout(() => this.adjustControlOnRightOperand(operator));
  }

  private makeRightOperandWithContentSource(): void {
    this.drillDownSource = this.simpleConditionLogicService.getContentSource(this.simpleCondition.leftOperand as LogicalField);
    this.rightOperandInParamsFilter = this.simpleConditionLogicService.getFilterFunction(this.simpleCondition.leftOperand as LogicalField);
    this.showControlOnRightOperand('drilldown');
  }

  private adjustRightOperandAllowedTypes(leftOperandType: OperandDataType): void {
    this.rightOperandTypes = AllowedTypesMap[leftOperandType.toUpperCase()];
  }

  private openInnerConditionBlock(): void {
    //main right operand should have the same allowed types as its inner conditions
    this.rightOperandTypes = InnerConditionsAllowedTypesMap[this.simpleCondition.conditionOperator.toLowerCase()];
    this.simpleCondition.innerConditions = [];
    this.simpleCondition.innerConditions.push(JSON.parse(JSON.stringify(this.ruleConditionsActionsService.emptyCondition)));
  }

  //TODO REMOVE THIS UGLY FUNCTION ONCE THINGS ARE DONE IN A PROPER WAY AND RO1 (OPERATORS SCRIPT) IS EXECUTED!!!
  private replaceOperatorIfNeeded(): void {
    const originalOperator = typeof this.simpleCondition.conditionOperator === 'object' ?
      JSON.parse(JSON.stringify(this.simpleCondition.conditionOperator)) : this.simpleCondition.conditionOperator;

    switch (this.simpleCondition.conditionOperator) {
      case ConditionOperator.IN_OUT_FILE:
      case ConditionOperator.IN_GROUP_MANUAL_MATCH:
      case ConditionOperator.MANUAL_MATCH_OCCURRENCES:
      case ConditionOperator.IN_TEMPLATE_SET:
      case ConditionOperator.NOT_IN_TEMPLATE_SET:
        this.simpleCondition.conditionOperator = '';
    }

    if (this.simpleCondition.conditionOperator === ConditionOperator.IN ||
      this.simpleCondition.conditionOperator === ConditionOperator.CONTAINS_ID) {
      if ((this.simpleCondition.leftOperand as LogicalField).pruleContentsource != null) {
        this.simpleCondition.conditionOperator = ConditionOperator.IN_SYSTEM_LIST;
      } else {
        this.simpleCondition.conditionOperator = ConditionOperator.IN_LIST;
      }
    } else if (this.simpleCondition.conditionOperator === ConditionOperator.NOT_IN) {
      if ((this.simpleCondition.leftOperand as LogicalField).pruleContentsource != null) {
        this.simpleCondition.conditionOperator = ConditionOperator.NOT_IN_SYSTEM_LIST;
      } else {
        this.simpleCondition.conditionOperator = ConditionOperator.NOT_IN_LIST;
      }
    } else if (this.simpleCondition.conditionOperator === ConditionOperator.LIKE_VALUE_LIST) {
      this.simpleCondition.conditionOperator = ConditionOperator.IN_VALUE_LIST;
    } else if (this.simpleCondition.conditionOperator === ConditionOperator.NOT_LIKE_VALUE_LIST) {
      this.simpleCondition.conditionOperator = ConditionOperator.NOT_IN_VALUE_LIST;
    } else if (this.simpleCondition.conditionOperator === ConditionOperator.NOT_LIKE) {
      this.simpleCondition.conditionOperator = ConditionOperator.DOES_NOT_CONTAIN;
      this.removeRightOperandPercentages();
    } else if (this.simpleCondition.conditionOperator === ConditionOperator.LIKE) {
      this.handleLikeOperator();
      this.removeRightOperandPercentages();
    } else if (this.simpleCondition.conditionOperator === ConditionOperator.NOT_STARTS_WITH) {
      this.simpleCondition.conditionOperator = ConditionOperator.DOES_NOT_START_WITH;
    }

    const conditionOperator = this.simpleCondition.conditionOperator;
    if ((this.simpleCondition.leftOperand as LogicalField).pruleContentsource != null) {
      if (conditionOperator === ConditionOperator.DOES_NOT_START_WITH || conditionOperator === ConditionOperator.DOES_NOT_END_WITH ||
        conditionOperator === ConditionOperator.DOES_NOT_CONTAIN) {
        this.simpleCondition.conditionOperator = ConditionOperator.NON_EQUALS;
      } else if (conditionOperator === ConditionOperator.STARTS_WITH || conditionOperator === ConditionOperator.ENDS_WITH ||
        conditionOperator === ConditionOperator.CONTAINS) {
        this.simpleCondition.conditionOperator = ConditionOperator.EQAULS;
      }
    }

    if (originalOperator !== this.simpleCondition.conditionOperator) {
      this.isOperatorChangedOnInit = true;
      this.ruleCommonService.operatorsChangedOnInit++;
      this.operatorChangedOnInitAlertText =
        this.translateService.instant('rules.operator_changed_on_init_msg', { lastOperator: originalOperator });
      this.updateOriginalConditionOperator();
    } else {
      const operatorList = this.simpleConditionLogicService.getOperatorsForOperand(this.simpleCondition.leftOperand);
      if (operatorList.length > 0 && operatorList.findIndex((operator) => operator.value === this.simpleCondition.conditionOperator) === -1) {
        this.isOperatorChangedOnInit = true;
        this.ruleCommonService.operatorsChangedOnInit++;
        this.operatorChangedOnInitAlertText = this.translateService.instant('rules.operator_changed_on_init_msg', {
          lastOperator: this.simpleCondition.conditionOperator
        });
      }
    }
  }

  private updateOriginalConditionOperator(): void {
    const index = this.ruleConditionsService.flatRuleConditionsOnInit.indexOf(this.simpleCondition);
    const originalCondition = this.ruleConditionsService.flatOriginalRuleConditions[index];
    if (originalCondition) {
      originalCondition.conditionOperator = this.simpleCondition.conditionOperator;
    }
  }

  private isOperandInvalid(operand: ConditionOperand, allowValue: boolean): boolean {
    return this.simpleConditionLogicService.isValidOperand(operand, allowValue);
  }

  private handleLikeOperator(): void {
    const text = this.simpleCondition.rightOperand.alias;
    const PercentageAtStart = text.startsWith('%');
    const PercentageAtEnd = text.charAt(text.length - 1) === '%';

    if (PercentageAtStart && PercentageAtEnd) {
      this.simpleCondition.conditionOperator = ConditionOperator.CONTAINS;
    } else if (PercentageAtStart) {
      this.simpleCondition.conditionOperator = ConditionOperator.ENDS_WITH;
    } else if (PercentageAtEnd) {
      this.simpleCondition.conditionOperator = ConditionOperator.STARTS_WITH;
    } else {
      this.simpleCondition.conditionOperator = ConditionOperator.EQAULS;
    }
  }

  private removeRightOperandPercentages(): void {
    const newStrRegex = /^%+|%+$/g;
    const newStr = this.simpleCondition.rightOperand.alias.trim().replace(newStrRegex, '');
    this.simpleCondition.rightOperand.alias = newStr;
  }

  private checkOnSpecialRightOperand(newValue: ConditionOperand): ConditionOperand {
    let field = null;
    this._isLeftOperandStartsWithColon = false;
    const value = this.getValue(newValue);
    if (value !== null && this.ruleService.execTypeBindWhere) {
      for (let i = 0; i < this.ruleService.flatConditionsBank.length; i++) {
        if ((this.ruleService.flatConditionsBank[i].alias === value || this.ruleService.flatConditionsBank[i].id === value) &&
          this.ruleService.flatConditionsBank[i].contentType === ContentType.FIELD) {
          field = this.ruleService.flatConditionsBank[i];
          this._isLeftOperandStartsWithColon = true;
          break;
        }
      }
    }
    return field;
  }

  private getValue(newValue: ConditionOperand): string {
    let value = null;
    if (newValue.type === ContentType.VALUE && newValue.alias.charAt(0) === ':') {
      value = newValue.alias.substring(1);
    } else if (newValue.type === OperandDataType.STRING && (newValue as LogicalField).id.charAt(0) === ':') {
      value = (newValue as LogicalField).id.substring(1);
    }
    return value;
  }

  addCondition(): void {
    this.ruleConditionsActionsService.add(this.ruleConditionsService.conditions, this.simpleCondition);
  }

}
