import { Injectable } from '@angular/core';
import { LiquidityTab, SitemapNode } from '../../../sitemap/models';
import { ITaskBarHandlerService } from './task-bar-handler-service.interface';

@Injectable()
export class LiquidityTaskBarHandlerService implements ITaskBarHandlerService {

  getTaskBar(component: LiquidityTab) {
    const taskBar = {
      items: []
    };
    component.modules.inner_navigation?.nodes?.forEach((node: SitemapNode, index: number) => {
      taskBar.items.push({
        id: node.id,
        alias: node.alias,
        icon: node.icon,
        position: index,
        routerLink: `/home/liquidity/${node.id}`,
        stateName: `home.liquidity.views.${node.id}`
      });
    });
    return taskBar;
  }

}
