import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import {Observable, Subscription, from, mergeMap, tap, BehaviorSubject} from 'rxjs';
import { Entity } from '../../shared/models/enums/entity.enum';
import { PopOutService } from '../../services/pop-out.service';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { FormsService } from '../../forms/services/forms.service';
import { MessagesQueues } from '../../messages/models/consts/messages-queues.service';
import { CallerMessagesManagementService } from '../../messages/services/caller-messages-management.service';
import { FormResponse } from '../models/form-response.mode';
import { AutoFeederResult } from '../../messages/models/auto-feeder-result.model';
import { BFPaymentTypes } from '../../business-framework/messages/models/enums/bf-payment-types.enum';
import { PopupService } from '../../core/services/popup.service';
import {FeatureFlagsService} from '../../core/feature-flags/services/feature-flags.service';
import {FeatureName} from '../../core/feature-flags/models/feature-name.enum';
import {PaymentDataModel} from '../../business-framework/messages/models/payment-data.model';
import {HttpStatusCode} from "@angular/common/http";
import {ExternalFilter} from "../../shared/models/external-filter.model";

@Injectable()
export class MessagesService implements OnDestroy {
  get isLoading(): Observable<boolean> {
    return this._isLoading.asObservable();
  }
  public readonly MESSAGES = 'MESSAGES';
  private _isLoading = new BehaviorSubject(false);
  private _callerId = undefined;
  private _actOnBehalfOfCaller = 'false';
  private _callerFeature = 'false';
  private callerCallbackVisibleState = false;
  private readonly fieldIds = ['P_MID', 'P_MSG_STS', 'P_DEPARTMENT', 'P_DESTINATION_DEPARTMENT',
    'P_MSG_TYPE', 'P_MSG_SUB_TYPE'];
  private _multiSelectedMessages = new Array<string>();
  private subscriber = new Subscription();
  private queryParams;

  get callerId(): any {
    return this._callerId;
  }

  set callerId(value: any) {
    this._callerId = value;
  }

  get multiSelectedMessages(): Array<string> {
    return this._multiSelectedMessages;
  }

  set multiSelectedMessages(value: Array<string>) {
    this._multiSelectedMessages = value;
  }

  get actOnBehalfOfCaller(): string {
    return this._actOnBehalfOfCaller;
  }

  set actOnBehalfOfCaller(value: string) {
    this._actOnBehalfOfCaller = value;
  }

  get callerFeature(): string {
    return this._callerFeature;
  }

  set callerFeature(value: string) {
    this._callerFeature = value;
  }
  //todo: refactor stateParams

  constructor(private popupService: PopupService,
              private popOutService: PopOutService, private authenticationService: AuthenticationService,
    private formsService: FormsService,
              private messagesQueues: MessagesQueues, private callerMessagesManagementService: CallerMessagesManagementService,
    private notificationService: NotificationService, private translateService: TranslateService, private route: ActivatedRoute,
    private router: Router,
              private featureFlagsService: FeatureFlagsService) {
  }

  openMessage(item: any) {
    //if we are in popout mode we want the main page to open the old UI since the old UI needs data from the main page.
    // the popout window get closed after an action thus cannot provide data to the newly opened window
    if (this.popOutService.isPopOut()) {
      (window.top.opener as any).GPPFrontEnd().openMessage(item.P_MID);
      return;
    }

    const message = { P_MID : item.P_MID };
    if (this.featureFlagsService.isFeatureOn(FeatureName.newMessageScreens)) {
      this._isLoading.next(true);
      this.formsService.getMessageOpsData(item.P_MID).subscribe({
        next: (response) => {
          this._isLoading.next(false);
          const eTag = response.headers.get('ETag') || '0';
          const data = response.body;
          const messageType = this.checkMessageOpsCompatibility(data);
          if (messageType) {
            this.openBusinessFrameworkMessage(item.P_MID, data, messageType, eTag);
          } else {
            this.openNonMessageOpsPayment(item, message);
          }
        },
        error: (err) => {
          this._isLoading.next(false);
          // If response status is 501 message is not supported by message ops, to be treated as non-business framework message
          if (err.status === HttpStatusCode.NotImplemented) {
            this.openNonMessageOpsPayment(item, message);
          } else {
            this.notificationService.error(this.translateService.instant('business-framework.errors.unable-to-get-msg-ops-data'));
          }
        }
      });
    } else {
      this.openNonMessageOpsPayment(item, message);
    }

  }
;
  private checkMessageOpsCompatibility(data: any): BFPaymentTypes {
    // Domestic Payment if
    // STATUS = REPAIR & & DIRECTION = O & MESSAGE_TYPE = FED_10 & MESSAGE_SUB_TYPE = 00
    // & Beneficiary agent prefix (Cdtr agt NCC cd) = USABA & Beneficiary agent country = US
    console.log(data[PaymentDataModel.MESSAGE_TYPE] === 'FED_10'
      ,data[PaymentDataModel.MESSAGE_SUB_TYPE] === '00'
      ,data[PaymentDataModel.DIRECTION] === 'O'
      , data[PaymentDataModel.LOCAL_INSTR_CODE] === 'CTR'
      , data[PaymentDataModel.BENE_AGT_ID_TYPE] === 'USABA'
      , (data[PaymentDataModel.STATUS] === 'REPAIR' || data[PaymentDataModel.STATUS] === 'RELEASE' || data[PaymentDataModel.STATUS] === 'VERIFY'))

    console.log('PaymentDataModel.BENE_AGT_ID_TYPE is ', data[PaymentDataModel.BENE_AGT_ID_TYPE]);
    if (data[PaymentDataModel.MESSAGE_TYPE] === 'FED_10'
      && data[PaymentDataModel.MESSAGE_SUB_TYPE] === '00'
      && data[PaymentDataModel.DIRECTION] === 'O'
      && data[PaymentDataModel.LOCAL_INSTR_CODE] === 'CTR'
      && (data[PaymentDataModel.STATUS] === 'REPAIR' || data[PaymentDataModel.STATUS] === 'RELEASE' || data[PaymentDataModel.STATUS] === 'VERIFY')) {
      return BFPaymentTypes.PACS_008_US_DOMESTIC;
    }

    // International payment if
    // STATUS = REPAIR & & DIRECTION = O & MESSAGE_TYPE = SWIFT_103 & MESSAGE_SUB_TYPE = any
    // & Beneficiary agent prefix = any & Beneficiary agent country != US

    // FX payment if
    // STATUS = REPAIR & & DIRECTION = O & MESSAGE_TYPE = SWIFT_103 & MESSAGE_SUB_TYPE = any
    // & Beneficiary agent prefix = any & Beneficiary agent country != US & D_CURRENCY_CONVERSION_TYPE = CREDIT

    return null;
  }

  openNonMessageOpsPayment(item: any, message: any) {
    this._isLoading.next(true);
    const subscription = this.formsService.prepareOpenForm(this.MESSAGES, item.P_MID).subscribe(
      {
        next: (formResponse: FormResponse) => {
          const formName = formResponse.payload.FORM;
          if (this.isMultiSelectMode()) {
            this.openMultiSelectMessage(item.P_MID, message, formName);
          } else if (formName !== 'OLD') {
            this.openNewUIMessage(message, formName);
          } else {
            this._isLoading.next(false);
            return this.openOldUIMessages(message);
          }
        },
        error: (err) => {
          this._isLoading.next(false);
          console.error('Failure open message', err);
        },

      }
    );
    this.subscriber.add(subscription);

  }

  openMultiSelectMessage(mid: string, message: any, formName: string) {
    if (formName !== 'OLD') {
      this.openMessageForm(message, formName);
    } else {
      this._isLoading.next(false);
      return this.open(message, {
        txtMID: message.P_MID
      });
    }
  }

  openMessageFormApproveReg(item: any, formName: string): void {
    const url = this.router.url.split('?')[0];
    this.queryParams = this.route.snapshot.queryParams;
    const queue = this.messagesQueues.types(this.queryParams.queueType) || this.messagesQueues.default;
    let stateParams = {
      form: this.MESSAGES,
      uid: item.P_MID,
      messageComponent: formName
    };
    stateParams = { ...this.queryParams, ...stateParams };
    this.formsService.setFormName(formName);
    let createUrl = url;
    if (url.indexOf('createMessage') > -1) {
      createUrl = url.replace('/messages/createMessage', '');
    }
    if (createUrl.endsWith('/messages')) {
      createUrl = createUrl.substring(0, createUrl.length - 9);
    }
    this.router.navigate([createUrl + '/messages/single'], { queryParams: stateParams }).then(() => {
      console.log('approve reg states');
      this._isLoading.next(false);
    });
  }


  openMessageForm(item: any, formName: string): void {
    const url = this.router.url.split('?')[0];
    this.queryParams = this.route.snapshot.queryParams;
    const queue = this.messagesQueues.types(this.queryParams.queueType) || this.messagesQueues.default;
    let stateParams = {
      form: this.MESSAGES,
      uid: item.P_MID,
      messageComponent: formName
    };
    stateParams = { ...this.queryParams, ...stateParams };
    if (this.isMultiSelectMode()) {
      this.openNewUIPopOutMessage(item, queue, stateParams);
    } else {
      this.formsService.setFormName(formName);
      if('queueId' in this.queryParams) {
        this.router.navigate([url + '/single'], { queryParams: stateParams }).then(() => {
        this._isLoading.next(false);
      });
     } else {
        this.router.navigate([url + '/messages/single'], { queryParams: stateParams }).then(() => {
          this._isLoading.next(false);
        });
      }
    }
  }

  openMessageInOldUi(item: any): void {
    const message = { P_MID : item.P_MID };
    this.open(message, { txtMID: message.P_MID });
  }

  open(item: any, params: any): Observable<any> {
    //if we are in popout mode we want the main page to open the old UI since the old UI needs data from the main page.
    // the popout window get closed after an action thus cannot provide data to the newly opened window
    if (this.popOutService.isPopOut()) {
      (window.top.opener as any).GPPFrontEnd().openMessageWithParams(item, params);
      return;
    }

    params = { ... {
        txtPaymentReqMetadataList: this.concatenatePaymentData(item, this.fieldIds) + ';;',
        txtFunctionID: '',
        txtTemplateType: '',
        KEY_IS_HISTORY: '0',
        txtDefOffice: this.authenticationService.userData.userOffice, // todo why ?
        txtLockMode: '1',
        txtMIDToRelease: '',
        txtTriggeredMessage: '',
        txtGeneratedMessage: '',
        txtSplitXmlAndOrigXml: '',
        txtTriggeredMessageButtonID: '',
        callerID1: '',
        txtMID: item.P_MID,
        '@SESSION_ID@': this.authenticationService.sessionId,
        '@FORM_NAME@':	'frmLoadMessage',
        '@COMMAND_EVENT@':	'eventLoadMessage'
      }, ...params };

    return from(this.popupService.open(params, 'message') || Promise.resolve(null));
  }

  openAutoFeederMessage(item: any, isOld = false): Observable<AutoFeederResult> {
    return this.formsService.prepareOpenForm(this.MESSAGES, item.P_MID).pipe(mergeMap(
        (formResponse: FormResponse): Observable<AutoFeederResult> => {
          const formName = formResponse.payload.FORM;
          if (formName !== 'OLD' && isOld === false) {
            return this.openNewUIMessageAutoFeeder(item, formName);
          } else {
            const params = { '@COMMAND_EVENT@': 'eventAutoFeederMessage' };
            return this.open(item, params);
          }
        }),
      tap({
        error: (err) => console.error('Failure open auto feeder message', err)
      })
    );
  }

  createMessage(office: string, department: string, paymentType: string, isCallerPayment: any, fxEnhancement = false): void {
    const item = {
      P_MID: '-1',
      P_DEPARTMENT: department
    };
    this.formsService.isCallerPayment = isCallerPayment;
    if (this.shouldCreateBusinessFrameworkMessage(paymentType)) {
      this.createBusinessFrameworkMessage(paymentType, office, department);
      return;
    }
    this._isLoading.next(true);
    const subscription = this.formsService.prepareOpenForm(this.MESSAGES, item.P_MID, paymentType).subscribe(
      (formResponse: FormResponse) => {
        if (formResponse.payload.FORM !== 'OLD') {
          this.createNewUIMessage(formResponse.payload.FORM, item, office, paymentType, fxEnhancement);
        } else {
          this.createOldUIMessage(item, office, paymentType);
          this._isLoading.next(false);
        }
        this.formsService.isCallerPayment = null;
      },
      error => {
        console.error('Failure create message', error);
        this._isLoading.next(false);
      }
    );
    this.subscriber.add(subscription);
  }

  shouldCreateBusinessFrameworkMessage(paymentType: string): boolean {
    return Object.keys(BFPaymentTypes).some(key => BFPaymentTypes[key] === paymentType);
  }

  createBusinessFrameworkMessage(paymentType: string, office: string, department: string): void {
    this.queryParams = this.route.snapshot.queryParams;
    const queueType = this.messagesQueues.types(this.queryParams.queueType) || this.messagesQueues.default;
    const url = `/home/messages-center/${queueType}/messages/create-msg-ops`;
    const queryParams = { paymentType: paymentType, txtDefOffice: office, P_DEPARTMENT: department };
    this.router.navigate([url], { queryParams: queryParams });
  }

  async createNewUIMessage(formName: string, item: any, office: string, paymentType: string, fxEnhancement = false): Promise<void> {
    this.formsService.setFormName(formName);
    let url = this.router.url;
    this.queryParams = this.route.snapshot.queryParams;
    let stateParams = {
      form: this.MESSAGES,
      uid: item.P_MID,
      P_DEPARTMENT: item.P_DEPARTMENT,
      messageComponent: formName,
      txtDefOffice: office,
      paymentType: paymentType,
      fxEnhancement: fxEnhancement
    };
    if (Object.keys(this.queryParams).length > 0) { // in specific messages grid
      stateParams = { ...this.queryParams, ...stateParams };
      url = url.substring(0, url.indexOf('?'));
      await this.router.navigate([url + '/createMessage'], {
          queryParams: stateParams,
        }
      );
      this._isLoading.next(false);
    } else {
      await this.router.navigate([url + '/messages/createMessage'], { // landing page
        queryParams: stateParams,
      });
      this._isLoading.next(false);
    }
  }

  createOldUIMessage(item: any, office: string, paymentType: string): void {
    const params = {
      txtDefOffice: office,
      '@COMMAND_EVENT@': paymentType,
      txtIsCallerPayment: this.formsService.isCallerPayment
    };
    this.open(item, params);
  }

  getMidKey(entity: Entity): string {
    switch (entity) {
      case Entity.MESSAGES:
        return 'P_MID';
      case Entity.BATCHES:
        return 'BATCH_SUMMARY_INTERNAL_FILE_ID';
      case Entity.FILES:
      case Entity.BULKS:
        return 'FILE_SUMMARY_INTERNAL_FILE_ID';
    }
  }

  showBeforeAfter(item: any): void {
    this.popupService.open({
      txtPaymentReqMetadataList: this.concatenatePaymentData(item, this.fieldIds) + ';;',
      txtMsgTablePageNum:	1,
      txtQueueMT:	'H',

      '@SESSION_ID@': this.authenticationService.sessionId,
      '@FORM_NAME@':	'frmLoadMessage',
      '@COMMAND_EVENT@':	'eventDisplayMessageBeforeAfter'
    });
  }

  createFromTemplate(item: any): void {
    this.open(item, {
      txtMID: item.P_MID,
      txtQueueName:	item.P_MSG_STS,
      txtTemplateLoad: '1'
    });
  }

  createTempFromPay(item: any, mid: string): void {
    this.open(item, {
      txtMID: mid,
      txtQueueName:	item.P_MSG_STS,
      txtLockMode : '0',
      txtPayOrTempFromPay : '2',
      txtTemplateLoad: '1'
    });
  }

  createPayFromPay(item: any, mid: string): void {
    this.open(item, {
      txtMID: mid,
      txtQueueName:	item.P_MSG_STS,
      txtPayOrTempFromPay : '1',
      txtTemplateLoad: '1'
    });
  }

  isMultiSelectMode(): boolean {
    return this._multiSelectedMessages.length > 1;
  }

  openNextMessage(mid: string) {
    if (!this.isMultiSelectMode()) {
      return;
    }

    const nextMessageMid = this.getMultiSelectNextMessage(mid);
    if (nextMessageMid) {
      this.openMessage({ P_MID: nextMessageMid });
    } else {
      this.addNotificationNextActionDone();
    }
  }

  getMultiSelectNextMessage(mid: string): string { //from old UI
    let nextMessageMID;
    const indexOfMessage = this._multiSelectedMessages.indexOf(mid);
    if (indexOfMessage < this._multiSelectedMessages.length - 1) {
      nextMessageMID = this._multiSelectedMessages[indexOfMessage + 1];
      return nextMessageMID;
    }
    return null;
  }

  exitCallerCallback(): void {
    this.callerCallbackVisibleState = false;
  }

  public openNewUIMessage(message: any, formName: string): void {
    if (!this.popOutService.openedWindows[message.P_MID]) {
      this.openMessageForm(message, formName);
    } else {
      const childWindow = this.popOutService.openedWindows[message.P_MID]['windowRef'];
      childWindow.focus();
      this._isLoading.next(false);
    }
  }

  public openBusinessFrameworkMessage(uid: string, messageData: any, paymentType: BFPaymentTypes, eTag = 0): void {
    const queue = this.messagesQueues.types(this.route.snapshot.params.queueType) || this.messagesQueues.default;
    const routerLink = `home/messages-center/${queue}/messages/single-msg-ops`;
    this.router.navigate([routerLink], { queryParams: { uid, paymentType}, state:{ messageData, eTag}});
  }

  private concatenatePaymentData(item: any, fieldIds: Array<string>): string {
    const list = [];
    fieldIds.forEach((fieldId) => list.push(fieldId + '~' + (item[fieldId] || '')));
    return list.join('#');
  }

  private openNewUIPopOutMessage(item: any, queue: string, stateParams: any): void {
    // this.popOutService.stateName = `popOut.messagesCenter-${queue}`;
    // this.popOutService.stateParams = { ...{
    //     queueId: this.stateService.params.queueId,
    //     multiSelectMode: true
    //   }, ...stateParams
    // };
    // this.popOutService.open(item.P_MID);
  }

  private openOldUIMessages(message: any): Observable<any> {
    const params = {
      txtMID: message.P_MID,
      txtIsCallerPayment: this.callerMessagesManagementService.isCallerAuthenticated
    };
    return this.open(message, params);
  }

  private openNewUIMessageAutoFeeder(item: any, formName: string): Observable<AutoFeederResult> {
    const params = {
      'queueId': this.route.snapshot.queryParams.queueId,
      'uid': item.P_MID,
      'form': this.MESSAGES,
      'messageComponent': formName,
      'autofeederMode': true
    };
    return this.popOutService.openAutoFeeder({} as any, params);
  }

  private addNotificationNextActionDone(): void {
    this.notificationService.warning(this.translateService.instant('message.autofeeder.no_messages'));
  }

  setFilterParamsCreditor(filterType: string, office: string, currency: string, selectValue): ExternalFilter {
    if (filterType === 'ACCT') {
      let searchFilter = {};
      if (currency) {
        searchFilter = {
          'ACCOUNTS.OFFICE': office,
          'ACCOUNTS.REC_STATUS': 'AC',
          'ACCOUNTS.CURRENCY': currency
        }
      }
      else {
        searchFilter = {
          'ACCOUNTS.OFFICE': office,
          'ACCOUNTS.REC_STATUS': 'AC'
        }
      }
      return {searchFilter};

    } else if (filterType === 'BIC') {
      return {
        searchFilter: {
          'CUSTOMRS-OFFICE': [office, '***'],
          'CUSTOMRS.REC_STATUS': 'AC',
          'CUSTOMRS.SWIFT_ID': '*'
        }
      };
    } else if (filterType === 'NCC') {
      return {
        searchFilter: {
          'NCC-OFFICE': [office, '***'],
          'NCC.REC_STATUS': 'AC',
          'NCC-NCC_TYPE_ISO': '*' + selectValue + '*'
        }
      };
    }
  }

  getPartyIdOptions(options, basicOptions: string[], possibleOptions: string[], metadata) {
    const filteredOptions = options ? possibleOptions.filter((possibleOption: string) => {
      return options.some((option) => option.id === possibleOption && (option.value === metadata['P_OFFICE'].value || option.value === '***'));
    }) : [];

    return basicOptions.concat(filteredOptions).map((option: string) => {
      return { id: option, value: option };
    });
  }

  getAdditionalParameters(row: any, fieldType: string, office: string) {
    const additionalParameters = {};
    if (fieldType === 'NCC') {
      additionalParameters['CUST_CODE'] = row[0]['NCC-CUST_CODE'];
      additionalParameters['NCC-NCC_TYPE_ISO'] = row[0]['NCC-NCC_TYPE_ISO'];
      additionalParameters['OFFICE'] = row[0]['NCC-OFFICE'];
    } else {
      if (fieldType === 'BIC') {
        additionalParameters['OFFICE'] = office;
        if (row[0]['CUSTOMRS-CUST_CODE'] && row.length === 1) {
          additionalParameters['CUST_CODE'] = row[0]['CUSTOMRS-CUST_CODE'];
        }
      } else {
        additionalParameters['CUST_CODE'] = row[0]['ACCOUNTS-CUST_CODE'];
        additionalParameters['OFFICE'] = office;
        additionalParameters['isHintError'] = true;
      }
    }
    return additionalParameters;
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
