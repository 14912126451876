export enum CutoffTimes {
  OFFICE = 'CUTOFF_PROFILE-OFFICE',
  DEPARTMENT = 'CUTOFF_PROFILE-DEPARTMENT',
  REC_STATUS = 'CUTOFF_PROFILE-REC_STATUS',
  PROFILE_CHANGE_STATUS = 'CUTOFF_PROFILE-PROFILE_CHANGE_STATUS',
  EFFECTIVE_DATE = 'EFFECTIVE_DATE',
  CUTOFF_NAME = 'CUTOFF_PROFILE-CUTOFF_NAME',
  DESCRIPTION = 'CUTOFF_PROFILE-DESCRIPTION',
  CUTOFF_TYPE = 'CUTOFF_PROFILE-CUTOFF_TYPE',
  DEFAULT_IND = 'CUTOFF_PROFILE-DEFAULT_IND',
  V_EXCEPTION_DATE = 'CUTOFF_PROFILE-V_EXCEPTION_DATE',
  EXP_FROM_DATE = 'CUTOFF_PROFILE-EXP_FROM_DATE',
  EXP_TO_DATE = 'CUTOFF_PROFILE-EXP_TO_DATE',
  INTERIM_CUTOFF = 'CUTOFF_PROFILE-INTERIM_CUTOFF',
  FINAL_CUTOFF = 'CUTOFF_PROFILE-FINAL_CUTOFF',
  TIME_ZONE = 'CUTOFF_PROFILE-TIME_ZONE',
  TIME_STAMP = 'TIME_STAMP',
  UID = 'VIRTUAL-UID',
  PROFILE_UPDATE_PK = 'PROFILE_UPDATE_PK',
  PU_TIME_STAMP = 'PU_TIME_STAMP',
  NOTE = 'NOTE'
};
