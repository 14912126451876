import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TaskBarService } from '../core/navigation/task-bar/services/task-bar.service';

@Injectable({ providedIn: 'root' })
export class TaskBarResolver implements Resolve<void> {
  constructor(private taskBarService: TaskBarService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<void>|Promise<void>|void {
    this.taskBarService.init();
  }
}
