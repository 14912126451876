import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GridColumn } from '@fgpp-ui/components';
import { PROFILE_ID } from '../../../profiles/components/profiles-grid/profiles-grid.component';
import { RulesProfilesManagementService } from '../../../profiles/services/rules-profiles-management.service';
import { RuleMetadata } from '../../single-rule/models';

@Injectable()
export class RuleMetadataResolver implements Resolve<RuleMetadata> {

  constructor(private rulesProfilesManagementService: RulesProfilesManagementService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<RuleMetadata> {
    return Promise.resolve(this.getColumns()).then(columns => this.mapColumns(columns));
  }

  private getColumns(): Promise<Array<GridColumn>> | Array<GridColumn> {
    if (this.rulesProfilesManagementService.profileId === PROFILE_ID.RULE && this.rulesProfilesManagementService.availableColumns?.length) {
      return this.rulesProfilesManagementService.availableColumns;
    }
    if (this.rulesProfilesManagementService.profileId !== PROFILE_ID.RULE) {
      this.rulesProfilesManagementService.profileId = PROFILE_ID.RULE;
    }
    return this.rulesProfilesManagementService.getMetadata().then(columns => columns.availableColumns);
  }

  private mapColumns(columns: Array<GridColumn>): RuleMetadata {
    const map = {};
    columns.forEach((column) => {
      map[column.name] = column;
    });
    return map;
  }

}
