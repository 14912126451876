import { Component, Injector, OnInit } from '@angular/core';
import { DatepickerComponent } from '@fgpp-ui/components';
import dayjs from 'dayjs';
import { DEFAULT_FORMATS } from 'projects/gpp/src/app/shared/models/default-formats.const';
import { FormatsService } from '@fgpp-ui/components';
import { IEndpoint } from '../../../core/interfaces/endpoint.interface';
import { IValueChange } from '../../../core/interfaces/form-value-change.interface';
import { BFOnFormLoadComplete, BFOnValueChange, BFProfileOnSave } from '../../../core/interfaces/sdk-profile-hooks.interface';
import { ProfileFormTemplateProviders } from '../../../core/templates/profile-form.template.providers';
import { AbstractProfileFormComponent } from '../../abstract/abstract-profile/abstract-profile-form.component';
import { ProfileActions } from '../../enums/profile-actions.enum';
import { CutoffTimes } from '../../modals/cutoff-times.model';

const MIN_DATE_FORMAT = 'MM/DD/YYYY';
const SERVER_TIME_FORMAT = DEFAULT_FORMATS.timeFormat;

@Component({
  selector: 'app-cutoff-times',
  templateUrl: '../../../core/templates/profile-form.template.html',
  styleUrls: ['./cutoff-times.component.scss'],
  providers: ProfileFormTemplateProviders
})
export class CutoffTimesComponent extends AbstractProfileFormComponent implements OnInit, BFOnFormLoadComplete, BFOnValueChange, BFProfileOnSave {

  constructor(protected injector: Injector,
              private formatsService: FormatsService) {
    super(injector);
  }


  fgppFormLoadComplete(): void {
    this.setExceptionDateCheckbox();
    this.setClientTimeFormattedValue(CutoffTimes.INTERIM_CUTOFF);
    this.setClientTimeFormattedValue(CutoffTimes.FINAL_CUTOFF);
  }

  fgppValueChanges(change: IValueChange): void {
    switch (change.control) {
      case CutoffTimes.CUTOFF_NAME:
        this.onCutoffNameChange(change.value.inputValue);
        break;
      case CutoffTimes.V_EXCEPTION_DATE:
        this.onExceptionDateCheckboxChange(change.value.inputValue);
        break;
      case CutoffTimes.EXP_FROM_DATE:
        this.onFromDateChange(change.value.inputValue);
        break;
      case CutoffTimes.EXP_TO_DATE:
        this.validateExceptionDates();
        break;
      case CutoffTimes.INTERIM_CUTOFF:
      case CutoffTimes.FINAL_CUTOFF:
        this.validateCutoffTime();
        break;
      }
  }

  fgppOnSave(): boolean {
    return this.validateExceptionDates() && this.validateCutoffTime();
  }

  protected getServerActionRequestBody(endpoint: IEndpoint, buttonId: string): any {
    const body = super.getServerActionRequestBody(endpoint, buttonId);
    const action = ProfileActions[buttonId];
    if (action === ProfileActions.CREATE || action === ProfileActions.SAVE || action === ProfileActions.APPROVE) {
      this.setDefaultTimeIndicator(body);
      this.setServerTimeFormattedValue(body, CutoffTimes.INTERIM_CUTOFF);
      this.setServerTimeFormattedValue(body, CutoffTimes.FINAL_CUTOFF);
    }
    return body;
  }

  private setExceptionDateCheckbox(): void {
    const defaultTime = this.getData()[CutoffTimes.DEFAULT_IND];
    const exceptionDate = defaultTime === '0' ? '1' : '0';
    this.formMgr.get(CutoffTimes.V_EXCEPTION_DATE).setValue(exceptionDate);
    this.onExceptionDateCheckboxChange(exceptionDate);
  }

  private onCutoffNameChange(value: string): void {
    if (value && value.toUpperCase() !== value) {
      this.formMgr.get(CutoffTimes.CUTOFF_NAME).setValue(value.toUpperCase());
    }
  }

  private onExceptionDateCheckboxChange(value: '0' | '1'): void {
    if (value === '1') {
      this.formMgr.get(CutoffTimes.EXP_FROM_DATE).enable().markAsRequired();
      this.formMgr.get(CutoffTimes.EXP_TO_DATE).enable().markAsRequired();
    } else {
      this.formMgr.get(CutoffTimes.EXP_FROM_DATE).disable().markAsOptional().reset();
      this.formMgr.get(CutoffTimes.EXP_TO_DATE).disable().markAsOptional().reset();
    }
  }

  private onFromDateChange(value: string): void {
    if (value) {
      const fromDate = dayjs(+value).format(MIN_DATE_FORMAT);
      const datepickerComponent = this.formMgr.get(CutoffTimes.EXP_TO_DATE).getLayoutControl() as DatepickerComponent;
      datepickerComponent.controlData.minDate = fromDate;
      this.validateExceptionDates();
    }
  }

  private validateExceptionDates(): boolean {
    const fromDate = +this.formMgr.get(CutoffTimes.EXP_FROM_DATE).getValue();
    const toDate = +this.formMgr.get(CutoffTimes.EXP_TO_DATE).getValue();
    if (fromDate && toDate && fromDate > toDate) {
      this.notificationService.error(this.translate.instant('business-framework.profiles.26.errors.exception-dates-comparison'));
      return false;
    }
    return true;
  }

  private validateCutoffTime(): boolean {
    const interimCutoffTime = this.formMgr.get(CutoffTimes.INTERIM_CUTOFF).getValue();
    const finalCutoffTime = this.formMgr.get(CutoffTimes.FINAL_CUTOFF).getValue();
    if (interimCutoffTime && finalCutoffTime && this.formatsService.toTime(interimCutoffTime) > this.formatsService.toTime(finalCutoffTime)) {
      this.notificationService.error(this.translate.instant('business-framework.profiles.26.errors.cutoff-time-comparison'));
      return false;
    }
    return true;
  }

  private setClientTimeFormattedValue(fieldName: CutoffTimes): void {
    const value = this.formMgr.get(fieldName).getValue();
    if (value && dayjs(value).isValid()) {
      const time = dayjs(value).format(this.userPreferences.timeFormat);
      this.formMgr.get(fieldName).setValue(time);
    }
  }

  private setServerTimeFormattedValue(body: any, fieldName: CutoffTimes): void {
    if (body[fieldName]) {
      body[fieldName] = this.formatsService.toTime(body[fieldName]).format(SERVER_TIME_FORMAT);
    }
  }

  private setDefaultTimeIndicator(body: any): void {
    body[CutoffTimes.DEFAULT_IND] = body[CutoffTimes.V_EXCEPTION_DATE] === '0' ? '1' : '0';
    delete body[CutoffTimes.V_EXCEPTION_DATE];
  }

}
