import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LiquidityNode } from '../../models';
import { LiquidityService } from '../../services/liquidity.service';

@Injectable()
export class LiquidityTreeResolver implements Resolve<LiquidityNode> {

  constructor(private liquidityService: LiquidityService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LiquidityNode> {
    const taskBarItem = route.params.taskBarItem;
    const taskBarItemService = this.liquidityService.getTaskBarItemService(taskBarItem);
    if (taskBarItemService) {
      return taskBarItemService.getTree();
    }
  }

}
