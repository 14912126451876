import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { MessagesQueues } from '../../../messages/models/consts/messages-queues.service';
import { MessagesQueuesStateService } from '../../../messages/services/messages-queues-state.service';

@Injectable()
export class MessagesQueuesStateResolver implements Resolve<any> {
  constructor(private messagesQueuesStateService: MessagesQueuesStateService, private messagesQueues: MessagesQueues, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): void {
    const queueId = route.parent.queryParamMap.get('queueId');
    let queueType = route.parent.parent.paramMap.get('taskBarItem');
    queueType = this.messagesQueues.getMessagesQueueType(queueType)
    const entityType = route.parent.url[0].path;
    const uid = route.parent.queryParamMap.get('uid');
    if(!(this.router.url.indexOf('single') > -1 && state.url.indexOf('single') > -1)) {
      this.messagesQueuesStateService.dispatchQueue(queueId, queueType, entityType, uid);
    }
  }
}
