import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeactivateEntityGuard } from '../mass-pay/entity/guards/deactivate-entity.guard';
import { FtDatePipe } from '../pipes/ftDate.pipe';
import { BreadcrumbsService } from '../ui-components/services/breadcrumbs.service';
import { GroupActionsResolver } from './routing/resolvers/group-actions.resolver';
import { ResetQueueStateResolver } from './routing/resolvers/reset-queue-state.resolver';
import { MessagesCenterCallbackModule } from './components/messages-center-callback/messages-center-callback.module';
import { MessagesCenterTaskBarItemGuard } from './routing/guards/messages-center-task-bar-item.guard';
import { RedirectMessageCenterGuard } from './routing/guards/redirect-message-center.guard';
import { RedirectSearchGuard } from './routing/guards/redirect-search.guard';
import { TaskBarItemExitGuard } from './routing/guards/task-bar-item-exit.guard';
import { CreateFormDataResolver } from './routing/resolvers/create-form-data.resolver';
import { GridContainerConfigResolver } from './routing/resolvers/grid-container-config.resolver';
import { MessagesCenterTreeResolver } from './routing/resolvers/messages-center-tree.resolver';
import { MessagesQueuesStateResolver } from './routing/resolvers/messages-queues-state.resolver';
import { RecentSearchBreadcrumbsResolver } from './routing/resolvers/recent-search-breadcrumbs-resolver';
import { GroupActionsService } from './services/group-actions.service';
import { FnUiExportModule } from '../shared/fn-ui-export/fn-ui-export.module';
import { FnUiDialogModule } from '../shared/fn-ui-dialog/fn-ui-dialog.module';
import { FavoritesModule } from './favorites/favorites.module';
import { RecentSearchesModule } from './recent-searches/recent-searches.module';
import { MessagesCenterNavigationModule } from './messages-center-navigation/messages-center-navigation.module';
import { GroupActionsFollowupService } from './services/group-actions-followup.service';

@NgModule({
  providers: [
    GroupActionsService,
    GroupActionsFollowupService,
    MessagesCenterTaskBarItemGuard,
    RedirectMessageCenterGuard,
    MessagesCenterTreeResolver,
    RedirectSearchGuard,
    TaskBarItemExitGuard,
    GridContainerConfigResolver,
    MessagesQueuesStateResolver,
    CreateFormDataResolver,
    RecentSearchBreadcrumbsResolver,
    ResetQueueStateResolver,
    GroupActionsResolver,
    DeactivateEntityGuard,
    BreadcrumbsService,
    FtDatePipe
  ],
  imports: [
    CommonModule,
    MessagesCenterCallbackModule,
    FnUiExportModule,
    FnUiDialogModule,
    FavoritesModule,
    RecentSearchesModule,
    MessagesCenterNavigationModule
  ],
  declarations: [],
  exports: []
})
export class MessagesCenterModule { }
