import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { PROFILE_ID } from '../../components/profiles-grid/profiles-grid.component';

@Injectable({
  providedIn: 'root'
})
export class RulesAssociationSingularProfileResolver implements Resolve<boolean> {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return route.params.profileId === PROFILE_ID.RULE_ASSOCIATION && route.queryParams['mode'];
  }
}
