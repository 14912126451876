import * as pbiClient from 'powerbi-client';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PowerBINavigationService } from '../../services/power-bi-navigation.service';
import { PowerBIReportMetadata } from '../../models/power-bi-report-metadata.model';
import { PowerBIRefreshTokenService } from '../../services/power-bi-refresh-token.service';
import {ActivatedRoute} from '@angular/router';
import {tap} from "rxjs";

@Component({
  selector: 'app-power-bi-reports',
  templateUrl: './power-bi-reports.component.html',
  styleUrls: ['./power-bi-reports.component.scss']
})
export class PowerBiReportsComponent implements OnInit, OnDestroy {

  @ViewChild('reportsContainer', { static: true }) reportsContainer: ElementRef<HTMLElement>;

  powerBI: pbiClient.service.Service;
  report: pbiClient.Report;
  isLoaded: boolean;
  reportMetadata: PowerBIReportMetadata;
  private queryParamsFlag: string;

  constructor(private route: ActivatedRoute,
    private powerBINavigationService: PowerBINavigationService,
    private powerBIRefreshTokenService: PowerBIRefreshTokenService) {
    this.powerBI = new pbiClient.service.Service(pbiClient.factories.hpmFactory, pbiClient.factories.wpmpFactory, pbiClient.factories.routerFactory);
  }

  ngOnInit(): void{
    this.route.queryParams.pipe(tap(async (queryParams) =>  {
      if (!this.queryParamsFlag || this.queryParamsFlag !== queryParams.report) {
        this.powerBIRefreshTokenService.finishCheckTokenExpiration();
        this.queryParamsFlag = queryParams.report;
        await this.loadReport();
        this.powerBIRefreshTokenService.startCheckTokenExpiration(this.reportMetadata, this.report);
      }
    })).subscribe(() => {});
  }

  ngOnDestroy(): void {
    this.powerBIRefreshTokenService.finishCheckTokenExpiration();
  }

  showBookmarks(): void {
    const newSettings = {
      panes: {
        bookmarks: {
          visible: true
        }
      }
    };
    this.report.updateSettings(newSettings);
  }

  reloadReport(): void {
    this.report.reload();
  }

  printReport(): void {
    this.report.print();
  }

  private async loadReport(): Promise<void> {
    this.isLoaded = false;
    this.reportMetadata = this.getReportMetadata();
    if (!this.reportMetadata) {
      return;
    }
    const config = this.getReportConfig(this.reportMetadata);
    this.powerBI.reset(this.reportsContainer.nativeElement);
    this.report = this.powerBI.embed(this.reportsContainer.nativeElement, config) as pbiClient.Report;
    this.report.on('loaded', async () => {
      await this.loadLastState();
      this.isLoaded = true;
    });

    this.report.on('rendered', async () => {
      if(this.isLoaded) {
        await this.saveLastState();
      }
    });
  }

  private getReportMetadata(): PowerBIReportMetadata {
    const reportId = this.route.snapshot.queryParamMap.get('report');
    return this.powerBINavigationService.getReportMetadata(reportId);
  }

  private getReportConfig(reportMetadata: PowerBIReportMetadata): pbiClient.IEmbedConfiguration {
    return {
      type: 'report',
      tokenType: pbiClient.models.TokenType.Embed,
      pageView: 'fitToWidth',
      permissions: pbiClient.models.Permissions.All,
      accessToken: reportMetadata.embedToken,
      embedUrl: reportMetadata.embedUrl,
      id: reportMetadata.id,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true
      }
    };
  }

  private async saveLastState(): Promise<void> {
    if(this.report) {
      try {
        const capturedBookmark = await this.report.bookmarksManager.capture();
        this.powerBINavigationService.setReportLastState(this.reportMetadata.id, capturedBookmark.state);
      } catch (error) {
        console.error(error);
      }
    }
  }

  private async loadLastState(): Promise<void> {
    try {
      const lastState = this.powerBINavigationService.getReportLastState(this.reportMetadata.id);
      if(lastState) {
        await this.report.bookmarksManager.applyState(lastState);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
