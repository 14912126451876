import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ROUTES_MAPPING_MESSAGES_CENTER } from '../../../core/navigation/models/consts/routes-mapping.const';
import { FavoritesManagerService } from '../../favorites/services/favorites-manager.service';
import { MessagesCenterNavigationService } from '../../messages-center-navigation/services/messages-center-navigation.service';
import { RecentSearchesManagerService } from '../../recent-searches/services/recent-searches-manager.service';
import { CallerCallbackApiService } from '../../components/messages-center-callback/services/caller-callback-api.service';

@Injectable()
export class MessagesCenterTreeResolver implements Resolve<any> {

  constructor(private messagesCenterNavigationService: MessagesCenterNavigationService,
              private favoritesManagerService: FavoritesManagerService,
              private recentSearchesManagerService: RecentSearchesManagerService,
              private callerCallbackApiService : CallerCallbackApiService) {}

   async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Observable<any> | Promise<any> | any> {

    const taskBarItem = route.params.taskBarItem;

    switch (taskBarItem) {
      case ROUTES_MAPPING_MESSAGES_CENTER['system-queues']: {
        const tree = this.messagesCenterNavigationService.initSystemQueuesTree();
        this.messagesCenterNavigationService.updateSystemQueuesTree();
        return tree;
      }
      case ROUTES_MAPPING_MESSAGES_CENTER['custom-queues']: {
        this.messagesCenterNavigationService.initCustomQueuesTree();
        return this.messagesCenterNavigationService.updateCustomQueuesTree();
      }
      case ROUTES_MAPPING_MESSAGES_CENTER.favorites: {
        return this.favoritesManagerService.getTree().toPromise();
      }
      case ROUTES_MAPPING_MESSAGES_CENTER['recent-searches']: {
        return this.recentSearchesManagerService.getTree().toPromise();
      }
      case ROUTES_MAPPING_MESSAGES_CENTER.callback: {
        return this.callerCallbackApiService.getCallerTree().then((callerTree) => {
          return this.messagesCenterNavigationService.initCallbackQueuesTree(callerTree);
        });
      }
      case ROUTES_MAPPING_MESSAGES_CENTER.templates: {
        this.messagesCenterNavigationService.initTemplatesQueuesTree();
        return this.messagesCenterNavigationService.updateTemplatesQueuesTree();
      }
    }
  }
}
