import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GroupActionsService } from '../../services/group-actions.service';

@Injectable()
export class GroupActionsResolver implements Resolve<any> {
  constructor(private groupActionsService: GroupActionsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {

    const entity = route.parent.data.entityType;
    const entityIdKey = route.parent.data.entityIdKey;

    return this.groupActionsService.getGroupActions(entity, [route.queryParams[entityIdKey]]).catch(() => {
      return [];
    });
  }
}
