import { Injectable } from '@angular/core';
import { Office } from '../../../shared/models/office.model';
import { NavigationItem, NavigationMap, MainTab, NavigationTabType, NavigationTab } from '../models';
import { ArchiveTab, CallerQueuesTree, CreatePaymentItem, InsightTab, MessagesCenterTab, ProfilesTab, Sitemap, SitemapComponent } from '../sitemap/models';
import { SitemapApiService } from '../sitemap/services/sitemap-api.service';
import { INavigationService } from './interfaces/navigation-service.interface';
import { NavigationMapperService } from './navigation-mapper.service';
import { ROUTES_MAPPING_MESSAGES_CENTER, ROUTES_MAPPING_RULES, RoutesMappingLiquidity } from '../models/consts/routes-mapping.const';
import { PbiTaskbarItem } from '../../../insight/enums/pbi-taskbar-item.enum';
import { BFPaymentTypes } from '../../../business-framework/messages/models/enums/bf-payment-types.enum';
import { FeatureFlagsService } from '../../feature-flags/services/feature-flags.service';
import { FeatureName } from '../../feature-flags/models/feature-name.enum';

@Injectable()
export class NavigationService implements INavigationService {

  private _sitemap: Sitemap;
  private _navigationMap: NavigationMap;
  private _sitemapPromise: Promise<Sitemap>;

  get sitemap(): Sitemap {
    return this._sitemap;
  }

  get navigationMap(): NavigationMap {
    return this._navigationMap;
  }

  constructor(private sitemapApiService: SitemapApiService,
              private navigationMapperService: NavigationMapperService,
              private featureFlagsService: FeatureFlagsService) {}

  getSitemap(): Promise<Sitemap> {
    if (!this._sitemapPromise) {
      this._sitemapPromise = this.loadSitemap();
    }
    return this._sitemapPromise;
  }

  isSitemap(): boolean {
    this.getSiteMapFromLocalStorage();
    return this._sitemap ? true : false;
  }

  getSitemapComponent(tab: MainTab): SitemapComponent {
    this.getSiteMapFromLocalStorage();
    return this._sitemap?.components.find((component: SitemapComponent) => component.id === tab);
  }

  getTabNavigationItem(tab: MainTab, key: string): NavigationItem {
    return this.navigationMap.get(tab)?.items.get(key);
  }

  getNavigationItem(key: string): NavigationItem {
    for (const tab of this.navigationMap.keys()) {
      const navigationItem = this.getTabNavigationItem(tab, key);
      if (navigationItem) {
        return navigationItem;
      }
    }
    return null;
  }

  isCallerPaymentApplicable(): boolean {
    return this.sitemap.modules.allowedPermissions['callerPayment'] === 'true';
  }

  getAllowedOffices(): Array<Office> {
    const offices = this._sitemap?.modules.allowedOffices;
    return this.mapOffices(offices);
  }

  getWriteOnlyOffices(): Array<Office> {
    let offices = this._sitemap?.modules.writeOnlyOffices;
    // This 'if' statement' is to keep backward compatibility for 2.3 version in which we don't have 'writeOnlyOffices' in the sitemap response
    if (!offices) {
      offices = this._sitemap?.modules.allowedOffices;
    }
    return this.mapOffices(offices);
  }

  getOfficeDepartments(office: string): Array<string> {
    return this._sitemap?.modules.officeDepartments[office];
  }

  getCreatePaymentItems(): Array<CreatePaymentItem> {
    const messagesCenterTab = this.getSitemapComponent(MainTab.MESSAGES_CENTER) as MessagesCenterTab;
    return messagesCenterTab.modules.createPayment.items;
  }

  private loadSitemap(): Promise<Sitemap> {
    return this.sitemapApiService.get().toPromise().then((response: Sitemap) => {
      this._sitemap = response;
      this.filterCreatePaymentItems();
      this._navigationMap = this.navigationMapperService.map(this._sitemap);
      return this._sitemap;
    });
  }

  private filterCreatePaymentItems(): void {
    const messagesCenterTab = this.getSitemapComponent(MainTab.MESSAGES_CENTER) as MessagesCenterTab;
    const createPaymentItems = messagesCenterTab.modules.createPayment.items;

    const isNewMessageScreensFeatureOn = this.featureFlagsService.isFeatureOn(FeatureName.newMessageScreensForCreate);

    if (isNewMessageScreensFeatureOn) {
      this.removeCreatePaymentItem(createPaymentItems, 'eventCreateCustomerMessage');
    } else {
      this.removeCreatePaymentItem(createPaymentItems, BFPaymentTypes.PACS_008_US_DOMESTIC);
      this.removeCreatePaymentItem(createPaymentItems, BFPaymentTypes.PACS_008_US_INTERNATIONAL);
      this.removeCreatePaymentItem(createPaymentItems, BFPaymentTypes.PACS_008_US_INTERNATIONAL_FX);
    }
  }

  private removeCreatePaymentItem(createPaymentItems: Array<CreatePaymentItem>, event: string): void {
    const index = createPaymentItems.findIndex(item => item.event === event);
    if (index !== -1) {
      createPaymentItems.splice(index, 1);
    }
  }

  private mapOffices(offices: { [key: string]: string }): Array<Office> {
    if (!offices) {
      return null;
    }
    return Object.keys(offices).map((office: string) => {
      return {
        name: office,
        alias: offices[office],
        isGlobal: office === '***'
      };
    });
  }

  isUrlAllowed(url: string): boolean {
    if (url.length <= 1 || url === '/home/page-not-found') {
      return true;
    }
    const urlParts = this.parseUrl(url);
    const mainTabComponent = this._navigationMap?.get(<MainTab>urlParts[1]) as NavigationTab;
    if (mainTabComponent && urlParts.length === 2) {
      return true;
    }
    const type = mainTabComponent?.type;
    if (type === NavigationTabType.INSIGHT) {
      return this.isInsightAllowed(urlParts);
    } else if (type === NavigationTabType.PROFILES) {
      return this.isProfilesAllowed(urlParts);
    } else if (type === NavigationTabType.MESSAGES_CENTER) {
      return this.isMessageCenterQueueAllowed(url, urlParts);
    } else if (type === NavigationTabType.LIQUIDITY) {
      return !!RoutesMappingLiquidity.getLiquidityCategory(urlParts[2]);
    } else if (type === NavigationTabType.ARCHIVE) {
      return this.isArchiveAllowed(urlParts);
    }
    return false;
  }

  isInsightAllowed(urlParts: Array<string>): boolean {
    const treeItem = this._navigationMap.get(<MainTab>urlParts[1])?.items?.get(urlParts[urlParts.length - 1]);
    if (urlParts.length === 4) {
      return treeItem ? true : false;
    }
    if (urlParts.length === 3) {
      const component = this.getSitemapComponent(<MainTab>urlParts[1]) as InsightTab;
      return component?.modules.inner_navigation?.nodes?.find(node => node.id === urlParts[2]) != null;
    }
    return false;
  }

  isInsightPbi(taskbarItemId: string): boolean {
    return taskbarItemId === PbiTaskbarItem.MANAGEMENT_REPORTS || taskbarItemId === PbiTaskbarItem.INSIGHT_DASHBOARDS;
  }

  isProfilesAllowed(urlParts: Array<string>): boolean {
    const profileId = urlParts[urlParts.length - 1];
    const profileData = this._navigationMap.get(<MainTab>urlParts[1])?.items?.get(profileId);
    if (profileData && profileData.stateParams) {
      return this.parseUrl(profileData.routerLink)[2] === urlParts[2];
    } else if (urlParts[2] === ROUTES_MAPPING_RULES && this._navigationMap.get(<MainTab>urlParts[1])?.items?.get('416')) {
      return true;
    } else if (urlParts.length === 3) {
      const component = this.getSitemapComponent(<MainTab>urlParts[1]) as ProfilesTab;
      const taskBarItem = component?.modules.inner_navigation?.nodes?.find(node => node.id === urlParts[2]);
      if (taskBarItem) {
        return true;
      }
    }
    return false;
  }

  isMessageCenterQueueAllowed(url: string, urlParts: Array<string>): boolean {
    if (urlParts[4] === 'createMessage') {
      return this.isCreatePaymentTypeAllowed(url);
    }
    if (urlParts[2] === ROUTES_MAPPING_MESSAGES_CENTER['recent-searches'] || urlParts[2] === ROUTES_MAPPING_MESSAGES_CENTER['favorites']) {
      return true;
    }
    if (ROUTES_MAPPING_MESSAGES_CENTER[urlParts[2]]) {
      if (urlParts.length === 3) {
        return true;
      }
      return this.checkOnQueueId(url, urlParts);
    }
    return false;
  }


  isCreatePaymentTypeAllowed(url: string): boolean {
    let paymentType = this.extractUrlQueryParam(url, 'paymentType');
    if (paymentType === 'eventCreateFxCustomerMessage') {
      if (this.featureFlagsService.isFeatureOff(FeatureName.fxFeature)) {
        return false;
      }
      paymentType = 'eventCreateCustomerMessage';
    }
    const createPaymentItems = this.getCreatePaymentItems();
    return createPaymentItems.some((item: CreatePaymentItem) => item.event === paymentType);
  }

  isArchiveAllowed(urlParts: Array<string>): boolean {
    const treeItem = this._navigationMap.get(<MainTab>urlParts[1])?.items?.get(urlParts[urlParts.length - 1]);
    if (urlParts.length === 3) {
      return !!treeItem;
    }
    if (urlParts.length === 2) {
      const component = this.getSitemapComponent(<MainTab>urlParts[1]) as ArchiveTab;
      return component?.modules.inner_navigation?.nodes?.find(node => node.id === urlParts[1]) != null;
    }
    return false;
  }

  checkOnQueueId(url: string, urlParts: Array<string>): boolean {
    let queueId;
    if (urlParts[2] === 'callback' && urlParts[urlParts.length - 1] !== 'single') {
      queueId = this.extractUrlQueryParam(url, 'uid');
    }
    if (!queueId) {
      queueId = this.extractUrlQueryParam(url, 'queueId');
      if (!queueId) {
        return false;
      }
      queueId = queueId.replace('/', '.');
    }
    return this._navigationMap.get(<MainTab>urlParts[1]).items?.get(`${urlParts[2]}.${urlParts[3]}.${queueId}`) != null;
  }

  parseUrl(url: string): Array<string> {
    url = this.getUrlPathAndParameters(url);
    url = this.getUrlPath(url);
    const urlParts = url.split('/');
    urlParts[0] = this.removePopOutPart(urlParts[0]);
    return urlParts;
  }

  addCallbackQueuesToNavigationMap(callerTree: CallerQueuesTree): void {
    const messagesCenterComponent = this.getSitemapComponent(MainTab.MESSAGES_CENTER);
    const items = this.navigationMapperService.mapCallbackQueue(callerTree, messagesCenterComponent);
    items.forEach((itemValue, itemKey) => {
      const itemExists = this.getNavigationItem(itemKey);
      if (!itemExists) {
        this._navigationMap.get(MainTab.MESSAGES_CENTER).items.set(itemKey, itemValue);
      }
    });
  }

  private getUrlPathAndParameters(url: string): string {
    const indexHash = url.indexOf('#!/');
    if (indexHash > -1) {
      url = url.substring(indexHash + 3);
    } else if (url.charAt(0) === '/') {
      url = url.substring(1);
    }
    return url;
  }

  private getUrlPath(url: string): string {
    const indexEndOfStates = url.indexOf('?');
    if (indexEndOfStates > -1) {
      url = url.substring(0, indexEndOfStates);
    }
    return url;
  }

  private removePopOutPart(urlPart: string): string {
    if (urlPart.includes('pop-out-')) {
      urlPart = urlPart.substring('pop-out-'.length);
    }
    return urlPart;
  }

  private extractUrlQueryParam(url: string, param: string): string {
    return decodeURIComponent(url).split(param + '=')[1]?.split('&')[0];
  }

  private getSiteMapFromLocalStorage(): void {
    if(!this._sitemap) {
      const siteMapLocalStorage = JSON.parse(window.localStorage.getItem('sitemap'));
      this._sitemap = siteMapLocalStorage ? siteMapLocalStorage.data : null;
    }
  }

}
