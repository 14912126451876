import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';
import { FileAttachmentFormComponent } from './components/file-attachment-form/file-attachment-form.component';
import { FileAttachmentManagerComponent } from './components/file-attachment-manager/file-attachment-manager.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FileAttachmentsListComponent } from './components/file-attachments-list/file-attachments-list.component';
import { MatMenuModule } from '@angular/material/menu';
import { FileAttachmentDetailsComponent } from './components/file-attachment-details/file-attachment-details.component';
import { FileAttachmentUploadedComponent } from './components/file-attachment-uploaded/file-attachment-uploaded.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';

@NgModule({
    declarations: [
        FileUploadComponent,
        FileAttachmentManagerComponent,
        FileAttachmentFormComponent,
        FileAttachmentsListComponent,
        FileAttachmentDetailsComponent,
        FileAttachmentUploadedComponent,
        AlertMessageComponent
    ],
    imports: [
        CommonModule,
        FileUploadModule,
        TranslateModule.forChild(),
        MatTabsModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatMenuModule,
        CommonModule
    ],
    exports: [FileAttachmentManagerComponent]
})
export class AttachmentsModule {

}
