import { EventEmitter, Injectable, Injector, OnDestroy } from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { SetSideNavigationMode } from '../../../state/actions/set-side-navigation-mode.action';
import { SidebarMode } from '../../../state/models/enums/sidebar-mode.enum';
import { LiquidityState } from '../../../state/liquidity.state';
import { NavigationService } from '../../services/navigation.service';
import { SitemapComponent } from '../../sitemap/models';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ITaskBarHandlerService } from './task-bar-handlers/task-bar-handler-service.interface';
import { Task_Bar_HANDLER_TOKENS } from '../models/task-bar-handler-tokens.const';
import { SideNavigationItem } from '../models/side-navigation-item.model';
import { LiquidityFullRoute } from '../../../models/liquidity.model';

@Injectable()
export class TaskBarService implements OnDestroy {

  @Select(LiquidityState.getLiquidityState) liquidityState$: Observable<LiquidityFullRoute>;

  taskBarStateChange = new EventEmitter();

  taskBars = {};

  taskBarState = {
    showSideBar: true,
    isLandingPage: false
  };

  taskBarCreated = false;

  liquidityTaskbarState: LiquidityFullRoute;

  private _subscriber = new Subscription();

  private _loading = new BehaviorSubject<boolean>(false);

  get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }

  get taskBarShowState() {
    return this.taskBarState.showSideBar;
  }

  set taskBarShowState(state) {
    this.taskBarState.showSideBar = state;
    this.taskBarStateChange.emit(state);
  }

  constructor(protected storeService: StoreService,
              private navigationService: NavigationService,
              private injector: Injector) {
    this.initTaskBarStateForLiquidity();
  }

  ngOnDestroy() {
    this._subscriber.unsubscribe();
  }

  init() {
    if (Object.keys(this.taskBars).length === 0 && this.navigationService.sitemap && !this.taskBarCreated) {
      this.taskBarCreated = true;
      this.navigationService.sitemap.components.forEach((component: SitemapComponent) => {
        const taskBarHandlerService = this.injector.get<ITaskBarHandlerService>(Task_Bar_HANDLER_TOKENS[component.type]);
        this.addTaskBar(component.id, taskBarHandlerService.getTaskBar(component));
      });
    }
  }

  getTaskBar(taskBarId) {
    this.validateTaskBarExistence(taskBarId);
    this.taskBarShowState = true; //expand taskbar when switching between main tabs
    const taskbar = this.filterTaskBar(this.taskBars[taskBarId]);
    return { taskBar: taskbar, lastState: this.liquidityTaskbarState };
  }

  setSideNavigationStateOnClick(isToggled: boolean) {
    const mode = isToggled ? SidebarMode.OPEN : SidebarMode.CLOSE;
    this.storeService.dispatch(new SetSideNavigationMode(mode));
  }

  emitLoading(value: boolean): void {
    this._loading.next(value);
  }

  private initTaskBarStateForLiquidity() {
    const subscription = this.liquidityState$.subscribe((liquidityState: LiquidityFullRoute) => {
      this.liquidityTaskbarState = liquidityState;
    });
    this._subscriber.add(subscription);
  }

  private addTaskBar(taskBarId, taskBar) {
    this.taskBars[taskBarId] = taskBar;
  }

  private validateTaskBarExistence(taskBarId) {
    if (taskBarId && taskBarId.length) {
      if (this.taskBars[taskBarId]) {
        return true;
      } else if (Object.keys(this.taskBars).length === 0 && this.navigationService.isSitemap()) {
        this.init();
      } else {
        throw new Error('Menu does not exists');
      }
    } else {
      throw new Error('MenuId was not provided');
    }
  }

  private filterTaskBar(taskBar) {
    taskBar.items = (taskBar.items as Array<SideNavigationItem>).filter((item) => {
      return item.shouldRender ? item.shouldRender() : true;
    });
    return taskBar;
  }

}
