import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrowsersService } from '../../services/browsers.service';
import { FormatsService } from '../../shared/services/formats.service';
import { GridConfig } from '@fgpp-ui/components';
import { TableConfig } from '@fgpp-ui/components';
import { PaginatorConfig } from '@fgpp-ui/components';
import { RefineFilteringConfig } from '@fgpp-ui/components';
import { FacetFilteringConfig } from '@fgpp-ui/components';
import { FilterButtonsConfig } from '@fgpp-ui/components';
import { ChipConfig } from '@fgpp-ui/components';
import { GridClass } from '../models/enums/grid-class';

@Injectable({
  providedIn: 'root'
})
export class GridConfigService {

  constructor(
    public browsersService: BrowsersService,
    private translateService: TranslateService,
    private formatsService: FormatsService
  ) {}

  get defaultGridPaginator() {
    return this.paginatorConfig;
  }

  get itemsPerPage() {
    let itemsPerPage = this.formatsService.itemsPerPage;
    if (isNaN(itemsPerPage)) {
       itemsPerPage = this.defaultGridPaginator.size;
    }
    return itemsPerPage;
  }

  get defaultGridConfig(): GridConfig {
    return {
      table: this.tableConfig,
      paginator: this.defaultGridPaginator,
      filterButtons: this.filterButtonsConfig,
      facetFiltering: this.facetFilteringConfig,
      filteringChips: this.filteringChipsConfig,
      formats: this.formatsService.formats
    };
  }

  get refineFilteringConfig(): RefineFilteringConfig {
    return {
      enableRefine: true,
      showRefine: true,
      texts: {
        operators: {
          contains: this.translateService.instant('grid.filter.labels.operators.contains'),
          startsWith: this.translateService.instant('grid.filter.labels.operators.starts_with'),
          endsWith: this.translateService.instant('grid.filter.labels.operators.ends_with'),
          equal: this.translateService.instant('grid.filter.labels.operators.equal'),
          notEqual: this.translateService.instant('grid.filter.labels.operators.no_equal'),
          notContains: this.translateService.instant('grid.filter.labels.operators.not_contains'),
          between: this.translateService.instant('grid.filter.labels.operators.between'),
          greaterThan: this.translateService.instant('grid.filter.labels.operators.greater_than'),
          lessThan: this.translateService.instant('grid.filter.labels.operators.less_than'),
          greaterThanOrEqual: this.translateService.instant('grid.filter.labels.operators.greater_then_equal'),
          lessThanOrEqual: this.translateService.instant('grid.filter.labels.operators.less_then_equal'),
          is: this.translateService.instant('grid.filter.labels.operators.is'),
          isEmpty: this.translateService.instant('grid.filter.labels.operators.is_empty'),
          in: this.translateService.instant('grid.filter.labels.operators.in')
        },
        operands: {
          date: this.translateService.instant('grid.filter.labels.operands.date'),
          time: this.translateService.instant('grid.filter.labels.operands.time'),
          datetime: this.translateService.instant('grid.filter.labels.operands.datetime'),
          from: this.translateService.instant('grid.filter.labels.operands.from'),
          to: this.translateService.instant('grid.filter.labels.operands.to'),
          minimum: this.translateService.instant('grid.filter.labels.operands.minimum'),
          maximum: this.translateService.instant('grid.filter.labels.operands.maximum'),
          true: this.translateService.instant('grid.filter.labels.operands.true'),
          false: this.translateService.instant('grid.filter.labels.operands.false'),
          closeButton: this.translateService.instant('grid.filter.labels.operands.buttons.close'),
          applyButton: this.translateService.instant('grid.filter.labels.operands.buttons.apply')
        }
      }
    };
  }

  get tableConfig(): TableConfig {
    return {
      enableSelection: true,
      multiSelect: true,
      enableSort: true,
      enablePin: !this.browsersService.isIE(),
      enableResize: true,
      defaultColumnWidth: 150,
      emptyMessage: this.translateService.instant('grid.no_data_msg'),
      refineFiltering:  this.refineFilteringConfig,
      tableClass: GridClass.SCROLLABLE_CONTENT,
      routing: {
        paramsProperty: 'stateParams'
      }
    };
  }

  get paginatorConfig(): PaginatorConfig {
    return {
      index: 0,
      size: 25,
      sizeOptions: [25, 50, 100],
      labels: {
        itemsPerPage: this.translateService.instant('grid.paginator.labels.itemsPerPage'),
        firstPage: this.translateService.instant('grid.paginator.labels.firstPage'),
        lastPage: this.translateService.instant('grid.paginator.labels.lastPage'),
        nextPage: this.translateService.instant('grid.paginator.labels.nextPage'),
        previousPage: this.translateService.instant('grid.paginator.labels.previousPage'),
        rangeLabel: this.translateService.instant('grid.paginator.labels.rangeLabel')
      }
    };
  }

  get filterButtonsConfig(): FilterButtonsConfig {
    return {
      refineFilterBtnConfig: { showButton: true, shouldToggleBtn: false, isDisabled: false },
      facetsFilterBtnConfig: { showButton: false, shouldToggleBtn: false, isDisabled: false },
      titles: {
        refineFilteringBtnText: this.translateService.instant('grid.filter.buttons.filter'),
        facetsFilteringBtnText: this.translateService.instant('grid.filter.buttons.advanced'),
      }
    };
  }

  get facetFilteringConfig(): FacetFilteringConfig {
    return {
      showFacetsFiltering: false,
      facetsFilteringContent: {
        facetActionButtonsTitles: {
          resetTitle: this.translateService.instant('grid.filter.facets.buttons.reset'),
          closeTitle: this.translateService.instant('grid.filter.facets.buttons.close'),
          applyTitle: this.translateService.instant('grid.filter.facets.buttons.apply'),
        },
        facetTitles: {
          showMoreTitle: this.translateService.instant('grid.filter.facets.show_more'),
          showLessTitle: this.translateService.instant('grid.filter.facets.show_less'),
          emptyFacetsTitle: this.translateService.instant('grid.filter.facets.empty_facets')
        },
        defaultAggregationShowCount: 3,
        maxAggregationCount: 10
      }
    };
  }
  get filteringChipsConfig(): ChipConfig {
    return {
      removable: true
    };
  }
}
