import { Injectable } from '@angular/core';
import { QUEUE_TYPES } from '../../../../../messages/models/consts/messages-queues.service';
import { MessagesQueueType } from '../../../../../messages/models/enums/messages-queue-type.enum';
import { NavigationService } from '../../../services/navigation.service';
import { MessagesCenterTab } from '../../../sitemap/models';
import { ITaskBarHandlerService } from './task-bar-handler-service.interface';

@Injectable()
export class MessageCentertTaskBarHandlerService implements ITaskBarHandlerService {

  constructor(private navigationService: NavigationService) { }

  getTaskBar(component: MessagesCenterTab) {
    const taskBar = {
      items: [{
        alias: 'main_window.system_queue',
        icon: 'widgets',
        id: QUEUE_TYPES[MessagesQueueType.SYSTEM_QUEUE],
        position: 0,
        routerLink: '/home/messages-center/system-queues',
        stateName: 'home.messagesCenter.layout.taskBar.system-queues',
        shouldRender: () => {
          return !!component.modules.system_queues_messages
            || !!component.modules.system_queues_files
            || !!component.modules.system_queues_bulks
            || !!component.modules.system_queues_batches;
        }
      },
        {
          alias: 'main_window.custom_queue',
          icon: 'format_list_bulleted',
          id: QUEUE_TYPES[MessagesQueueType.CUSTOM_QUEUE],
          position: 1,
          routerLink: '/home/messages-center/custom-queues',
          stateName: 'home.messagesCenter.layout.taskBar.custom-queues',
          shouldRender: () => {
            return !!component.modules.custom_queues_messages
            || !!component.modules.custom_queues_files
            || !!component.modules.custom_queues_bulks
            || !!component.modules.custom_queues_batches;
          }
        },
        {
          alias: 'main_window.my_favorite',
          icon: 'star',
          id: QUEUE_TYPES[MessagesQueueType.FAVORITE],
          position: 2,
          routerLink: '/home/messages-center/favorites',
          stateName: 'home.messagesCenter.layout.taskBar.favorites'
        },
        {
          alias: 'main_window.callback',
          icon: 'phone_outline',
          id: QUEUE_TYPES[MessagesQueueType.CALLBACK],
          position: 3,
          routerLink: '/home/messages-center/callback',
          stateName: 'home.messagesCenter.layout.taskBar.callback',
          shouldRender: () => {
            const actOnBehalfOfCaller = this.navigationService.sitemap.modules.allowedPermissions.actOnBehalfOfCaller === 'true';
            if (actOnBehalfOfCaller) {
              return !!component.modules.callback_queues_messages;
            }
            return false;
          }
        },
        {
          alias: 'main_window.recent_searches',
          icon: 'clock',
          id: QUEUE_TYPES[MessagesQueueType.RECENT_SEARCH],
          position: 4,
          routerLink: '/home/messages-center/recent-searches',
          stateName: 'home.messagesCenter.layout.taskBar.recent-searches'
        },
        {
          alias: 'main_window.templates',
          icon: 'file_document_multiple',
          id: QUEUE_TYPES[MessagesQueueType.TEMPLATES],
          position: 5,
          routerLink: '/home/messages-center/templates',
          stateName: 'home.messagesCenter.layout.taskBar.templates',
          shouldRender: () => {
            return !!component.modules.template_queues_messages;
          }
        }]
    };

    return taskBar;
  }
}
