import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { Entity } from '../../shared/models/enums/entity.enum';
import { MessagesHelperService } from './messages-helper.service';
import { GridConfigService } from '../../grid/services/grid-config.service';
import { MessagesQueue } from '../models/messages-queue.model';
import { GridManagementService } from '../../grid/services/grid-management.service';
import { MessagesApiService } from './messages-api.service';
import { BehaviorSubject, take } from 'rxjs';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { SearchQueryBuilderService } from '../../shared/services/search-query-builder.service';
import { MessagesBaseQueryFactoryService } from './messages-base-query-factory.service';
import { SearchRequestBuilderService } from '../../grid/services/search-request-builder.service';
import { MessagesQueueType } from '../models/enums/messages-queue-type.enum';
import { GridApiRoutes } from '../../grid/models/enums/grid-api.routes';
import { FormatsService } from '../../shared/services/formats.service';
import { ProcessingTimeScope } from '../../grid/models/enums/processing-time-scope.enum';
import { PROCESSING_SCOPE } from '../../grid/models/consts/processing-scope.const';
import { MessagesType } from '../models/enums/messages-type.enum';
import { GridConfig } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { FilterOperator } from '@fgpp-ui/components';
import { FacetsFilteringService } from '@fgpp-ui/components';
import { GridColumnsModel } from '../../grid/models/grid-columns.model';
import { SearchCondition } from '../../shared/search/models/classes/search-condition.model';
import { OfficesCounts } from '../../messages-center/messages-center-navigation/models/offices-counts.model';
import { QueueCountsSearchResponse } from '../models/queues-counts-search-response.model';
import { GridSearchQuery, SearchCriteria } from '../../grid/models/grid-search-query.model';
import { Operators } from '../../shared/search/models/enums/operators.enum';
import { BreadcrumbsService } from '../../ui-components/services/breadcrumbs.service';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { SMART_SEARCH_PARAMETER_NAME } from '../../shared/search/services/additional-parameters.service';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Injectable()
export class MessagesManagementService extends GridManagementService<GridSearchRequest> {

  readonly defaultSearchCondition = new SearchCondition(false, '_all', Operators.contains, ['*']);

  private _entity: Entity;
  public showSplit = false;

  get entity(): Entity {
    return this._entity;
  }

  set entity(entity: Entity) {
    this._entity = entity;
  }

  get queueType(): MessagesQueueType {
    return this.currentTreeItem?.queueType;
  }

  get messageType(): MessagesType {
    return MessagesType[this.entity.toUpperCase()];
  }

  get defaultFilters(): Array<SearchFilter> {
    let defaults: Array<SearchFilter> = [];
    switch (this.entity) {
      case Entity.MESSAGES:
        defaults = MessagesBaseQueryFactoryService.getMessagesBaseFiltersPerQueueType(this.currentTreeItem);
        break;
      case Entity.FILES:
      case Entity.BULKS:
      case Entity.BATCHES:
        if (this.currentTreeItem.queueType === MessagesQueueType.FAVORITE) {
          break;
        }
        if (this.currentTreeItem.queueType === MessagesQueueType.RECENT_SEARCH) {
          defaults.push(...SearchQueryBuilderService.searchCriteriaToFilters(this.currentTreeItem.searchQuery.searchCriteria));
          break;
        }
        let columnId = this.entity === Entity.BATCHES ? 'BATCH_SUMMARY_STATUS' : 'FILE_SUMMARY_F_FILE_STATUS';
        defaults.push({ columnId: columnId, operator: FilterOperator.EQUAL, value: [this.queueStatus] });
        if (!!this.currentTreeItem.office) {
          columnId = this.entity === Entity.BATCHES ? 'BATCH_SUMMARY_OFFICE' : 'FILE_SUMMARY_OFFICE';
          defaults.push({ columnId: columnId, operator: FilterOperator.EQUAL, value: [this.currentTreeItem.office] });
        }
        break;
    }

    return defaults;
  }

  get baseUrl(): string {
    return `${GridApiRoutes.do}/${this.entity}/`;
  }

  get httpParams(): HttpParams {
    const httpParams = new HttpParams().set('queueType', this.queueType);
    if (this.queueType === MessagesQueueType.RECENT_SEARCH) {
      return httpParams;
    }
    return httpParams.set('queueId', this.queueId);
  }

  get queueStatus(): string {
    return this.currentTreeItem.status;
  }

  itemSelected$: BehaviorSubject<MessagesQueue> = new BehaviorSubject(null);

  constructor(
    userSettingsService: UserSettingsService,
    private breadcrumbsService: BreadcrumbsService,
    public messagesHelperService: MessagesHelperService,
    protected gridConfigService: GridConfigService,
    private messagesApiService: MessagesApiService,
    searchRequestBuilderService: SearchRequestBuilderService<GridSearchRequest>,
    formatsService: FormatsService,
    protected translateService: TranslateService,
    protected facetsFilteringService: FacetsFilteringService,
    protected router?: Router, protected route?: ActivatedRoute) {
    super(userSettingsService, messagesHelperService, gridConfigService, searchRequestBuilderService, formatsService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.itemSelected$.unsubscribe();
  }

  onColumnSettingsChange(): Promise<GridColumnsModel> {
    return this.gridHelperService.getGridsColumns(this.baseUrl, this.httpParams);
  }

  getDefaultSearchRequest(): GridSearchRequest {
    const searchRequest = super.getDefaultSearchRequest();
    searchRequest.searchQuery.additionalParameters = MessagesBaseQueryFactoryService.getBaseAdditionalParams(this.currentTreeItem);
    return searchRequest;
  }

  onTreeItemSelect(queue: MessagesQueue) {
    this.updateCurrentQueue(queue);
    this.invokeGridDataChange(true);
    this.subscriber.add(this.gridData$.pipe(take(1)).subscribe(() => {
      this.setBreadcrumbs(queue);
      this.itemSelected$.next(queue);
    }));
  }

  onFavoritesItemSelect(queue: MessagesQueue) {
    this.updateCurrentQueue(queue);
    this.searchRequest.searchQuery = JSON.parse(JSON.stringify(queue.searchQuery || null));
    this.searchRequest.sort = JSON.parse(JSON.stringify(queue.sortBy || null));
    this.externalFilters = SearchQueryBuilderService.searchCriteriaToFilters(queue.searchQuery.searchCriteria);
    this.invokeGridDataChange(true);
    this.gridData$.pipe(take(1)).subscribe(() => {
      this.setBreadcrumbs(queue);
      this.itemSelected$.next(queue);
    });
  }

  getCurrentQueueData(): MessagesQueue {
    return { ...this.currentTreeItem };
  }

  protected reset() {
    this.showSplit = false;
    this.resetGridData();
    this.resetSearchRequest();
    this.facetsFilteringService.resetAggregatesListToggleStateMap();
  }

  updateCurrentQueue(queue: MessagesQueue) {
    this.currentTreeItem = queue;
    this.title = queue.alias;
    this.entity = Entity[queue.type.toUpperCase()];
    this.reset();
  }

  setBreadcrumbs(queue: MessagesQueue) {
    const translateStr = `breadcrumbs.${this.queueType}`;
    this.breadcrumbsService.setBreadcrumbs(queue, translateStr, this.queueStatus || queue.alias);
  }

  setProcessingScope(processingScope: ProcessingTimeScope) {
    const baseAdditionalParameters = MessagesBaseQueryFactoryService.getBaseAdditionalParams(this.currentTreeItem);
    const processingScopeParameters = PROCESSING_SCOPE[processingScope].additionalParameters;
    const additionalParameters = { ...baseAdditionalParameters, ...processingScopeParameters };
    this.setAdditionalParameters(additionalParameters);
  }

  getGridConfig(): GridConfig {
    const gridConfig = super.getGridConfig();
    gridConfig.filterButtons.facetsFilterBtnConfig.showButton = true;
    gridConfig.filterButtons.titles.refineFilteringBtnText = this.translateService.instant('grid.filter.buttons.basic');
    return gridConfig;
  }

  setTitle(filteredAmount?: number): void {
    if (!!this.currentTreeItem) {
      if (!!this.currentTreeItem.office) {
        const amount = filteredAmount ? filteredAmount.toFixed(2) : this.currentTreeItem.amount;
        this.title = this.currentTreeItem.statusAlias || '';
        this.title += this.currentTreeItem.alias ? ` | ${this.currentTreeItem.alias}` : '';
        this.title += amount ? ` | ${this.formatsService.toNumberFormat(amount)}` : '';
        this.title += this.currentTreeItem.currency ? ` ${this.currentTreeItem.currency}` : '';
      } else {
        if (this.currentTreeItem.queueType === MessagesQueueType.RECENT_SEARCH) {
          this.title = (!!this.currentTreeItem.alias && typeof (this.currentTreeItem.alias) === 'string') ? this.currentTreeItem.alias : '';
        }
      }
    }
    super.setTitle(filteredAmount);
  }

  setQueueTitle() {
    if (this.currentTreeItem.queueType === MessagesQueueType.RECENT_SEARCH) {
      this.setQueueTitleRecentSearch();
      return;
    }
    super.setQueueTitle();
  }

  private setQueueTitleRecentSearch() {
    if (this.title.includes('Quick: ')) {
      let title = this.title.substring('Quick: '.length);
      if (title !== '*'.repeat(title.length)) {
        title = title.substring(0, title.length - 1);
      }
      this.gridData.queueTitle = '"' + title + '"';
    } else if (this.title.includes('_all*')) {
      if (this.currentTreeItem.searchQuery.additionalParameters[SMART_SEARCH_PARAMETER_NAME]) {
        this.gridData.queueTitle = '"' + this.title.substring('_all*'.length) + '"';
      } else {
        this.gridData.queueTitle = this.title.substring('_all*'.length + 1, this.title.length - 1);
      }
    } else {
      this.gridData.queueTitle = this.title;
    }
  }

  getMultiCustomQueuesCounts(entityType: string, searchCondition: SearchCondition, udqNames: string[]): Promise<Array<OfficesCounts>> {
    if (udqNames.length === 0) {
      return Promise.reject('Expected udqNames to be non empty array, got size of 0');
    }

    const additionalParameters = {
      UDQS: udqNames,
      COUNTER_TYPE: 'user_defined_queues'
    };
    const searchRequest = this.buildSearchRequest(searchCondition, additionalParameters);
    const url = this.getBaseUrl(entityType);
    return this.messagesHelperService.doMultiSearch(searchRequest, url);
  }

  getSystemQueuesCounts(entityType: string, searchCondition?: SearchCondition): Promise<OfficesCounts> {
    const expression = searchCondition ? searchCondition : this.defaultSearchCondition;
    return this.getQueuesCounts(entityType, expression, { COUNTER_TYPE: 'system_queues' });
  }

  getCustomQueuesCounts(entityType: string, udqName: string): Promise<OfficesCounts> {
    if (!udqName) {
      return Promise.reject('no udqName');
    }

    return this.getQueuesCounts(entityType, this.defaultSearchCondition, {
      UDQ_NAME: udqName,
      COUNTER_TYPE: 'user_defined_queues'
    }, true);
  }

  async getQueuesCounts(entityType: string, expression: SearchCondition, params, singleStatus?): Promise<OfficesCounts> {
    const searchRequest = this.buildSearchRequest(expression, params);
    try {
      const url = this.getBaseUrl(entityType);
      const searchResult = await this.messagesApiService.getSearch(searchRequest, url) as QueueCountsSearchResponse;
      if (singleStatus === true) {
        return this.resolveSingleStatusQueueCount(searchResult);
      } else {
        return this.resolveStatusQueueCounts(searchResult);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  resolveStatusQueueCounts(searchResult: QueueCountsSearchResponse): Promise<OfficesCounts> {
    const statuses = this.messagesHelperService.aggregationsToMap(searchResult.aggregates);
    return Promise.resolve(statuses);
  }

  resolveSingleStatusQueueCount(searchResult: QueueCountsSearchResponse): Promise<OfficesCounts> {
    const statuses = this.messagesHelperService.countOffices(searchResult.aggregates);
    return Promise.resolve(statuses);
  }

  buildSearchRequest(searchCriteria: SearchCriteria, params: any): GridSearchRequest {
    const searchQuery: GridSearchQuery = {
      searchCriteria: searchCriteria,
      additionalParameters: { ...params }
    };

    return {
      pageNo: 1,
      sort: [],
      pageSize: 0,
      searchType: 'COUNT',
      searchQuery: searchQuery
    } as GridSearchRequest;
  }

  private getBaseUrl(entity: string): string {
    return `${GridApiRoutes.do}/${entity}/`;
  }

  switchBetweenEntities(goToEntity: Entity, params: Params, replaceEntity?: Entity): void {
    if (!replaceEntity) {
      replaceEntity = this.entity;
    }
    let url = this.router.url;
    url = url.replace(replaceEntity, goToEntity);
    url = url.substring(0, url.indexOf('?'));
    this.router.navigate([url], { queryParams: params });
  }
}
