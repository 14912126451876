import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LiquidityFullRoute } from '../../../core/models/liquidity.model';
import { MainTab } from '../../../core/navigation/models';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';
import { LIQUIDITY_DEFAULT_STATE } from '../../../core/state/liquidity.state';
import { LiquidityTaskBarItem } from '../../models';
import { LiquidityService } from '../../services/liquidity.service';

@Injectable()
export class RidirectLiquidityGuard implements CanActivate {

  constructor(private router: Router,
    private taskBarService: TaskBarService,
    private liquidityService: LiquidityService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let taskbar;
    try {
      taskbar = this.taskBarService.getTaskBar(MainTab.LIQUIDITY);
    } catch (e) {
      this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
      return false;
    }
    const targetState = taskbar.lastState;
    const path = targetState.path;
    if (this.isValidState(targetState)) {
      const queryParams = targetState.queryParams.account ? { ...targetState.queryParams, isLastState: true } : null;
      this.router.navigate([path], { queryParams });
    } else {
      this.router.navigateByUrl(LIQUIDITY_DEFAULT_STATE.path);
    }
    return false;
  }

  private isValidState(state: LiquidityFullRoute): boolean {
    if (!state) {
      return false;
    }
    const taskBarItem = state.path.split('/').pop() as LiquidityTaskBarItem;
    const taskBarItemService = this.liquidityService.getTaskBarItemService(taskBarItem);
    if (!taskBarItemService) {
      return false;
    }
    if (!state.queryParams.account) {
      return true;
    }
    const activeNode = taskBarItemService.findActiveNode(state.queryParams.account, state.queryParams.parent);
    return activeNode ? true : false;
  }

}
