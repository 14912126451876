import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import { RouterHelper } from '../../../base-view/classes/router-helper';
import {BreadcrumbsService} from "../../../ui-components/services/breadcrumbs.service";
import {MessagesManagementService} from "../../../messages/services/messages-management.service";
import {MessagesQueuesStateService} from "../../../messages/services/messages-queues-state.service";
import {MessagesQueues} from "../../../messages/models/consts/messages-queues.service";

@Injectable()
export class ResetQueueStateResolver implements Resolve<any> {

  constructor(private translateService: TranslateService, private breadCrumbsService: BreadcrumbsService,
              private messagesManagementService: MessagesManagementService,
              private messagesQueuesStateService: MessagesQueuesStateService,
              private messagesQueues: MessagesQueues, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    const queueType = this.messagesQueues.getMessagesQueueType(RouterHelper.getSegmentsFromUrl(this.router, state.url)[2].path);
    const queueId = route.queryParams.queueId;
    const entity = route.data.entityType;
    if(this.router.url.indexOf('single') > -1) {
      return;
    }
    this.messagesQueuesStateService.dispatchQueue(queueId, queueType, entity);
  }
}
