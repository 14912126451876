import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridConfigService } from './grid-config.service';
import { FormatsService } from '../../shared/services/formats.service';
import { GridColumnsModel } from '../models/grid-columns.model';
import { GridColumn } from '@fgpp-ui/components';
import { ColumnTypes } from '@fgpp-ui/components';


@Injectable({
  providedIn: 'root'
})
export class GridColumnsService {

  protected columnsObj: GridColumnsModel = {
    gridColumns: [],
    availableColumns: [],
    baseUrl: ''
  };

  constructor(
    private translateService: TranslateService,
    private gridConfigService: GridConfigService,
    private formatsService: FormatsService) { }

  getStoredColumns(): GridColumnsModel {
    return this.columnsObj;
  }

  protected getTranslateKey(key) {
    return key;
  }

  getColumns(metadata, baseUrl): GridColumnsModel {
    let gridColumns = [];
    const availableColumns = [];
    for ( let i = 0; i < metadata.length; i++) {
      const field = metadata[i];
      if (field.fieldAlias && field.availableInListView) {
        const gridColumn = this.getGridColumn(field);
        availableColumns.push(gridColumn);

        if (field.gridIndex >= 0) {
          gridColumns[field.gridIndex] = gridColumn;
        }
      }
    }
    gridColumns = gridColumns.filter((column: GridColumn) => column !== undefined);
    this.columnsObj = { gridColumns, availableColumns, baseUrl };
    return this.columnsObj;
  }

  private getGridColumn(field): GridColumn {
    return {
      type: field.dataType,
      name: field.fieldId,
      displayName: this.getDisplayName(field),
      pinned: field.pinned,
      sort: field.sort,
      sortable: field.sortable,
      width: this.getWidth(field),
      refinable: field.refinable,
      facetable: field.facetable,
      presentAsIcon: field.dataType === ColumnTypes.BOOLEAN,
      formatterCallback: this.formatsService.getFormatter(field.dataType)
    };
  }

  private getDisplayName(filed): string {
    const key = this.getTranslateKey(filed.fieldId);
    let displayName = this.translateService.instant(key);
    if (key === displayName) {
      displayName = filed.fieldAlias;
    }
    return displayName;
  }

  protected getWidth(field): number {
    if (field.width <= 0) {
      return this.gridConfigService.defaultGridConfig.table.defaultColumnWidth;
    }
    return field.width;
  }
}
