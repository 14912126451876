import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormatsService } from '@fgpp-ui/components';
import { ColumnSettingsService } from '../../../grid/services/column-settings.service';
import { ExportService } from '../../../shared/fn-ui-export/services/export.service';
import { TranslateService } from '@ngx-translate/core';
import { RulesProfilesManagementService } from '../../services/rules-profiles-management.service';
import { ProfilesGridComponent } from '../profiles-grid/profiles-grid.component';
import { FilterMapperService } from '../../../grid/services/filter-mapper.service';
import { ActionFilterService } from '@fgpp-ui/components';
import { ActionFilterMapperService } from '@fgpp-ui/components';
import { GroupActionsService } from '../../../messages-center/services/group-actions.service';
import { NotificationService } from '@fgpp-ui/components';
import { GridEventsService } from '../../../azure-insight/services/grid-events.service';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';

@Component({
  selector: 'app-rules-grid',
  templateUrl: '../profiles-grid/profiles-grid.component.html',
  styleUrls: ['../profiles-grid/profiles-grid.component.scss']
})
export class RulesGridComponent extends ProfilesGridComponent implements OnInit, OnDestroy {

  constructor(route: ActivatedRoute,
              router: Router,
              userSettingsService: UserSettingsService,
              gridEventsService: GridEventsService,
              public rulesProfilesResourceService: RulesProfilesManagementService,
              groupActionsService: GroupActionsService,
              notificationService: NotificationService,
              $translate: TranslateService,
              columnSettingsService: ColumnSettingsService,
              exportService: ExportService,
              filterMapperService: FilterMapperService,
              actionFilterMapperService: ActionFilterMapperService,
              actionFilterService: ActionFilterService, resolver: ComponentFactoryResolver,
              injector: Injector, app: ApplicationRef, cdr: ChangeDetectorRef, protected formatsService: FormatsService) {
    super(route, router, userSettingsService, gridEventsService, rulesProfilesResourceService, groupActionsService, notificationService,
      $translate, columnSettingsService, exportService, filterMapperService, actionFilterMapperService, actionFilterService,
      resolver, injector, app, cdr, formatsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriber.add(this.route.queryParams.subscribe((queryParams: Params) => {
      if (this.route.snapshot.data.gridEntityConfig) {
        this.setContainerConfig();
        this.updateCreateButtonAvailability();
      }
    }));
  }

  ngOnDestroy(): void {
    this.rulesProfilesResourceService.reset();
    super.ngOnDestroy();
  }

  protected setRoutingData(): void {
    this.routingData = {
      routerLink: this.router.url.split('?')[0] + '/single',
      stateName: 'home.rules.views.rules.single',
      stateParams: { ...this.route.snapshot.queryParams, mode: 'edit' }
    };
  }

  protected updateCreateButtonAvailability(): void {
    const disableCreate = this.profilesResourceService.hideNewButton;
    if (disableCreate) {
      this.gridContainerConfig.gridHeaderConfig.disableCreate = disableCreate;
    }
  }

}
