import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { GridEntityConfigService } from '../../../messages/services/grid-entity-config.service';

@Injectable({ providedIn: 'root' })
export class ProfileGridConfigResolver implements Resolve<GridContainerConfig> {

  constructor(private gridEntityConfigService: GridEntityConfigService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GridContainerConfig {
    return this.gridEntityConfigService.getProfilesGridConfig();
  }
}
