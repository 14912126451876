import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityTrackerComponent } from './components/activity-tracker/activity-tracker.component';
import { ActivityTrackerListComponent } from './components/activity-tracker-list/activity-tracker-list.component';
import { ActivityTrackerToggleFilterComponent } from './components/activity-tracker-toggle-filter/activity-tracker-toggle-filter.component';
import { ActivityTrackerErrorItemComponent } from './components/activity-tracker-error-item/activity-tracker-error-item.component';
import { ActivityTrackerAuditItemComponent } from './components/activity-tracker-audit-item/activity-tracker-audit-item.component';
import { ActivityTrackerRuleItemComponent } from './components/activity-tracker-rule-item/activity-tracker-rule-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { EmptyActivityLogListComponent } from './components/empty-activity-log-list/empty-activity-log-list.component';
import { HttpClientModule } from '@angular/common/http';
import { ActivityTrackerMapperService } from './services/activity-tracker-mapper.service';
import { ActivityTrackerService } from './services/activity-tracker.service';
import {ActivityTrackerApiService} from "../../common/services/activity-tracker-api.service";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCardModule} from "@angular/material/card";
import {ComponentsModule} from "@fgpp-ui/components";
import {FtTextPipe} from "./pipes/ftText.pipe";


@NgModule( {
  declarations : [
    ActivityTrackerComponent,
    ActivityTrackerListComponent,
    ActivityTrackerToggleFilterComponent,
    ActivityTrackerErrorItemComponent,
    ActivityTrackerAuditItemComponent,
    ActivityTrackerRuleItemComponent,
    EmptyActivityLogListComponent,
    FtTextPipe
  ],
  exports : [
    ActivityTrackerComponent,
    ActivityTrackerListComponent,
    ActivityTrackerToggleFilterComponent,
    ActivityTrackerErrorItemComponent,
    ActivityTrackerAuditItemComponent,
    ActivityTrackerRuleItemComponent,
    EmptyActivityLogListComponent,
  ],
  imports : [
    HttpClientModule,
    CommonModule,
    TranslateModule,
    MatBadgeModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatCardModule,
    ComponentsModule.forChild()
  ],
  providers:[
    ActivityTrackerService,
    ActivityTrackerApiService,
    ActivityTrackerMapperService,
  ],

})
export class ActivityTrackerModule { }
