import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {BreadcrumbsService} from "../../../ui-components/services/breadcrumbs.service";
import {MessagesManagementService} from "../../../messages/services/messages-management.service";

@Injectable()
export class RecentSearchBreadcrumbsResolver implements Resolve<any> {

  constructor(private breadCrumbsService: BreadcrumbsService,
              private messagesManagementService: MessagesManagementService,
              private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    const entity = route.data.entityType;
    const link = state.url;
    const condition = this.messagesManagementService.gridTitle;
    const fromRecentSearches = this.router.url.indexOf('recent-searches') > -1 && this.router.url.indexOf('single') === -1;
    if(fromRecentSearches) {
      this.breadCrumbsService.pushBreadcrumbRecentSearches(link.split('?')[0], entity, condition);
    }
  }
}
