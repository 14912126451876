import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {ProfilesManagementService} from "../../services/profiles-management.service";
import {NavigationService} from "../../../core/navigation/services/navigation.service";
import {NotificationService} from "@fgpp-ui/components";
import {ProfileComponentLoader} from "../../../business-framework/config/profile-component-loader";

@Injectable({ providedIn: 'root' })
export class IsNewUiProfileResolver implements Resolve<boolean> {
  constructor(private profilesManagementService: ProfilesManagementService,
              private navigationService: NavigationService,
              private notificationService: NotificationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean | any {
    const profileId = this.navigationService.getNavigationItem(route.params.profileId)?.data['profileId'];
    if (profileId) {
      const profileInfo = this.profilesManagementService.getProfileInfo(profileId);
      if(ProfileComponentLoader.isMigratedProfile(profileInfo.profileId)) {
        return {profileInfo}
      } else {
        return false;
      }
      // TODO : uncomment metadata call
/*      return this.profilesManagementService.businessFrameworkCheck(profileInfo.profileId).then(
        (response) => (response && (profileInfo.taskCode ? response.tasks[profileInfo.profileId] : response.profiles[profileInfo.profileId])) ? { profileInfo } : false
      );*/
    }
  }
}
