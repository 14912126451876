import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { RuleStatuses } from '../../single-rule/models/rule-statuses.model';
import { RuleCommonService } from '../../single-rule/services/rule-common.service';

@Injectable()
export class RuleStatusesResolver implements Resolve<RuleStatuses> {

  constructor(private authenticationService: AuthenticationService,
              private ruleCommonService: RuleCommonService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): RuleStatuses {
    const rule = this.ruleCommonService.rule;
    const isInitiator = this.authenticationService.userData.userId === rule.INITIATOR;
    const isRuleForApproval = route.queryParams.mode !== 'create' && ('PN' === rule.PROFILE_CHANGE_STATUS &&
      (!isInitiator || (isInitiator && rule.IS_SAME_APPROVER)));
    const isLockedByAnotherUser = route.queryParams.mode !== 'create' && (rule.IS_LOCKED_BY_ANOTHER_USER);
    const isReadOnly = isLockedByAnotherUser || (isRuleForApproval && route.queryParams.loadType !== '3') ||
      (!isRuleForApproval && route.queryParams.loadType === '3' && isInitiator && !rule.IS_EDITABLE) ||
      (route.queryParams.loadType === '2' && 'FU' === rule.PROFILE_CHANGE_STATUS);

    const isWritable = route.queryParams.mode === 'create' || rule.IS_WRITABLE;

    return {
      isCreate: route.queryParams.mode === 'create',
      isEditMode: route.queryParams.mode === 'edit',

      isRuleForApproval: isRuleForApproval,
      isLockedByAnotherUser: isLockedByAnotherUser,
      isReadOnly: isReadOnly,
      loadType: route.queryParams.loadType,
      isEditable: rule.IS_EDITABLE,
      isInitiator: isInitiator,
      isWritable: isWritable,

      isStatusHold: 'HD' === rule.REC_STATUS,
      isStatusDeleted: 'DL' === rule.REC_STATUS,
      isStatusNew: 'NW' === rule.REC_STATUS,
      isStatusActive: 'AC' === rule.REC_STATUS,
      isChangeStatusPN: 'PN' === rule.PROFILE_CHANGE_STATUS,
      isChangeStatusNO: 'NO' === rule.PROFILE_CHANGE_STATUS,
      isChangeStatusFU: 'FU' === rule.PROFILE_CHANGE_STATUS,

      canUserApproveOwnRules: rule.IS_SAME_APPROVER,
      isOpenedFromApprovalScreen: route.queryParams.loadType === '3',
      isOpenedFromInactiveScreen: route.queryParams.loadType === '6'
    };
  }

}
