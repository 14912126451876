import { ComponentManagerService, FormService } from '@fgpp-ui/components';
import { TaskBarService } from '../../../core/navigation/task-bar/services/task-bar.service';
import { FileAttachmentApiService } from '../../../shared/attachments/file-attachment-dialog/services/file-attachment-api.service';
import { FileAttachmentDialogService } from '../../../shared/attachments/file-attachment-dialog/services/file-attachment-dialog.service';
import {SystemParametersService} from "../../profiles/components/system-parameters/services/system-parameters.service";
import {CalendarService} from "../../profiles/components/calendar/services/calendar.service";
import {StandingOrderService} from "../../profiles/components/standing-order/standing-order.service";

export const ProfileFormTemplateProviders = [
  TaskBarService,
  FormService,
  ComponentManagerService,
  FileAttachmentApiService,
  FileAttachmentDialogService
];
export const SystemParameterProfileProviders = [...[SystemParametersService], ProfileFormTemplateProviders];
export const CalendarProfileProviders = [...[CalendarService], ProfileFormTemplateProviders];
export const StandingOrdersProfileProviders = [...[StandingOrderService], ProfileFormTemplateProviders];
