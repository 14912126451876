import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  ChangeDetectorRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FormatsService } from '@fgpp-ui/components';
import { ProfilesManagementService, PROFILE_UID } from '../../services/profiles-management.service';
import { TableRow } from '@fgpp-ui/components';
import { GroupActionsService, IGroupAction } from '../../../messages-center/services/group-actions.service';
import { ColumnSettingsService } from '../../../grid/services/column-settings.service';
import { ExportService } from '../../../shared/fn-ui-export/services/export.service';
import { GridContainerComponent } from '../../../grid/components/grid-container/grid-container.component';
import { TranslateService } from '@ngx-translate/core';
import { ProfilesSearchRequest } from '../../models/profiles-search-request.type';
import { PROFILES_SCOPES } from '../../models/consts/profiles-scopes.const';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { Scope } from '../../../grid/models/scope.model';
import { Resource } from '../../../grid/models/resource.model';
import { FilterMapperService } from '../../../grid/services/filter-mapper.service';
import { ActionFilterService } from '@fgpp-ui/components';
import { ActionFilterMapperService } from '@fgpp-ui/components';
import { NotificationService } from '@fgpp-ui/components';
import { GridEventsService } from '../../../azure-insight/services/grid-events.service';
import { SelectionEvent } from '@fgpp-ui/components';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';
import { UniqueIdFormat } from '../../../business-framework/profiles/models/unique-id-format.model';

export enum PROFILE_ID {
  RULE = '416',
  RULE_ASSOCIATION = '417',
  FAILED_INCOMING_INTERACTION = '481',
  UNIQUE_ID_FORMAT = '693'
}

@Component({
  selector: 'app-profiles-grid',
  templateUrl: 'profiles-grid.component.html',
  styleUrls: ['./profiles-grid.component.scss']
})
export class ProfilesGridComponent extends GridContainerComponent<ProfilesSearchRequest> implements OnDestroy, OnInit {

  @Input() overwriteRowClick = false;
  @Output() rowClicked = new EventEmitter<TableRow>();
  isInnerGrid = false;
  groupActions;
  interval;
  cntr;
  grid = <any>{
    options: <any>{}
  };
  flag = true;
  scopes: Scope[];
  selectedScope: Scope;

  get entity() {
    return Entity.PROFILES;
  }

  private sub: Subscription;

  get keyId(): string {
    return PROFILE_UID;
  }

  constructor(route: ActivatedRoute,
              protected router: Router,
              userSettingsService: UserSettingsService,
              gridEventsService: GridEventsService,
              public profilesResourceService: ProfilesManagementService,
              protected groupActionsService: GroupActionsService,
              protected notificationService: NotificationService,
              private translateService: TranslateService,
              columnSettingsService: ColumnSettingsService,
              exportService: ExportService,
              filterMapperService: FilterMapperService,
              actionFilterMapperService: ActionFilterMapperService,
              actionFilterService: ActionFilterService, resolver: ComponentFactoryResolver,
              injector: Injector, app: ApplicationRef,
              cdr: ChangeDetectorRef,
              protected formatsService: FormatsService) {
    super(route, userSettingsService, gridEventsService, profilesResourceService, columnSettingsService, exportService, filterMapperService,
      actionFilterMapperService, actionFilterService, resolver, injector, app,cdr, formatsService);
    this.scopes = PROFILES_SCOPES;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.initializeProfileGrid();
    });
  }

  protected initializeProfileGrid(): void {
    if (!this.isInnerGrid) {
      this.setContainerConfig();
      this.setRoutingData();
    }
    super.ngOnInit();
    this.applySorting();
    this.initSelectedScope();
    this.profileId = this.profilesResourceService.profileId;
  }

  setContainerConfig(): void {
    this.gridContainerConfig = this.route.snapshot.data.gridEntityConfig;
  }

  protected setRoutingData(): void {
    const isNewUIProfile = this.route.snapshot.data.isNewUIProfile;
    if (isNewUIProfile && isNewUIProfile.profileInfo) {
      const stateParamsSingleProfile = {
        mode: 'edit',
        profile: isNewUIProfile.profileInfo.profileId,
        taskCode: isNewUIProfile.profileInfo.taskCode
      };
      this.routingData = {
        routerLink: this.router.url,
        stateParams: { ...this.route.snapshot.queryParams, ...stateParamsSingleProfile },
        stateName: ''
      };
      return;
    } else if (this.route.snapshot.params.profileId === PROFILE_ID.RULE_ASSOCIATION) {
      this.routingData = {
        routerLink: this.router.url.split('?')[0],
        stateParams: { ...this.route.snapshot.queryParams, mode: 'edit' },
        stateName: ''
      };
    } else {
      this.routingData = {
        routerLink: this.router.url.split('?')[0],
        stateParams: { ...this.route.snapshot.queryParams },
        stateName: '',
        oldProfile: true
      };
    }
  }

  //Apply default sorting for the rule association grid.
  applySorting() {
    if (this.profilesResourceService.profileId === PROFILE_ID.RULE_ASSOCIATION) {
      this.interval = setInterval(this.handlerDefaultSorting.bind(this), 20);
    }
  }

  enableGroupActions() {
    if (this.profilesResourceService.profileId === PROFILE_ID.FAILED_INCOMING_INTERACTION) {
      this.gridContainerConfig.gridFooterConfig.groupActions.showGroupActionIcon = true;
      this.gridContainerConfig.gridFooterConfig.groupActions.showGroupActions = true;
      //this.groupActionsService = GroupActionsService;
    }
  }

  protected initSelectedScope() {
    let loadType = this.profilesResourceService.loadType;
    if (loadType) {
      loadType = Number(loadType);
      this.selectedScope = this.scopes.find(scope => scope.loadType === loadType);
      this.routingData.stateParams.loadType = this.selectedScope.loadType;
    } else if (this.routingData) {
      this.routingData.stateParams.loadType = PROFILES_SCOPES[0].loadType;
    }
  }

  handlerDefaultSorting() {
    if (this.grid && this.grid.options &&
      this.grid.options.gridApi) {
      for (const column of this.grid.options.gridApi.grid.columns) {
        if (column.name === 'PRULE_TYPES-RULE_TYPE_NAME') {
          column.sort = { direction: 'desc', priority: 0 };
        }
        if (column.name === 'OBJECT_PRULE_TYPES-OBJECTID_NAME') {
          column.sort = { direction: 'desc', priority: 1 };
        }
      }
      clearInterval(this.interval);
    }
    if (this.cntr > 20) {
      clearInterval(this.interval);
    }
    this.cntr++;
  }

  setContainerGridData(resource: Resource) {
    super.setContainerGridData(resource);
    if (this.profilesResourceService.profileId === PROFILE_ID.UNIQUE_ID_FORMAT) {
      this.translateFieldLogicalIdColumnData();
    }
  }

  private translateFieldLogicalIdColumnData() {
    const translatePrefix = 'business-framework.profiles.common.select-options.UNQ_ID_LF.';
    this.gridData.rows.forEach((row: TableRow) => {
      const key = translatePrefix + row[UniqueIdFormat.FIELD_LOGICAL_ID];
      const value = this.translateService.instant(key);
      if (key !== value) {
        row[UniqueIdFormat.FIELD_LOGICAL_ID] = value;
      }
    });
  }

  gridChanged() {
    setTimeout(() => {
      this.updateCreateButtonAvailability();
      const selectTypeFromSearchRequest = this.profilesResourceService.getSearchRequest()?.selectType;
      const selectType = this.getSelectType(selectTypeFromSearchRequest);
      if (selectType !== selectTypeFromSearchRequest) {
        this.profilesResourceService.setSearchScope(selectType);
      }
      this.selectedScope = this.scopes.find(scope => scope.selectType === selectType);
      if (this.gridContainerConfig.gridFooterConfig) {
        this.gridContainerConfig.gridFooterConfig.groupActions.showGroupActionIcon = false;
        this.gridContainerConfig.gridFooterConfig.groupActions.showGroupActions = false;
        this.enableGroupActions();
      }
    }, 0);
  }

  protected updateCreateButtonAvailability(): void {
    this.gridContainerConfig.gridHeaderConfig.disableCreate = this.profilesResourceService.hideNewButton;
  }

  private getSelectType(selectTypeFromSearchRequest) {
    if (selectTypeFromSearchRequest || selectTypeFromSearchRequest === 0) {
      return selectTypeFromSearchRequest;
    }
    return this.profilesResourceService.getDefaultSelectedType();
  }

  onRowClicked(row: TableRow) {
    this.isFetchingData = true;
    super.onRowClicked(row);
    if (this.overwriteRowClick) {
      this.rowClicked.emit(row);
    } else {
      const isOldUIProfile = this.routingData.oldProfile;
      if (isOldUIProfile) {
        setTimeout(() => { // Remove this timeout when we have a better solution
          this.profilesResourceService.onRowClicked(row, this.selectedRows, isOldUIProfile);
          this.isFetchingData = false;
        }, 0);
      } else {
        this.profilesResourceService.onRowClicked(row, this.selectedRows, isOldUIProfile);
      }
    }
  }

  onCreateProfile() {
    super.onCreate();
    if (!this.gridContainerConfig.gridHeaderConfig.disableCreate) {
      this.profilesResourceService.createProfile();
    }
  }

  onScopeChanged(index: number) {
    this.selectedScope = this.scopes[index];
    this.profilesResourceService.setSearchScope(PROFILES_SCOPES[index].selectType);
    if (this.routingData) {
      this.routingData.stateParams.loadType = PROFILES_SCOPES[index].loadType;
    }
    this.profilesResourceService.loadType = PROFILES_SCOPES[index].loadType;
  }

  onSelectionChanged($event: SelectionEvent) {
    super.onSelectionChanged($event);
    if (this.gridContainerConfig.gridFooterConfig?.groupActions.showGroupActions) {
      this.setGroupActions();
    }
  }

  setGroupActions() {
    const profileUids = this.selectedIds;
    if (profileUids.length) {
      this.groupActionsService.getProfileGroupActions(Entity.PROFILES, this.profilesResourceService.profileId).then((res) => {
          this.groupActions = res;
        },
        () => {
          if (this.groupActions) {
            this.groupActions.length = 0;
          }
        }
      );
    }
  }

  onActionClick(action: IGroupAction) {
    const uids = this.selectedIds;
    this.isFetchingData = true;
    this.groupActionsService.executeProfileGroupAction(Entity.PROFILES, this.profilesResourceService.profileId, action, uids)
    .then((response: any) => {
      if (response !== null) {
          super.onActionClick(action);
          this.gridComponent.resetSelection();
          setTimeout(() => { //TODO GPD-127057, REMOVE THIS TIMEOUT WHEN WE HAVE THE PROPER SOLUTION OF REFRESHING THE GRID AFTER GROUP ACTION
            this.profilesResourceService.invokeGridDataChange(true);
            if (response.errorMessage.includes('failed')) {
              this.notificationService.error(response.errorMessage);
            } else {
              this.notificationService.success(response.errorMessage);
            }
            this.isFetchingData = false;
          }, 1500);
        } else {
          this.isFetchingData = false;
        }
      })
      .catch((error) => {
        console.error(error);
        this.isFetchingData = false;
        this.notificationService.error(this.translateService.instant('profiles.general_error'));
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if(this.sub) {
      this.sub.unsubscribe();
    }
    this.isFetchingData = false;
  }
}
