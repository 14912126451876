import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FacetsFilteringService } from '@fgpp-ui/components';
import { FilterOperator } from '@fgpp-ui/components';
import { GridConfig } from '@fgpp-ui/components';
import { StoreService } from '../../core/services/store.service';
import { SetCallerAuthentication } from '../../core/state/actions/caller-authentication.actions';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { GridConfigService } from '../../grid/services/grid-config.service';
import { SearchRequestBuilderService } from '../../grid/services/search-request-builder.service';
import { SearchFilter } from '../../shared/models/search-filter.model';
import { FormatsService } from '../../shared/services/formats.service';
import { BreadcrumbsService } from '../../ui-components/services/breadcrumbs.service';
import { MessagesApiService } from './messages-api.service';
import { MessagesHelperService } from './messages-helper.service';
import { MessagesManagementService } from './messages-management.service';

@Injectable()
export class CallerMessagesManagementService extends MessagesManagementService {
  private _isCallerAuthenticated: boolean;
  private _filter: Array<SearchFilter>;

  get filter() {
    return this._filter;
  }

  set isCallerAuthenticated(value: boolean) {
    this._isCallerAuthenticated = value;
  }

  get isCallerAuthenticated(): boolean {
    return this._isCallerAuthenticated;
  }

  get defaultFilters(): Array<SearchFilter> {
    return this.filter;
  }

  constructor(userSettingsService: UserSettingsService,
            breadcrumbsService: BreadcrumbsService,
            messagesHelperService: MessagesHelperService,
            gridConfigService: GridConfigService,
            messagesApiService: MessagesApiService,
            searchRequestBuilderService: SearchRequestBuilderService<GridSearchRequest>,
            formatsService: FormatsService,
            translateService: TranslateService,
            facetsFilteringService: FacetsFilteringService,
            private storeService: StoreService) {
    super(userSettingsService, breadcrumbsService, messagesHelperService, gridConfigService, messagesApiService,
      searchRequestBuilderService, formatsService, translateService, facetsFilteringService);
  }

  setFilter(mids) {
    const filters: Array<SearchFilter> = new Array<SearchFilter>();
    filters.push({ columnId: 'P_IS_HISTORY', operator: FilterOperator.NOT_EQUAL, value: ['2'] });
    filters.push({ columnId: 'P_MID', operator: FilterOperator.IN, value: mids });
    this._filter = filters;
    return filters;
  }

  getGridConfig(): GridConfig {
    const gridConfig = super.getGridConfig();
    gridConfig.filterButtons.facetsFilterBtnConfig.showButton = false;
    return gridConfig;
  }

  dispatchCallerAuthentication(mode: boolean): void {
    this.storeService.dispatch(new SetCallerAuthentication({ mode }));
  }
}
