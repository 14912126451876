import { Injectable } from '@angular/core';
import { GridHelperService } from '../../grid/services/grid-helper.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { GridSearchRequest } from '../../grid/models/grid-search-request.model';
import { GridColumnsModel } from '../../grid/models/grid-columns.model';
import { Resource } from '../../grid/models/resource.model';
import { GridSort } from '../../grid/models/grid-sort.model';
import { TableRow } from '@fgpp-ui/components';
import { GridColumnsService } from '../../grid/services/grid-columns.service';
import { ProfilesApiService } from './profiles-api.service';
import { StaticDataDrillDownColumn, StaticDataDrillDownResource } from '../../grid/models/static-data-drill-down-resource.model';
import { GridColumn } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ExternalFilterGridHelperService extends GridHelperService {

  constructor(public profilesApiService: ProfilesApiService,
    gridColumnsService: GridColumnsService,
    protected http: HttpClient,
    translateService: TranslateService) {
    super(profilesApiService, gridColumnsService, http, translateService);
  }

  protected doSearch<T extends GridSearchRequest>(searchRequest: T, baseUrl: string, httpParams: HttpParams, columns: GridColumnsModel): Promise<Resource> {
    return this.gridApiService.getSearch(searchRequest, baseUrl, httpParams).then((result: Resource & StaticDataDrillDownResource) => {
      let data;
      if (result.dataModel) {
        data = this.convertStaticDataModelToResource(result, columns.availableColumns, searchRequest.sort ? searchRequest.sort[0] : null, baseUrl);
      } else {
        data = this.prepareDataObj(result, columns.gridColumns, columns.availableColumns);
      }

      return new Promise(function (resolve) {
        resolve(data);
      });
    }, (error: HttpErrorResponse) => {
      console.error(error);
      const data = this.prepareDataObj(null, columns.gridColumns, columns.availableColumns);
      return new Promise(function (resolve) {
        resolve(data);
      });
    });
  }

  protected convertStaticDataModelToResource(staticDataDrillDownResource: StaticDataDrillDownResource,
    availableColumns: GridColumn[], sort: GridSort, baseUrl: string): Resource {
    const gridColumns = this.convertToGridColumns(staticDataDrillDownResource.columnModel.columns, sort, baseUrl);
    const gridRows = this.convertToTableRows(staticDataDrillDownResource.dataModel, gridColumns);
    return {
      gridData: {
        rows: gridRows,
        totalCount: staticDataDrillDownResource.dataModel.length
      },
      columns: gridColumns,
      availableColumns: availableColumns,
      queueTitle: '',
      lastUpdate: new Date()
    };
  }

  convertToGridColumns(columns: Array<StaticDataDrillDownColumn>, sort: GridSort, baseUrl: string): Array<GridColumn> {
    const gridColumns = new Array<GridColumn>();
    columns.forEach(column => {
      const col = {
        type: column.type,
        name: column.name,
        displayName: this.translateService.instant(column.name),
        pinned: false,
        sort: sort && sort.fieldName === column.name ? { active: sort.fieldName, direction: sort.sortType } : null,
        sortable: null,
        width: 150,
        refinable: false,
        facetable: false
      };
      gridColumns.push(col);
    });
    const storedColumns = this.gridColumnsService.getStoredColumns();
    storedColumns.gridColumns = gridColumns;
    storedColumns.availableColumns = gridColumns;
    storedColumns.baseUrl = baseUrl;
    return gridColumns;
  }


  convertToTableRows(rowsArray: Array<Array<string>>, columns: Array<GridColumn>): Array<TableRow> {
    const rows = [];
    for (let i = 0; i < rowsArray.length; i++) {
      const rowArray = rowsArray[i];
      const row = {};
      for (let j = 0; j < rowArray.length; j++) {
        row[columns[j].name] = rowArray[j];
      }
      rows.push(row);
    }

    return rows;
  }
}
