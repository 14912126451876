import { Injectable } from '@angular/core';
import { FilterOperator } from '@fgpp-ui/components';
import { PRULES_RULE_TYPE_ID } from '../../rules/models/consts/rule-type.consts';
import { PROFILE_ID } from '../components/profiles-grid/profiles-grid.component';
import { ProfilesManagementService } from './profiles-management.service';

@Injectable()
export class RulesProfilesManagementService extends ProfilesManagementService {

  private isRulesLoad = false;

  onRowClicked(row: any, selectedRows: any[], isOldUIProfile: boolean): void {
    if (isOldUIProfile) {
      this.navigateToProfile(row, selectedRows);
    } else {
      console.log(row);
    }
  }

  setCreateButtonState(navigationTree): void {
    this.selectTreeItem(navigationTree);
    this.hideNewButton = this.shouldHideCreateButton(this.selectedTreeNode);
  }

  shouldHideCreateButton(node) {
    return node.nodes[0]?.data.hideNewButton === 'true';
  }

  async onRuleTypeSelected(ruleTypes: Array<string>): Promise<any> {
    this.setProfileId(PROFILE_ID.RULE);
    this.title = 'system_navigation.Business_Rules';

    const filters = [{
      columnId: PRULES_RULE_TYPE_ID,
      operator: FilterOperator.IN,
      value: ruleTypes
    }];

    if (this.route.snapshot.queryParams.filters) {
      filters.push(...JSON.parse(this.route.snapshot.queryParams.filters));
    }
    const promise = this.setExternalFilters(filters, this.isRulesLoad === false);

    this.isRulesLoad = true;
    return promise;
  }

  reset(): void {
    this.isRulesLoad = false;
  }

  selectTreeItem(navigationTree): void {
    this.selectedTreeNode = navigationTree;
  }
}
