import { Injectable, NgZone } from '@angular/core';
import { MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { NavigationExtras, Router } from '@angular/router';
import { NotificationService, SnackbarComponent } from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { SortDirection } from '@fgpp-ui/components';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { RulesRelatedProfileHandlerService } from '../../business-framework/profiles/services/rules-related-profile-handler.service';
import { MessageDisclaimerService } from '../../message/message-disclaimer/services/message-disclaimer.service';
import { MessagesService } from '../../message/services/messages.service';
import { ProfilesManagementService } from '../../profiles/services/profiles-management.service';
import { PopOutService } from '../../services/pop-out.service';
import { DialogFileAttachmentConfig } from '../../shared/attachments/file-attachment-dialog/models/dialog-file-attachment-config.model';
import { FileAttachmentDialogOutput } from '../../shared/attachments/file-attachment-dialog/models/file-attachment-dialog-output.model';
import { FileAttachmentDialogService } from '../../shared/attachments/file-attachment-dialog/services/file-attachment-dialog.service';
import { AttachmentsAccessService } from '../../shared/attachments/services/attachments-access.service';
import { SearchCondition } from '../../shared/search/models/classes/search-condition.model';
import { Operators } from '../../shared/search/models/enums/operators.enum';
import { IdleService } from '../idle/services/idle.service';
import { NavigationService } from '../navigation/services/navigation.service';
import { OfficeSelectorService } from './office-selector.service';
import { PopupService } from './popup.service';
import { FxRateService } from '../../message/messageTypes/fx-provider/services/fx-rate.service';
import { CheckBalanceRequestBody, CheckBalanceResponse } from '../../message/messageTypes/fx-provider/models/fx-rate.model';
import { FxLogicalFieldsService } from '../../message/messageTypes/fx-provider/services/fx-logical-fields.service';
import { LogicalFieldMetadata } from '../../message/messageTypes/fx-provider/models/logical-field.model';
import { SystemVariablesService } from './system-variables/system-variables.service';
import { SystemVariables } from './system-variables/models/system-variables.model';
import { MessageTemplateDownloadService } from '../../message/message-disclaimer/services/message-template-download.service';
import { firstValueFrom } from 'rxjs';
import {ProfileComponentLoader} from "../../business-framework/config/profile-component-loader";

@Injectable()
export class GPPFrontEndService {

  popupParams: any;
  saveAsParams: any;
  saveAsOffice: string;

  constructor(private zone: NgZone,
              private router: Router,
              private translateService: TranslateService,
              private popupService: PopupService,
              private popOutService: PopOutService,
              private systemVariablesService: SystemVariablesService,
              private navigationService: NavigationService,
              private authenticationService: AuthenticationService,
              private messagesService: MessagesService,
              private messageDisclaimerService: MessageDisclaimerService,
              private fileAttachmentDialogService: FileAttachmentDialogService,
              private rulesRelatedProfileHandlerService: RulesRelatedProfileHandlerService,
              private profilesManagementService: ProfilesManagementService,
              private idleService: IdleService,
              private notificationService: NotificationService,
              private attachmentsAccessService: AttachmentsAccessService,
              private officeSelectorService: OfficeSelectorService,
              private fxRateService: FxRateService,
              private fxLogicalFieldsService: FxLogicalFieldsService,
              private messageTemplateDownloadService: MessageTemplateDownloadService) { }

  openMessage = (mid: string): void => {
    this.messagesService.openMessage({ P_MID: mid });
  };

  openMessageWithParams = (mid: string, params: any): void => {
    this.messagesService.open({ P_MID: mid }, params);
  };

  getPopupFormData = (uid: string): any => {
    return this.popOutService.getPopoutFormData(uid);
  };

  getPopoutOriginalFormData = (uid: string): any => {
    return this.popOutService.getPopoutOriginalFormData(uid);
  };

  getPopOutParams = (): object => {
    return this.popOutService.stateParams;
  };

  getSystemParameters = (): SystemVariables => {
    return this.systemVariablesService.variables;
  };

  openMessageDisclaimer = (uid: string, data: any, windowId): void => {
    const popupWindow = this.popupService.getWindowById(windowId);
    popupWindow?.alert(this.translateService.instant('message.disclaimer_modal_open'));
    this.messageTemplateDownloadService.downloadTemplateFile(uid, data).then();
  };

  goToProcessingCommunications = (mid: string, windowId: string): void => {
    const profileId = 467;
    const navigationItem = this.navigationService.getNavigationItem(profileId.toString());
    if (!navigationItem) {
      return;
    }
    const filter = new SearchCondition(false, 'MESSAGE_EXTERNAL_INTERACTION.MID', Operators.equal, [mid]);
    const sort = { fieldName: 'MESSAGE_EXTERNAL_INTERACTION-TIME_STAMP', sortType: SortDirection.DESC };
    const path = navigationItem.routerLink;
    const queryParams = {
      ...navigationItem.stateParams,
      filters: JSON.stringify(filter),
      sort: JSON.stringify(sort)
    };
    this.navigate([path], { queryParams });
    this.popupService.getWindowById(windowId).alert(this.translateService.instant('processing_communication_page_open'));
  };

  goToExposures = (filterKey: string, filterValue: string): void => {
    const profileId = 708;
    const navigationItem = this.navigationService.getNavigationItem(profileId.toString());
    if (!navigationItem) {
      return;
    }
    const filter = new SearchCondition(false, filterKey, Operators.equal, [filterValue]);
    const sort = { fieldName: 'EXPOSURE_MEASUREMENT-TIME_STAMP', sortType: SortDirection.DESC };
    const path = navigationItem.routerLink;
    const queryParams = {
      ...navigationItem.stateParams,
      filters: JSON.stringify(filter),
      sort: JSON.stringify(sort)
    };
    this.navigate([path], { queryParams });
  };

  goToRelatedRules = (profileUid: string): Promise<boolean> => {
    return this.zone.run(() => {
      return this.rulesRelatedProfileHandlerService.goToRelatedRules(profileUid);
    });
  };

  goToRelatedOfficeRules = (office: string): Promise<boolean> => {
    return this.rulesRelatedProfileHandlerService.goToRelatedOfficeRules(office);
  };

  getSessionId = (): string => {
    return this.authenticationService.sessionId;
  };

  getToken = (): string => {
    return this.authenticationService.accessToken;
  };

  getPopupParams = (): any => {
    return this.popupParams ? JSON.parse(this.popupParams) : undefined;
  };

  setPopupParams = (popupParams: any): void => {
    this.popupParams = popupParams ? JSON.stringify(popupParams) : undefined;
  };

  purgePopupParams = (): void => {
    this.popupParams = undefined;
  };

  getSaveAsParams = (): any => {
    return this.saveAsParams ? JSON.parse(this.saveAsParams) : undefined;
  };

  setSaveAsParams = (saveAsParams): void => {
    this.saveAsParams = saveAsParams ? JSON.stringify(saveAsParams) : undefined;
  };

  purgeSaveAsParams = (): void => {
    this.saveAsParams = undefined;
  };

  getSaveAsOffice = (): string => {
    return this.saveAsOffice ? this.saveAsOffice : undefined;
  };

  setSaveAsOffice = (saveAsOffice: string): void => {
    this.saveAsOffice = saveAsOffice ? saveAsOffice : undefined;
  };

  openPopup = (params: any, profileId: string, windowId: string): void => {
    const jsonParams = Array.isArray(params) ? this.paramsToJson(params) : params;
    this.setPopupParams(jsonParams);
    this.popupService.open(jsonParams, profileId, windowId);
  };

  navigate = (commands: any[], extras?: NavigationExtras): Promise<any> => {
    return this.zone.run(() => {
      return this.router.navigate(commands, extras);
    });
  };

  refreshProfile = (): void => {
    this.profilesManagementService.updateProfilesView();
  };

  isMultiSelectMode = (): boolean => {
    return this.messagesService.isMultiSelectMode();
  };

  openNextMessage = (mid: string): void => {
    return this.messagesService.openNextMessage(mid);
  };

  notifyParentClosedPopOut = (mid: string): void => {
    this.popOutService.notifyPopOutClosed(mid);
  };

  getOfficeDepartment = (): { [key: string]: Array<string> } => {
    return this.navigationService.sitemap.modules.officeDepartments;
  };

  resetIdleTimer = (): void => {
    this.idleService.resetIdleTimer();
  };

  openNotification = (config: MatSnackBarConfig): MatSnackBarRef<SnackbarComponent> => {
    if (config.data?.message) {
      config.data.message = this.translateService.instant(config.data.message);
    }
    return this.notificationService.open(config);
  };

  openAttachments = (data: DialogFileAttachmentConfig, windowId: string): void => {
    const popupWindow = this.popupService.getWindowById(windowId);
    let message: string;
    if (!this.fileAttachmentDialogService.getIsDialogOpen()) {
      this.fileAttachmentDialogService.openAsPromise(data).then((result: FileAttachmentDialogOutput) => {
        this.onCloseAttachmentsDialog(windowId, result);
      });
      message = 'attachment_modal_open';
    } else {
      message = 'attachment_modal_already_exists';
    }
    popupWindow?.alert(this.translateService.instant(message));
  };

  getWriteOnlyOfficeDepartment = (): { [key: string]: Array<string> } => {
    return this.navigationService.sitemap.modules.writeOnlyOfficeDepartments;
  };

  translateLabel = (key: string): string => {
    return this.translateService.instant(key);
  };

  translateLabelWithParams = (key: string, params: Object): string => {
    return this.translateService.instant(key, params);
  };

  shouldAddAttachmentsButton = (profileId: string): boolean => {
    return this.attachmentsAccessService.supportAttachments(profileId);
  };

  getAttachmentsButtonPermission = (profileId: string, office: string, department: string, uid: string): Promise<string> => {
    return this.attachmentsAccessService.getUserPermission(profileId, office, department, uid);
  };

  convertDate = (date: string, format: string): string => {
    const dayjsObject = this.toDayjsObject(date, format);
    return dayjs.isDayjs(dayjsObject) ? dayjsObject.format('YYYY-MM-DD') : dayjsObject;
  };

  convertDateTime = (dateTime: string, format: string): string => {
    const dayjsObject = this.toDayjsObject(dateTime, format);
    return dayjs.isDayjs(dayjsObject) ? dayjsObject.format('YYYY-MM-DD HH:mm:ss') : dayjsObject;
  };

  openOfficeSelector = (callbackFunc): void => {
    this.officeSelectorService.open(true).then(callbackFunc);
  };

  checkBalanceForFxRate = (body: CheckBalanceRequestBody): Promise<CheckBalanceResponse> => {
    return this.fxRateService.checkBalanceForFxRate(body);
  };

  forceNsf = (mid: string): void => {
    this.fxRateService.forceNsf(mid);
  };

  isForceNsf = (): boolean => {
    return this.navigationService.sitemap.modules.allowedPermissions.forceNsf === 'true';
  };

  getLogicalFieldsForCurrency = (office: string, currency: string): Promise<Array<LogicalFieldMetadata>> => {
    return this.zone.run(() => {
      return firstValueFrom(this.fxLogicalFieldsService.getLogicalFieldArray(office, currency));
    });
  };

  getAllowedOffices = function () {
    return this.navigationService.getAllowedOffices();
  };

  createPayFromPay = function(items, mid) {
    this.messagesService.createPayFromPay(items, mid);
  };

  createTempFromPay = function(items, mid) {
    this.messagesService.createTempFromPay(items, mid);
  };


  private toDayjsObject(date: string, format: string) {
    const shortFormat = format.replace('YYYY', 'YY');

    let newDate = dayjs(date, [format, shortFormat], true);
    if (newDate.isValid()) {
      return newDate;
    }

    newDate = dayjs(date);
    if (newDate.isValid()) {
      return newDate;
    }

    newDate = dayjs(date, [format, shortFormat]);
    if (newDate.isValid()) {
      return newDate;
    }

    console.warn('The date is invalid');
    return '';
  }

  private paramsToJson = (params: Array<Array<any>>): any => {
    const jsonParams = {};
    params.forEach((param: Array<any>) => jsonParams[param[0]] = param[1]);
    return jsonParams;
  };

  private onCloseAttachmentsDialog = (windowId: string, result: FileAttachmentDialogOutput): void => {
    this.popupService.getWindowById(windowId).isAttachmentModalOpen = false;
    this.fileAttachmentDialogService.setIsDialogOpen(false);
    this.popupService.updatePopupAttanchmentData(windowId, result);
  };

  isProfileMigrated(profileId: string): boolean {
    return ProfileComponentLoader.isMigratedProfile(profileId);
  }

  public openRelatedEntityProfileInNewTab(params: any) {
    const navigationItem = this.navigationService.getNavigationItem(params.profile);
    let url = location.origin + '/gpp/#' + navigationItem.routerLink + `?mode=${params.mode}&profile=${params.profile}&isStandAlone=true`;
    url = params.mode === 'edit' ? url + `&loadType=${params.loadType}&uniqueRecId=${params.uniqueRecId}`: url + `&office=${params.office}`;
    const childWindow: any = window.open( url , '_blank' , 'width=1024px, height=768px, resizable=1, location=yes' );
  }

}
