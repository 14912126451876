import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuService } from '../../core/services/menu.service';

@Injectable({ providedIn: 'root' })
export class MenuResolver implements Resolve<void> {
  constructor(private menuService: MenuService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<void>|Promise<void>|void {
    this.menuService.init();
  }
}
