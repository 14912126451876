import {
  AfterContentInit, AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input, OnInit, Optional,
  Output,
  Renderer2
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FaFormComponent } from '../fa-form/fa-form.component';
import { FieldVerifications, FieldVerificationsService } from '../../services/field-verifications.service';
import { AbstractFormControlInputComponent } from '../abstract-form-control-input/abstract-form-control-input.component';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from '../../shared/models/enums/entity.enum';
import { TableRow } from '@fgpp-ui/components';
import { ExternalFilterGridData } from '../../grid/models/external-filter-grid-data.model';
import { Filter } from '../../shared/models/search-filter.model';
import { DrillDownGridService } from '../../grid/services/drill-down-grid.service';

export enum KEY_CODE {
  F4 = 115
}

@Component({
  selector: 'app-dh-drilldown',
  templateUrl: './dh-drilldown.component.html',
  styleUrls: ['./dh-drilldown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DhDrilldownComponent,
      multi: true
    }
  ]
})
export class DhDrilldownComponent extends AbstractFormControlInputComponent implements OnInit, AfterContentInit, AfterViewInit {

  private isDrillDownButtonInFocus = false;
  isDrillDownButtonClicked = false;
  private pendingNotifyOnChangeRequest = false;
  private _baseUrl;
  tooltip;

  @Input() label;
  @Input() uppercase = false;
  @Input() profileId: string;
  @Input() entity: Entity;
  @Input() allowMultiSelect = false;
  @Input() blockFreeText = false;
  @Input() filter: Filter;
  @Input() maxLength: number;
  @Input() isHiddenDrillDown = false;
  @Input() filterResultByColumn: string;
  @Input() isTooltipDisable = false;

  @Input() set baseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  @Output() rowsSelected: EventEmitter<{}[]>;
  @Output() onChangeAction: EventEmitter<{}>;

  get baseUrl() {
    return this._baseUrl;
  }

  constructor(@Inject(forwardRef(() => ControlContainer)) form: ControlContainer,
    @Optional() @Inject(forwardRef(() => FaFormComponent)) faFormComponent: FaFormComponent,
    renderer: Renderer2,
    protected elem: ElementRef,
    fieldVerificationsService: FieldVerificationsService,
    private translate: TranslateService,
    private drillDownGridService: DrillDownGridService) {
    super(form, faFormComponent, renderer, elem, fieldVerificationsService);
    this.rowsSelected = new EventEmitter<{}[]>();
    this.onChangeAction = new EventEmitter<{}>();
  }

  ngOnInit() {
    super.ngOnInit();
    // baseUrl input used as an infra to other drill downs types. for now baseUrl and Entities are for profiles only
    this.baseUrl = this.baseUrl ? this.baseUrl : 'do/PROFILE/' + this.profileId + '/';
    this.entity = this.entity ? this.entity : Entity.PROFILES;
    this.tooltip = this.translate.instant('message.field.tooltip.' + this.formControlName);
    if (this.tooltip === 'message.field.tooltip.' + this.formControlName) {
      this.tooltip = null;
    }
  }

  ngAfterContentInit() {
    if (!this.controller.disabled && this.metadata && this.metadata.verification === FieldVerifications.Rekey) {
      const labelElem = this.formControl.getElementsByClassName('mat-form-field-label-wrapper')[0];
      this.renderer.setAttribute(labelElem, 'inputValue', this.controller.value);
    }

    if (!this.controller.disabled && this.metadata && this.metadata.verification === FieldVerifications.Blind) {
      const labelElem = this.formControl.getElementsByClassName('mat-form-field-label-wrapper')[0];
      this.renderer.setAttribute(labelElem, 'inputValue', '*'.repeat(this.controller.value.length));
    }
  }

  private handleModalResults(selectedRows) {
    this.rowsSelected.emit(selectedRows);
    this.controller.markAsDirty();
    this.onChangeText(this.formControlName, this.controller.value, selectedRows);
  }

  onDrillDownClick($event?: MouseEvent) {
    window.setTimeout(() => {
      if (this.isDrillDownButtonClicked) {
        return;
      }
      this.isDrillDownButtonClicked = true;
      const controllerValue = this.uppercase && this.controller.value ? this.controller.value.toUpperCase() : this.controller.value;
      const filterKeys = this.filter ? Object.keys(this.filter.searchFilter) : [];
      const preDefinedFacets = this.drillDownGridService.getPreDefinedFacets(this.filter, this.filterResultByColumn, this.label, filterKeys, controllerValue);
      const data = this.getDrillDownDialogData(preDefinedFacets);
      this.drillDownGridService.externalFilterGridData = data;
      const dialogRef = this.drillDownGridService.openDialog(data);
      dialogRef.afterClosed().subscribe(
        (row: TableRow[]) => {
          this.isDrillDownButtonClicked = false;
          if (row || typeof (row) === 'object') {
            this.handleModalResults(row);
          }
        });
      if ($event) {
        $event.stopPropagation();
      }
    }, 0);
  }

  getDrillDownDialogData(iSearchFilter): ExternalFilterGridData {
    const isSelectType = !this.profileId;
    return {
      entity: this.entity,
      baseUrl: this.baseUrl,
      searchFilter: iSearchFilter,
      additionalParameters: this.drillDownGridService.getAdditionalParameters(this.filter, isSelectType),
      title: this.label,
      alias: this.alias,
      multiSelect: false,
      isSelectType: isSelectType
    };
  }

  onDrillDownInputChange(fieldId, event) {
    this.onInputChange();
    this.pendingNotifyOnChangeRequest = true;
    setTimeout(() => {
      if (!this.isDrillDownButtonInFocus && !this.isDrillDownButtonClicked) {
        this.onChangeText(fieldId, event.target.value);
      }
    }, 0);
  }

  onChangeText(fieldId, value, payload?) {
    this.pendingNotifyOnChangeRequest = false;
    if (this.uppercase) {
      value = value.toUpperCase();
      this.controller.setValue(value, { emitEvent: false });
    }
    this.onChangeAction.emit({ fieldId, value, payload });
  }

  onInput(event) {
    super.onInput(event);
    if (event.keyCode === KEY_CODE.F4) {
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      this.onDrillDownClick();
    }
  }

  onDrillDownButtonFocus() {
    this.isDrillDownButtonInFocus = true;
  }

  onDrillDownButtonBlur() {
    this.isDrillDownButtonInFocus = false;
    if (this.pendingNotifyOnChangeRequest && !this.isDrillDownButtonClicked) {
      this.onChangeText(this.formControlName, this.controller.value);
    }
  }

  ngAfterViewInit(): void {
    this.elem.nativeElement.setAttribute('azure-id', this.id);
  }
}
