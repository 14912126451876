import { Injectable, OnDestroy } from '@angular/core';
import { SearchCondition } from '../search/models/classes/search-condition.model';
import { Operators, OPERATOR_KEYS } from '../search/models/enums/operators.enum';
import { SearchExpression } from '../search/models/classes/search-expression.model';
import { SearchFilter } from '../models/search-filter.model';
import { Filter, FilterOperator, FilterType, BooleanValue, ColumnTypes, GridColumn } from '@fgpp-ui/components';
import { Select } from '@ngxs/store';
import { Observable, Subscription, filter } from 'rxjs';
import { UserPreferencesState } from '../../core/state/user-preferences.state';
import { UserPreferences } from '../../core/user-settings/models/user-preferences.model';
import { FormatsService } from './formats.service';
import { LogicalOperator } from '../search/models/enums/logical-operator.enum';
import { SearchCriteria } from '../../grid/models/grid-search-query.model';

export const EMPTY_VALUE = 'facets.empty.value';

@Injectable({
  providedIn: 'root'
})
export class SearchQueryBuilderService implements OnDestroy {

  private static formatsService: FormatsService;

  private static _regexNumber = /^\d+(.|,)\d+$/;
  private _subscriber = new Subscription();

  static set regexNumber(value: RegExp) {
    this._regexNumber = value;
  }

  static get regexNumber(): RegExp {
    return this._regexNumber;
  }

  @Select(UserPreferencesState.getUserPreferences) userPreferences$: Observable<UserPreferences>;

  constructor(formatsService: FormatsService) {
    SearchQueryBuilderService.formatsService = formatsService;
    this.initRegexOnUserPreferencesChange();
  }

  /**
   * Creates SearchCondition array out of the filters array, merge it with the default condition then builds and returns the search query
   * @param filters
   */
  static buildSearchQuery(filters?: Array<SearchFilter>): SearchCriteria {
    const searchQueries: Array<SearchCondition> = [];
    if (!!filters) {
      filters.forEach((filter) => {
        searchQueries.push(SearchQueryBuilderService.buildSearchCondition(filter));
      });
    }
    return SearchQueryBuilderService.buildSearchCriteria(searchQueries);
  }

  /**
   * Adjusting the operator, right operand and negative flag and creates a search condition
   * @param filter
   */
  static buildSearchCondition(filter: SearchFilter): SearchCondition {
    let not = false;
    let newValue = filter.value;
    let operator = Operators[filter.operator];

    switch (filter.operator) {
      case FilterOperator.CONTAINS:
        newValue = filter.value.map((value) => {
          if ('*'.repeat(value.length) !== value) {
            return `*${value}*`;
          } else if (value.length === 3) { // in case of searching for *** aka global office
            filter.operator = FilterOperator.EQUAL;
            operator = Operators[filter.operator];
            return value;
          } else {
            return value;
          }
        });
        break;
      case FilterOperator.ENDS_WITH:
        newValue = filter.value.map((value) => `*${value}`);
        break;
      case FilterOperator.STARTS_WITH:
        newValue = filter.value.map((value) => {
          if (filter.columnId === 'quick_search' && this.isNumber(value)) {
            const newVal = this.formatsService.toNumber(value).toString();
          if (value.toString().charAt(0) === '0') {
            return `*${value}*`;
           } else {
            return `${newVal}*`;
           }

          }
          return `${value}*`;
        });
        break;
      case FilterOperator.NOT_CONTAINS:
        newValue = filter.value.map((value) => `*${value}*`);
        not = true;
        break;
      case FilterOperator.NOT_EQUAL:
        not = true;
        break;
      case FilterOperator.IS_EMPTY:
        not = newValue[0] !== BooleanValue.TRUE;
        newValue = [EMPTY_VALUE];
        break;
      case FilterOperator.BETWEEN:
      case FilterOperator.GREATER_THAN:
      case FilterOperator.GREATER_THAN_OR_EQUAL:
      case FilterOperator.LESS_THAN:
      case FilterOperator.LESS_THAN_OR_EQUAL:
      case FilterOperator.EQUAL:
      default:
    }

    return new SearchCondition(not, filter.columnId, operator, newValue);
  }

  public static isNumber(str: string): boolean {
    return this._regexNumber.test(str);
  }

  /**
   * Build the search criteria as a binary tree
   * @param searchQueries
   */
   static buildSearchCriteria(searchQueries: Array<SearchCondition>): SearchCriteria {
    let result = null;
    while (searchQueries.length > 0) {
      if (result != null) {
        const newCondition = searchQueries[0];
        result = new SearchExpression(newCondition, LogicalOperator.AND, result);
      } else {
        result = searchQueries[0];
      }
      searchQueries.shift();
    }

    return result;
  }

  static searchCriteriaToFilters(searchCriteria: SearchCriteria): SearchFilter[] {
    const filters = [];
    if (searchCriteria) {
      if (searchCriteria.class === 'SearchCondition') {
        filters.push(this.searchConditionToFilter(<SearchCondition>searchCriteria));
      } else {
        const searchExpression = <SearchExpression>searchCriteria;
        filters.push(...this.searchCriteriaToFilters(searchExpression.leftOperand));
        filters.push(...this.searchCriteriaToFilters(searchExpression.rightOperand));
      }
    }
    return filters;
  }

  static searchConditionToFilter(searchCondition: SearchCondition): SearchFilter {
    const operator = searchCondition.operator;
    const value = searchCondition.rightOperand;
    let filterOperator, filterValue;
    switch (operator) {
      case 'like':
        if (value[0].startsWith('*')) {
          if (value[0].endsWith('*')) {
            filterOperator = searchCondition.not ? FilterOperator.NOT_CONTAINS : FilterOperator.CONTAINS;
            filterValue = value.map(item => item === '*'.repeat(item.length) ? item : item.slice(1, item.length - 1));
          } else {
            filterOperator = FilterOperator.ENDS_WITH;
            filterValue = value.map(item => item.slice(1));

          }
        } else {
          filterOperator = FilterOperator.STARTS_WITH;
          filterValue = value.map(item => item.slice(0, item.length - 1));
        }
        break;
      case '=':
        if (value[0] === EMPTY_VALUE) {
          filterOperator = FilterOperator.IS_EMPTY;
          filterValue = searchCondition.not ? BooleanValue.FALSE : BooleanValue.TRUE;
        } else {
          filterOperator = searchCondition.not ? FilterOperator.NOT_EQUAL : FilterOperator.EQUAL;
          filterValue = value.map(item => item);
        }
        break;
      default:
        filterOperator = OPERATOR_KEYS.get(operator);
        filterValue = value.map(item => item);
        break;
    }
    return {
      columnId: searchCondition.leftOperand,
      operator: filterOperator,
      value: filterValue
    };
  }

  static getSelectedFacets(iSearchFilter: Array<SearchFilter>, columns: GridColumn[]): Filter[] {
    const selectedFilters: Filter[] = [];
    iSearchFilter.forEach((filter: SearchFilter) => {
      if (filter.value.length) {
        let column: GridColumn;
        if (!columns) {
          return selectedFilters;
        }
        column = columns.find(col => col.name === filter.columnId);
        if (column) {
          selectedFilters.push({
            id: filter.columnId,
            type: SearchQueryBuilderService.getFilterType(column.type),
            alias: column.displayName,
            output: {
              operator: filter.operator,
              value: filter.value
            }
          });
        }
      }

    });
    return selectedFilters;
  }

  static getFilterType(type: ColumnTypes): FilterType {
    if (type === ColumnTypes.NUM_NO_DEC || type === ColumnTypes.NUMBER) {
      return FilterType.DECIMAL;
    }
    return FilterType[type];
  }

  static initRegex(thousandSeparator: string, decimalSeparator: string) {
    SearchQueryBuilderService._regexNumber = new RegExp('^\\d+((\\' + thousandSeparator
      + '\\d{3})+)?(\\' + decimalSeparator + '\\d+)?$');
  }

  initRegexOnUserPreferencesChange() {
    const subscription = this.userPreferences$.pipe(filter(up => !!up)).subscribe((userPreferences: UserPreferences) => {
      SearchQueryBuilderService.initRegex(userPreferences.thousandSeparator, userPreferences.decimalSeparator);
    });
    this._subscriber.add(subscription);
  }

  ngOnDestroy(): void {
    this._subscriber.unsubscribe();
  }

}
