import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SitemapNode, BaseNodeData } from '../../core/navigation/sitemap/models';
import { InsightTaskbarService } from '../services/insight-taskbar.service';

@Injectable()
export class InsightTaskbarResolver implements Resolve<any> {

  constructor(private insightTaskbarService: InsightTaskbarService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): SitemapNode<BaseNodeData> {
    return this.insightTaskbarService.getGuardsAndResolversTaskbar(route);
  }
}
