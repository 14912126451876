import {
  ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, Directive,
  AfterViewInit, ComponentFactoryResolver, Injector, ApplicationRef, ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormatsService } from '@fgpp-ui/components';
import { Select } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { GridComponent } from '@fgpp-ui/components';
import { GridConfig, GridData, GridColumn } from '@fgpp-ui/components';
import { TableRow, TableSort, TablePin } from '@fgpp-ui/components';
import { PaginatorData } from '@fgpp-ui/components';
import { Formats } from '@fgpp-ui/components';
import { UserPreferencesState } from '../../../core/state/user-preferences.state';
import { UserPreferences } from '../../../core/user-settings/models/user-preferences.model';
import { EnumHelpers } from '../../../shared/models/classes/enum-helper';
import { Entity } from '../../../shared/models/enums/entity.enum';
import { GridTourComponent } from '../../../tours/components/grid-tour/grid-tour.component';
import { GridManagementService } from '../../services/grid-management.service';
import { ColumnSettingsService } from '../../services/column-settings.service';
import { FilterMapperService } from '../../services/filter-mapper.service';
import { ExportService } from '../../../shared/fn-ui-export/services/export.service';
import { ExportData } from '../../../shared/fn-ui-export/models/export-data.model';
import { GridContainerConfig } from '../../models/grid-container-config.model';
import { Resource } from '../../models/resource.model';
import { SearchFilter } from '../../../shared/models/search-filter.model';
import { GridSearchRequest } from '../../models/grid-search-request.model';
import { SearchQueryBuilderService } from '../../../shared/services/search-query-builder.service';
import { ActionFilter, Filter, FilterOperator } from '@fgpp-ui/components';
import { ActionFilterMapperService } from '@fgpp-ui/components';
import { ActionFilterService } from '@fgpp-ui/components';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';
import { GridClass } from '../../models/enums/grid-class';
import { RoutingData } from '../../models/routing-data.model';
import { GridEventsService } from '../../../azure-insight/services/grid-events.service';
import { GridEventType } from '../../../azure-insight/interfaces/grid-event-type.enum';
import { IGroupAction } from '../../../messages-center/services/group-actions.service';
import { FilterEvent } from '@fgpp-ui/components';
import { SelectionEvent } from '@fgpp-ui/components';
import { PaginationAction } from '@fgpp-ui/components';
import { ROUTING_DATA } from '../../models/consts/routing-data.const';
import { MessagesQueueType } from '../../../messages/models/enums/messages-queue-type.enum';
import { DialogDataResponse } from '../../../shared/fn-ui-export/models/dialog-data-response.model';

@Directive()
export abstract class GridContainerComponent<T extends GridSearchRequest> implements OnInit, OnDestroy, AfterViewInit {
  gridConfig: GridConfig;
  gridData: GridData;
  gridColumns: Array<GridColumn>;
  queueTitle: string;
  isFetchingData = false;
  lastUpdate: Date;
  selectedRows = [];
  subscriber = new Subscription();
  selectedActionFilters: Array<ActionFilter> = [];
  protected profileId: string;

  displayTour = false;

  abstract get keyId(): string;

  get selectedIds(): string[] {
    return this.selectedRows.map(row => row[this.keyId]);
  }

  get entity() {
    return null;
  }

  @Input() gridContainerConfig: GridContainerConfig;
  @Input() footerTemplate: TemplateRef<ElementRef>;
  @Input() routingData: RoutingData;

  @Output() selectedRowsChange = new EventEmitter<Array<TableRow>>();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<TableRow>();

  @ViewChild(GridComponent, { static: true }) gridComponent: GridComponent;
  @Select(UserPreferencesState.getUserPreferences) userPreferences$: Observable<UserPreferences>;


  constructor(protected route: ActivatedRoute,
              private userPreferencesService: UserSettingsService,
              protected gridEventsService: GridEventsService,
              protected resourceService: GridManagementService<T>,
              protected columnSettingsService: ColumnSettingsService,
              private exportService: ExportService,
              protected filterMapperService: FilterMapperService,
              protected actionFilterMapperService: ActionFilterMapperService,
              protected actionFilterService: ActionFilterService, protected resolver: ComponentFactoryResolver,
              protected injector: Injector, protected app: ApplicationRef,
              protected cdr: ChangeDetectorRef,
              protected formatsService: FormatsService) {}

  ngOnInit() {
    this.gridConfig = JSON.parse(JSON.stringify(this.resourceService.getGridConfig()));
    this.gridConfig.table.routing = this.gridConfig.table.routing || {};
    this.gridConfig.table.routing.rowParams = ROUTING_DATA[this.route.snapshot.data.entityType];
    this.subscriber.add(this.resourceService.gridDataReset.subscribe(() => {
      this.resetGridData();
    }));
    this.subscriber.add(this.resourceService.fetchingDataInProgress.subscribe((isFetchingData) => {
      if (this.isFetchingData && !isFetchingData) {
        this.displayTour = true;
      }
      this.isFetchingData = isFetchingData;
      if (!isFetchingData && this.profileId === '467') {
        this.cdr.detectChanges();
      }
      if (!this.isFetchingData) {
        this.addDynamicToursComponent();
      }
    }));
    this.subscriber.add(this.resourceService.gridConfigChange.subscribe(showRefine => {
      this.gridConfig.table.refineFiltering.showRefine = showRefine;
    }));
    this.subscriber.add(this.userPreferences$.subscribe((formats: Formats) => {
      this.gridConfig.formats = formats;
      this.formatsService.formats = formats;
    }))
    this.onGridDataChange();
    this.gridChanged();
  }

  addDynamicToursComponent() {
    setTimeout(() => {
      let newNode = document.createElement('div');
      newNode.id = 'tours-container';
      document.getElementById('additional-content')?.appendChild(newNode);
      let factory = this.resolver.resolveComponentFactory(GridTourComponent);
      const ref = factory.create(this.injector, [], newNode);
      ref.instance.isMessagesGrid = this.entity === Entity.MESSAGES;
      this.app.attachView(ref.hostView);
    }, 0)
  }

  onOperatorChanged(event: FilterOperator) {
    const enumKey = EnumHelpers.getKeyFromValue(FilterOperator, event);
    this.gridEventsService.triggerEvent(GridEventType[enumKey]);
  }

  protected onGridDataChange() {
    this.subscriber.add(this.resourceService.currentData.subscribe((resource: Resource) => {
      if (!!resource) {
        this.setContainerGridData(resource);
      }
    }));
  }

  protected setContainerGridData(resource: Resource) {
    this.queueTitle = resource.queueTitle;
    this.lastUpdate = resource.lastUpdate;
    this.prepareSortObject(resource.columns);
    this.gridData = resource.gridData;
    this.gridColumns = resource.columns;
    const filterList = SearchQueryBuilderService.getSelectedFacets(this.resourceService.selectedFilters, resource.availableColumns);
    this.selectedActionFilters = filterList.length ? this.actionFilterService.getActionFilterList(filterList) : [];
  }

  gridChanged() { }
  /**
   * converting metadata's columns sort object to TableSort
   * @param gridData
   */
  prepareSortObject(columns: Array<GridColumn>) {
    columns.forEach(item => {
      if (item.sort) {
        item.sort = { direction: item.sort.direction, active: item.name };
      }
    });
  }

  onPageChanged(paginatorData: PaginatorData) {
    const oldPage = this.gridConfig.paginator.index;
    const newPage = paginatorData.pageNumber - 1;
    this.gridConfig.paginator.index = newPage;
    const isPageSizeChanged = paginatorData.pageSize !== this.gridConfig.paginator.size;
    if (isPageSizeChanged) {
      this.onItemPerPageChanged(paginatorData);
      this.gridEventsService.triggerPageSizeEvent(paginatorData);
    } else {
      paginatorData.action = this.detectPageAction(newPage, oldPage);
      this.gridEventsService.triggerPageEvent(paginatorData);
    }
    this.resourceService.setSearchPage(paginatorData);
  }

  private detectPageAction(newPage: number, oldPage: number): PaginationAction {
    const difference = Math.abs(newPage - oldPage);
    if (difference > 1) {
      return newPage < oldPage ? PaginationAction.FIRST_PAGE : PaginationAction.LAST_PAGE;
    } else {
      return newPage < oldPage ? PaginationAction.PREV_PAGE : PaginationAction.NEXT_PAGE;
    }
  }

  onItemPerPageChanged(paginatorData: PaginatorData) {
    this.gridConfig.paginator.size = paginatorData.pageSize;

    const userPreference = this.userPreferencesService.getPreferences();
    userPreference.itemsPerPage = paginatorData.pageSize;
    this.userPreferencesService.save(userPreference);
  }

  onColumnPinChange(pin: TablePin) {
    this.gridEventsService.triggerPinEvent(pin);
    this.saveColumnsState();
  }

  onSortChanged($event: TableSort) {
    this.gridEventsService.triggerSortEvent($event);
    this.gridComponent.resetSelection();
    this.gridComponent.resetPagination();
    this.gridColumns.forEach(item => {
      this.castMatSortObj(item, $event);
    });
    this.saveColumnsState();
    this.resourceService.setSearchSort($event);
  }

  castMatSortObj(column: GridColumn, $event) {
    const sort = { direction: $event.direction, priority: 0 };
    if (column.name === $event.active && $event.direction) {
      column.sort = { direction: sort.direction, active: column.name };
    } else {
      column.sort = null;
    }
  }

  onSelectionChanged($event: SelectionEvent) {
    if (this.selectedRows.length != $event.data.length) {
      this.gridEventsService.triggerSelectionEvent($event.selectionEventType);
    }
    this.selectedRows = $event.data;
    this.selectedRowsChange.emit(this.selectedRows);
    if (!!this.gridData) {
      this.rowSelected.emit({ selectedRows: $event.data, allRows: this.gridData.rows });
    }
  }

  onFilterChanged(filterEvent: FilterEvent) {
    this.gridEventsService.triggerFilterEvent(filterEvent.filterType);
    this.gridComponent.resetPagination();
    this.gridComponent.resetSelection();
    const filters = this.actionFilterMapperService.mapActionFiltersToFilters(filterEvent.data);
    const searchFilters = this.filterMapperService.mapAll(filters);
    this.resourceService.selectedFilters = searchFilters;
    this.resourceService.setInternalFilters(searchFilters);
  }

  onColumnSettingsClick() {
    this.openColumnSettings();
  }

  openColumnSettings() {
    const availableColumns = this.resourceService.availableColumns;
    const gridColumns = this.gridColumns;
    const shouldShowApplyAll = this.entity === Entity.MESSAGES && this.resourceService.currentTreeItem.queueType != MessagesQueueType.RECENT_SEARCH;
    this.columnSettingsService.openDialog(availableColumns, gridColumns, shouldShowApplyAll).afterClosed().subscribe(
      (result: DialogDataResponse| string) => {
        this.resourceService.handleColumnSettings(result)?.then((gridColumnsModel) => {
          this.updateSelectedFacets(gridColumnsModel.gridColumns);
        });
      });
  }

  updateSelectedFacets(result: GridColumn[]) {
    const filters = this.actionFilterMapperService.mapActionFiltersToFilters(this.gridComponent.selectedFilters);
    const updatedFilters: Array<Filter> = new Array<Filter>();
    filters.forEach((item) => {
      if (result.findIndex(c => c.name === item.id) !== -1) {
        updatedFilters.push(item);
      }
    });
    const searchFilters = this.filterMapperService.mapAll(updatedFilters);
    this.resourceService.selectedFilters = searchFilters;
    this.resourceService.setInternalFilters(<Array<SearchFilter>>searchFilters);
  }

  onColumnSizeChanged() {
    this.saveColumnsState();
  }

  onExportClick() {
    this.exportService.openDialog(this.exportData);
  }

  onPrintClick() {
    this.exportService.print(this.exportData);
  }

  onRefreshClick(isClicked = true) {
    if (isClicked) {
      this.gridEventsService.triggerEvent(GridEventType.REFRESH);
    }
    this.gridComponent.resetSelection();
    this.gridComponent.resetPagination();
    this.formatsService.emitReloadFormats();
    const paginatorData: PaginatorData = { pageNumber: 1, pageSize: this.gridConfig.paginator.size };
    this.resourceService.setSearchPage(paginatorData);
  }

  onRefineFilteringClick($event: boolean) {
    this.gridEventsService.triggerEvent(GridEventType.BASIC_FILTER);
    this.gridConfig.table.refineFiltering.showRefine = $event;
    this.gridConfig.table = { ...this.gridConfig.table };
    this.resourceService.saveGridPreferences($event);
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  protected resetGridData() {
    this.gridComponent.reset();
    this.resourceService.resetSearchRequest();
    this.gridChanged();
  }

  protected saveColumnsState(): Promise<void> {
    return this.resourceService.saveColumns(this.gridColumns);
  }

  get exportData(): ExportData {
    return {
      searchRequest: this.resourceService.getSearchRequest(),
      from: 1,
      totalCount: this.gridData.totalCount,
      selectedItems: this.selectedIds,
      itemId: this.keyId,
      baseUrl: this.resourceService.baseUrl,
      httpParams: this.resourceService.httpParams
    };
  }

  setGridDenseMode(isDenseMode: boolean) {
    if (isDenseMode) {
      if (!this.gridConfig.table.tableClass.includes(GridClass.DENSE_MODE)) {
        this.gridConfig.table.tableClass = this.gridConfig.table.tableClass.concat(' ').concat(GridClass.DENSE_MODE);
      }
    } else {
      this.gridConfig.table.tableClass = this.gridConfig.table.tableClass.replace(' ' + GridClass.DENSE_MODE, '');
    }
    this.gridConfig = JSON.parse(JSON.stringify(this.gridConfig));
    this.selectedRows = [...this.selectedRows];
  }

  onCreate() {
    this.gridEventsService.triggerEvent(GridEventType.CREATE);
  }

  onActionClick(action: IGroupAction) {
    this.gridEventsService.triggerGroupActionsEvent(action);
  }

  onRowClicked(row: TableRow) {
    this.gridEventsService.triggerEvent(GridEventType.ENTER_ROW);
  }

  ngAfterViewInit(): void {
    this.gridComponent['elem'].nativeElement.setAttribute('azure-id', 'grid-event');
  }
}
