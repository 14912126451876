import { Injectable, Component } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { TaskbarItemConstants } from '../base-view/config/taskbar-item-constants';
import {ROUTES_MAPPING_RULES} from "../core/navigation/models/consts/routes-mapping.const";

@Injectable({ providedIn: 'root' })
export class RuleTaskBarComponentResolver implements Resolve<any> {
  constructor(private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Component {
    const parent = route.parent.routeConfig.path;
    const taskBarItem = route.params.taskBarItem;
    let component;
    component = TaskbarItemConstants.taskBarItemCodeToComponent[parent][ROUTES_MAPPING_RULES];
    if (!component) {
      const error = 'no form component found, component code = ' + [parent][taskBarItem];
      console.log(error);
      this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
      return;
    }
    return component;
  }
}
