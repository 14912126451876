import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { RuleCommonService } from '../../single-rule/services/rule-common.service';

@Injectable()
export class RuleTypesResolver implements Resolve<string> {

  constructor(private router: Router,
              private ruleCommonService: RuleCommonService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    const currentUrl = this.router.url;
    const urlTree = this.router.parseUrl(currentUrl);
    const ruleTypes = urlTree.queryParams.ruleType;
    this.ruleCommonService.ruleTypes = ruleTypes;
    this.ruleCommonService.isNeedCheckBeforeClose = true;
    return ruleTypes;
  }

}
