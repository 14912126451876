import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@fgpp-ui/components';
import { RulesAssociationApiService } from './rules-association-api.service';
import { Injectable } from '@angular/core';
import { RuleStatuses } from '../interfaces/rule-statuses.interface';
import { Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { FnUiDialogService } from '../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { NavigationService } from 'projects/gpp/src/app/core/navigation/services/navigation.service';
import { MainTab, NavigationItem } from 'projects/gpp/src/app/core/navigation/models';
import { PROFILE_ID } from '../../../../profiles/components/profiles-grid/profiles-grid.component';
import { ConfirmModalComponent } from '../../../../shared/fn-ui-modals/components/confirm-modal/confirm-modal.component';
import { ModalSize } from '../../../../shared/fn-ui-modals/models/enums/modal-size.enum';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RulesAssociationCommonService {
  selectedRule: any;
  originalRule: any;
  rule: any;

  private OFFICE: undefined;
  private DEPARTMENT: undefined;
  private DESCRIPTION: undefined;
  private RULE_TYPE_ID: undefined;
  private RULE_NAME: undefined;
  private PROFILE_CHANGE_STATUS: string;
  private ruleTextHolderElement: JQLite;
  private ruleSubTypeValue: string;
  private originalRuleAssociation: string;
  public ruleAssociationStatuses: RuleStatuses;
  public showAuditDetails: boolean = false;

  public show = {
    previewRule: false,
    auditTrail: false,
    conditionsBank: true,
    isTileSelected: true
  }
  public UID: undefined;
  public ALL_LAYER: string;
  public isListReadOnly: boolean = false;
  public isEditMode: boolean;
  public virtualList: Array<any> = [];
  showSaveButton: boolean;
  disableAssociationDetails: boolean = false;
  LAYER_CLASSIFICATION_RELATION: string;
  ObjectsDataOptions: any[];
  objectTypeOffice: any;
  outputParam: any;
  selectedRuleTypeName: any;
  LAYER_CLASSIFICATION: any;
  ObjectsData: any;
  RULES: any[];
  undoStack: any[] = [];
  redoStack: any[] = [];
  RULE_SUB_TYPE: string;
  REC_STATUS: string;
  isNeedCheckBeforeClose: boolean = true;
  subType: { profileId: string; };


  selectedRulesString: any;
  isReadOnlyMode: boolean;
  createMode: boolean;
  enableMoveUp: boolean;
  enableMoveDown: boolean;
  enableMoveToTop: boolean;
  enableMoveToBottom: boolean;
  enableRemoveOptions: any;
  enableRemoveAllOptions: any;
  enablePreview: boolean;
  saveDisable: boolean;
  moreDisable: boolean;
  undoRedoInProgress: any;
  isChangeStatusPN: boolean;
  isInitiator: boolean;
  isRuleForApproval: boolean;
  topIndex: number;
  filterString: string = '';
  associationExists: boolean;
  modalVisible: boolean;
  selectedRuleTypeId: '';
  selectedObjectType: string;
  selectedAssociatedTo: string;
  RULE_TYPE_NAME: any;
  ruleTypeDetails = new BehaviorSubject({});
  rulesGridRows = new BehaviorSubject({});
  showRulesList: boolean = true;
  ruleList = new BehaviorSubject({});
  private updatedList =  [];

  get navigationItem(): NavigationItem {
    return this.navigationService.getTabNavigationItem(MainTab.RULES, PROFILE_ID.RULE_ASSOCIATION)
  }

  constructor(private translate: TranslateService,
    private notificationService: NotificationService,
    private rulesAssociationApiService: RulesAssociationApiService,
    private dialogService: FnUiDialogService,
    private router: Router,
    private navigationService: NavigationService) {
      this.ruleList.subscribe((value: []) => {
        this.updatedList = value;
      });
  }

  /*  reset() {
      this.originalRule = {};
      this.rule = {};

      this.show = {
        previewRule: false,
        auditTrail: false,
        conditionsBank: true,
        isTileSelected: true
      };

      this.isNeedCheckBeforeClose = true;
      this.isListReadOnly = true;
      this.selectedRulesString = undefined;
      this.isReadOnlyMode = false;
      this.createMode = false;


      this.enablePreview = false;
      this.saveDisable = false;
      this.moreDisable = false;
      this.isChangeStatusPN = false;
      this.isInitiator = false;
      this.isRuleForApproval = false;
      this.topIndex = 0;
      this.filterString = '';
      this.associationExists = false;
      this.modalVisible = false;
      this.disableAssociationDetails = false;
      this.isEditMode = false;
      //New feature for rule evaluation F8194 -Run Time classification and evaluation per class

    }*/

  resetContentArea() {
    this.UID = undefined;
    this.OFFICE = undefined;
    this.DEPARTMENT = undefined;
    this.DESCRIPTION = undefined;
    this.RULE_TYPE_ID = undefined;
    this.RULE_NAME = undefined;
    this.RULE_SUB_TYPE = '0';
    this.ruleSubTypeValue = 'NA';
    this.PROFILE_CHANGE_STATUS = 'PN';
    this.REC_STATUS = 'NW';
    this.subType = { profileId: '' };
    this.ALL_LAYER = undefined;
  }

  resetListSection() {
    this.virtualList = [];
    this.undoStack = [];
    this.redoStack = [];
    this.undoRedoInProgress = false;
    this.enableRemoveOptions = false;
    this.enableRemoveAllOptions = false;
    this.enableMoveUp = false;
    this.enableMoveDown = false;
    this.enableMoveToTop = false;
    this.enableMoveToBottom = false;
  }

  enableAddButton() {
    this.isListReadOnly = !(this.showSaveButton && (this.selectedRuleTypeId !== undefined && this.selectedRuleTypeId !== '') &&
      (this.selectedObjectType !== undefined && this.selectedObjectType !== '') &&
      (this.selectedAssociatedTo !== undefined && this.selectedAssociatedTo !== ''));
  }

  checkBeforeClose(nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
    const oldRules: any[] = JSON.parse(this.getOriginalRules());
    const newRules: any[] = this.updatedList;
    return this.rule.dataChanged || this.isListChanged(oldRules, newRules) ? this.doOpenConfirmModal(nextState) : this.doClose(nextState);
  }

  doOpenConfirmModal(nextState?: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.dialogService.open(ConfirmModalComponent, {
      width: ModalSize.MEDIUM,
      data: {
        title: 'confirm.leavePage.title',
        message: this.translate.instant('confirm.leavePage.message'),
        okButton: 'rules.button.exit',
        cancelButton: 'confirm.leavePage.cancelButton'
      }
    }).afterClosed().pipe(map((choice) => {
      return choice === 'ok' ? this.doClose(nextState) : this.doStay();
    }));
  }

  doClose(nextState?: RouterStateSnapshot): boolean {
    this.isNeedCheckBeforeClose = false;
    this.rule = {};
    this.originalRule = {};
    this.closeRuleAssociation(nextState);
    return true;
  }

  isListChanged(oldRules: any[], newRules: any[]): boolean {
    let changed = false;
    if (oldRules?.length !== newRules?.length) {
      changed = true;
    } else {
      oldRules?.forEach((rule, index) => {
        if (changed) {
          return;
        }
        if (newRules[index]['RULE_UID'] !== rule['RULE_UID']) {
          changed = true;
          return;
        }
      })
    }
    return changed;
  }

  doStay(): boolean {
    this.isNeedCheckBeforeClose = true;
    return false;
  }

  isChanged() {
    let currentRecord = { ...this.rule };
    let originalRecord = { ...this.originalRule }
    let modifiedCurrentRuleObj = this._modifyObject(currentRecord);
    let modifiedOriginalRuleObj = this._modifyObject(originalRecord);
    return !(modifiedOriginalRuleObj === modifiedCurrentRuleObj) || this.rule.dataChanged;
  }

  _modifyObject(obj) {
    delete obj.$promise;
    delete obj.CHANGED_FIELDS;
    delete obj.objectTypeProfileId;
    delete obj.selectedObjectType;
    delete obj.selectedObjectTypeValue;
    delete obj.EFFECTIVE_DATE;
    delete obj.RULES;
    return obj;
  }

  sanitize(ruleAssociation) {
    const currentRecord = { ...ruleAssociation };
    currentRecord.SELECTED_VALUES = '';
    currentRecord['OBJECT_PRULE_TYPES.SELECTED_VALUES'] = '';
    if (this.virtualList) {
      this.virtualList.forEach((row, key) => {
        if (row.CUSTOMER_LEVEL_ACTION != null) {
          currentRecord['OBJECT_PRULE_TYPES.SELECTED_VALUES'] += row.RULE_UID + '~' + row.CUSTOMER_LEVEL_ACTION_UID + '~' + row.CUSTOMER_LEVEL_ACTION + ',';
          currentRecord.SELECTED_VALUES += row.RULE_UID + '~' + row.CUSTOMER_LEVEL_ACTION_UID + '~' + row.CUSTOMER_LEVEL_ACTION + (key != this.virtualList.length - 1 ? ',' : '');
        } else {
          currentRecord['OBJECT_PRULE_TYPES.SELECTED_VALUES'] += row.RULE_UID + '~' + '' + '~' + '' + ',';
          currentRecord.SELECTED_VALUES += row.RULE_UID + '~' + '' + '~' + '' + (key != this.virtualList.length - 1 ? ',' : '');
        }
      });
    }

    this.selectedRulesString = currentRecord.SELECTED_VALUES;
    delete currentRecord.subType;
    delete currentRecord.IS_WRITABLE;
    delete currentRecord.IS_EDITABLE;
    delete currentRecord.dataChanged;
    delete currentRecord.businessDate;
    delete currentRecord.ruleSubTypeValue;
    return currentRecord;
  }

/*  togglePreviewRulePane(rule) {
    // this.ruleTextHolderElement = $('.preview-rule-content');
    if (!this.show.previewRule) {
      this.updatePreview(rule);
      this.show.auditTrail = false;
      this.show.previewRule = true;
    } else {
      this.show.previewRule = false;
    }
  }*/

/*  updatePreview(rule) {
    // this.ruleTextHolderElement = $('.preview-rule-content');
    const ruleText = this.formatRule(rule.CONDITION_AS_TEXT);
    this.ruleTextHolderElement.empty().append(ruleText);
  }*/

/*  formatRule(text) {
    const LF = '\n';
    return text.replace(new RegExp('\\[', 'g'), '').replace(new RegExp('\\]', 'g'), '').replace(new RegExp(LF, 'g'), '<br>');
  }*/

  /*  toggleAuditTrail() {
      this.show.previewRule = false;
      this.show.auditTrail = !this.show.auditTrail;
    }*/

  deleteRuleAssociation(rule, note) {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      effectiveDate: rule.EFFECTIVE_DATE,
      note: note
    };

    this.rulesAssociationApiService.deleteRuleAssociation(payload).subscribe({
      next: (res) => {
        this.onActionSuccess(res);
      },
      error: (error) => {
        this.onActionError(error, null);
      }
    });
    this.isNeedCheckBeforeClose = false;
  }

  holdRuleAssociation(rule, note) {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      effectiveDate: rule.EFFECTIVE_DATE,
      note: note
    };

    this.rulesAssociationApiService.holdRuleAssociation(payload).subscribe({
      next: (res) => {
        this.onActionSuccess(res);
      },
      error: (error) => {
        this.onActionError(error, null);
      }
    });
    this.isNeedCheckBeforeClose = false;
  }

  activateRuleAssociation(rule, note) {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      effectiveDate: rule.EFFECTIVE_DATE,
      note: note
    };

    this.rulesAssociationApiService.activateRuleAssociation(payload).subscribe({
      next: (res) => {
        this.onActionSuccess(res);
      },
      error: (error) => {
        this.onActionError(error, null);
      }
    });
    this.isNeedCheckBeforeClose = false;
  }

  saveRuleAssociation(rule) {
    this.rulesAssociationApiService.saveRuleAssociation({ ruleId: this.rule.UID }, rule).subscribe({
      next: (res) => {
        this.onActionSuccess(res);
      },
      error: (error) => {
        this.onActionError(error, null);
      }
    });
  }

  createRuleAssociation(rule) {
    this.rulesAssociationApiService.createRuleAssociation(rule).subscribe({
      next: (res) => {
        this.onActionSuccess(res);
      },
      error: (error) => {
        this.onActionError(error, null);
      }
    });
  }

  approveRuleAssociation(rule) {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      profileUpdateTimeStamp: rule.PU_TIME_STAMP
    };

    this.rulesAssociationApiService.approve(payload).subscribe({
      next: (res) => {
        this.onActionSuccess(res);
      },
      error: (error) => {
        this.onActionError(error, null);
      }
    });
    this.isNeedCheckBeforeClose = false;
  }

  declineRuleAssociation(rule, note) {
    const payload = {
      uniqueRecID: rule.UID,
      profileTimeStamp: rule.TIME_STAMP,
      profileUpdateTimeStamp: rule.PU_TIME_STAMP,
      note: note
    };

    this.rulesAssociationApiService.decline(payload).subscribe({
      next: (res) => {
        this.onActionSuccess(res);
      },
      error: (error) => {
        this.onActionError(error, null);
      }
    });
    this.isNeedCheckBeforeClose = true;
  }

  public closeRuleAssociation(nextState?: RouterStateSnapshot): void {
    if (this.UID) {
      this.rulesAssociationApiService.close({ uniqueRecID: this.UID }).subscribe((res) => {
        this.doAfterClose(nextState);
      });
    }
    this.doAfterClose(nextState);
  }

  doAfterClose(nextState?: RouterStateSnapshot) {
    this.resetListSection();
    this.saveDisable = false;
    this.moreDisable = false;
    this.disableAssociationDetails = false;
    if (nextState != null) {
      this.router.navigateByUrl(nextState.url);
    } else {
      this.router.navigate([this.navigationItem.routerLink]);
    }
  }

  onActionSuccess(msg) {
    if (msg.errorMessage !== '' && msg.errorMessage != null) {
      this.notificationService.warning(msg.errorMessage);
    }
    this.closeRuleAssociation();
  }

  onActionError(err, rule) {
    this.isNeedCheckBeforeClose = true;
    const msg = (err.error && err.error.errorMessage) ? err.error.errorMessage : this.translate.instant('rules.general_error');
    this.notificationService.error(msg);
  }

  getMaxSeqNumber() {
    let max = 0;
    this.virtualList.forEach((rule) => {
      max = Math.max(max, rule.RULE_SEQ_NUMBER);
    });
    return max;
  }

  add(selectedRows) {
    const lengthBeforeAdding = this.virtualList ? this.virtualList.length : 0;
    this.filterString = '';
    const addedRows = [];
    if (selectedRows.length > 0) {
      this.unselectAll();
      selectedRows.forEach((row, index) => {
        const rule = this.createRuleObject(row);
        if (!this.virtualList) {
          this.virtualList = [];
        }
        rule.CHECKED = index === selectedRows.length - 1;
        this.virtualList.push(rule);
        addedRows.push(rule);
      });

      this.sort();
      this.controlListItemMovementOptions();
    }

    // $state.go('home.rules.views.tree.rulesAssociation.single.list');
    this.topIndex = lengthBeforeAdding;
    this.rule.dataChanged = true;
    this.refreshList();
    this.setTopIndex(addedRows[0].RULE_SEQ_NUMBER);
    return addedRows;
  }

  createRuleObject(row) {
    const rule: any = {};
    const length = this.virtualList ? this.virtualList.length : 0;
    const newSeqNumber = length == 0 ? 0 : this.getMaxSeqNumber() + 1;
    rule.RULE_UID = row['VIRTUAL.UID'];
    rule.RULE_DESCRIPTION = row['PRULES-DESCRIPTION'];
    rule.RULE_NAME = row['PRULES-RULE_NAME'];
    rule.CONDITION_AS_TEXT = row['PRULES-CONDITIONS_AS_TEXT'];

    //180364
    //rule.APPLICABLE_FOR = 'Applicable for: ' + row['PRULES-LAYER_CLASSIFICATION'];
    rule.APPLICABLE_FOR = row['LAYER_CLASSIFICATION_RELATIONS-LAYER_CLASSIFICATION_ALIAS'];
    rule.APPLICABLE_FOR = row['LAYER_CLASSIFICATION_RELATIONS.ALIAS'];
    rule.SEC_RULE_ACTION = row['PRULES.SEC_RULE_ACTION'];
    rule.RULE_ACTION = row['PRULES.RULE_ACTION'];
    rule.ACTION_LABEL = row['PRULE_TYPES.ACTION_LABEL_INPUT'];
    if (row.CUSTOMER_LEVEL_ACTION != null) {
      rule.CUSTOMER_LEVEL_ACTION = row.CUSTOMER_LEVEL_ACTION;
      rule.CUSTOMER_LEVEL_ACTION_UID = row.CUSTOMER_LEVEL_ACTION_UID;
      rule.ACTION = rule.ACTION_LABEL + ': Customer Level Action | ' + rule.CUSTOMER_LEVEL_ACTION;
    } else if (rule.ACTION_LABEL != '' && rule.SEC_RULE_ACTION != '') {
      rule.ACTION = rule.ACTION_LABEL + ': ' + rule.RULE_ACTION + ' | ' + rule.SEC_RULE_ACTION;
    } else if (rule.ACTION_LABEL != '' && !(rule.SEC_RULE_ACTION != '')) {
      rule.ACTION = rule.ACTION_LABEL + ': ' + rule.RULE_ACTION;
    } else if (!(rule.ACTION_LABEL != '') && rule.SEC_RULE_ACTION != '') {
      rule.ACTION = 'Action: ' + rule.RULE_ACTION + ' | ' + rule.SEC_RULE_ACTION;
    } else if (!(rule.ACTION_LABEL != '') && !(rule.SEC_RULE_ACTION != '')) {
      rule.ACTION = 'Action: ' + rule.RULE_ACTION;
    }

    rule.RULE_SEQ_NUMBER = newSeqNumber;
    rule.CHECKED = true;
    rule.PREVIEW_MODE = false;
    rule.position = 'none';
    return rule;
  }

  insert(rows) {
    let topRowIndex = undefined;
    for (let j = 0; j < rows.length; j++) {
      const row = rows[j];
      if (!topRowIndex) {
        topRowIndex = row.RULE_SEQ_NUMBER;
      }
      this._insertAndReorder(row);
    }
    this.refreshList();
    this.setTopIndex(topRowIndex);
  }

  _insertAndReorder(row) {
    const seqNumber = row.RULE_SEQ_NUMBER;
    this.virtualList.splice(seqNumber, 0, row);

    for (let i = 0; i < this.virtualList.length; i++) {
      this.virtualList[i].RULE_SEQ_NUMBER = i;
    }
  }

  remove(ruleSequenceNumber) {
    let length = this.virtualList.length;
    let indexToBeRemoved = -1;
    let removedRow = undefined;

    for (let i = 0; i < length; i++) {
      if (this.virtualList[i].RULE_SEQ_NUMBER == ruleSequenceNumber) {
        indexToBeRemoved = i;
        removedRow = this.virtualList.splice(indexToBeRemoved, 1);
        break;
      }
    }

    length = this.virtualList.length;
    for (let i = 0; i < length; i++) {
      this.virtualList[i].RULE_SEQ_NUMBER = i;
    }
    this.rule.dataChanged = true;
    this.refreshList();

    return removedRow;
  }

  _delinkReferences() {
    const tempList = [...this.virtualList];
    this.virtualList = [];
    this.virtualList = tempList;
  }

  removeSelected() {
    const removedRows = [];
    for (let i = this.virtualList.length - 1; i >= 0; i--) {
      if (this.virtualList[i].CHECKED) {
        const ruleSequenceNumber = this.virtualList[i].RULE_SEQ_NUMBER;
        const row = this.remove(ruleSequenceNumber);
        Array.prototype.push.apply(removedRows, row);
      }
    }
    this.rule.dataChanged = true;
    return removedRows;
  }

  removeAll() {
    if (this.virtualList?.length > 0) {
      const removedRows = this.virtualList.splice(0, this.virtualList.length);
      this.rule.dataChanged = true;
      this.refreshList();
      return removedRows;
    }
  }

  sort() {
    this.virtualList.sort((a, b) => {
      if (a.RULE_SEQ_NUMBER < b.RULE_SEQ_NUMBER) {
        return -1;
      } else if (a.RULE_SEQ_NUMBER > b.RULE_SEQ_NUMBER) {
        return 1;
      }
      return 0;
    });
  }

  swap(sourceIndex, destIndex) {
    let SOURCE_SEQ_NUMBER = undefined;
    let DEST_SEQ_NUMBER = undefined;
    const length = this.virtualList.length - 1;

    if (destIndex === 0) {
      let sourceRow = this.virtualList.splice(sourceIndex, 1);
      this.virtualList.splice(0, 0, sourceRow[0]);
      for (let i = 0; i <= length; i++) {
        this.virtualList[i].RULE_SEQ_NUMBER = i;
      }
    } else if (destIndex === length) {
      let sourceRow = this.virtualList.splice(sourceIndex, 1);
      this.virtualList.splice(length, 0, sourceRow[0]);
      for (let i = 0; i <= length; i++) {
        this.virtualList[i].RULE_SEQ_NUMBER = i;
      }
    } else {
      SOURCE_SEQ_NUMBER = this.virtualList[sourceIndex].RULE_SEQ_NUMBER;
      DEST_SEQ_NUMBER = this.virtualList[destIndex].RULE_SEQ_NUMBER;
      this.virtualList[sourceIndex].RULE_SEQ_NUMBER = DEST_SEQ_NUMBER;
      this.virtualList[destIndex].RULE_SEQ_NUMBER = SOURCE_SEQ_NUMBER;
    }
    this.rule.dataChanged = true;
    this.refreshList();
    this.setTopIndex(destIndex);
  }

  getSelectionDetails() {
    let index = 0;
    const selectionDetails = {
      index: undefined,
      count: 0,
      total: 0
    };
    this.virtualList?.forEach((item) => {
      if (item.CHECKED) {
        selectionDetails.index = index;
        selectionDetails.count++;
      }
      selectionDetails.total++;
      index++;
    });

    return selectionDetails;
  }

  controlListItemMovementOptions() {
    const selectionDetails = this.getSelectionDetails();

    const selectedCount = selectionDetails.count;
    const index = selectionDetails.index;
    const total = selectionDetails.total;

    this.enablePreview = selectedCount == 1;

    if ((selectedCount == 1 && total > 1) && this.filterString == '') {
      this.listOptionsEnabled(true);

      if (index === 0) {
        this.enableMoveToTop = false;
        this.enableMoveUp = false;
      }

      if (index === this.virtualList.length - 1) {
        this.enableMoveToBottom = false;
        this.enableMoveDown = false;
      }
    } else {
      this.listOptionsEnabled(false);
    }

    if (this.showSaveButton) {
      if (total == 0) {
        this.enableRemoveOptions = false;
        this.enableRemoveAllOptions = false;
      } else {
        this.enableRemoveOptions = selectedCount > 0;
        this.enableRemoveAllOptions = true;
      }
    } else {
      this.enableRemoveOptions = false;
      this.enableRemoveAllOptions = false;
    }

    if (this.filterString.length > 0) {
      this.listOptionsEnabled(false);

    }
  }

  refreshList() {
    this._delinkReferences();
    this.sort();
    this.controlListItemMovementOptions();
  }

  unselectAll() {
    this.virtualList?.forEach((row) => {
      row.CHECKED = false;
      row.PREVIEW_MODE = false;
    });
  }

/*  closeAllPreviewPanes() {
    /!*    this.virtualList?.forEach((row) => {
          row.PREVIEW_MODE = false;
        });*!/
  }*/

  setTopIndex(index) {
    const totalRows = this.virtualList.length;
    const rowHeight = 96;
    const listHeight = 96; //$('.rules-association-list-wrapper')[0].offsetHeight;
    const rowsPerPage = Math.trunc(listHeight / rowHeight);
    const pageCountTemp = Math.trunc(totalRows / rowsPerPage);
    const pageCount = (totalRows / rowsPerPage) > pageCountTemp ? pageCountTemp + 1 : pageCountTemp;
    const pageNumber = Math.trunc(index / rowsPerPage) + 1;
    let firstRowOnPage = ((pageNumber - 1) * rowsPerPage) + 1;
    if (pageNumber == pageCount) {
      firstRowOnPage = totalRows;
      /*      setTimeout(function() {
              $('.md-virtual-repeat-scroller').scrollTop(10000);
            }, 300);*/
    }
    this.topIndex = firstRowOnPage - 1;
  }

  /*  Math.trunc = Math.trunc || function(x) {
      var n = x - x % 1;
      return n === 0 && (x < 0 || (x === 0 && (1 / x !== 1 / 0))) ? -0 : n;
    };*/

  addToUndoStack(action, undoValue, redoValue) {
    this.undoRedoInProgress = false;
    let data = undefined;

    switch (action) {
      case 'ADD':
        data = this.createUndoRedoObject('REMOVE', [...undoValue], 'ADD', [...redoValue]);
        break;

      case 'REMOVE':
        data = this.createUndoRedoObject('INSERT', [...undoValue], 'REMOVE', [...redoValue]);
        break;

      case 'SWAP':
        data = this.createUndoRedoObject('SWAP', [...undoValue], 'SWAP', [...redoValue]);
        break;
    }
    this.undoStack.push(data);
    this.clearRedo();
  }

  createUndoRedoObject(undoAction, undoValue, redoAction, redoValue) {
    const data: any = {};
    data.undoData = {};
    data.redoData = {};
    data.undoData.action = undoAction;
    data.undoData.value = undoValue;
    data.redoData.action = redoAction;
    data.redoData.value = redoValue;
    return data;
  }

  doUndo() {
    if (this.undoStack.length == 0 || this.undoRedoInProgress) {
      return;
    }
    this.undoRedoInProgress = true;

    try {
      const undoRedoItem = this.undoStack.pop();
      const undo = undoRedoItem.undoData;
      const action = undo.action;

      switch (action) {
        case 'REMOVE':
          for (let i = 0; i < undo.value.length; i++) {
            this.remove(undo.value[i].RULE_SEQ_NUMBER);
          }
          break;

        case 'INSERT':
          this.insert(undo.value);
          break;

        case 'SWAP':
          this.swap(undo.value[0], undo.value[1]);
          break;

        default:
          break;
      }

      this.unCheckAll();
      this.displayChanges(undo);
      this.sort();
      this.redoStack.push(undoRedoItem);
      this.controlListItemMovementOptions();
      this.show.previewRule = false;
      //printStacks();
      this.undoRedoInProgress = false;
    } catch (e) {
      this.undoRedoInProgress = false;
    }
  }

  doRedo() {
    if (this.redoStack.length == 0 || this.undoRedoInProgress) {
      return;
    }
    this.undoRedoInProgress = true;
    const undoRedoItem = this.redoStack.pop();
    const redo = undoRedoItem.redoData;
    const action = redo.action;

    switch (action) {
      case 'ADD':
        this.add(redo.value);
        break;

      case 'REMOVE':
        redo.value.forEach((item) => {
          this.remove(item);
        });
        break;

      case 'SWAP':
        this.swap(redo.value[0], redo.value[1]);
        break;

      default:
        break;
    }
    this.unCheckAll();
    this.displayChanges(redo);
    this.sort();
    this.undoStack.push(undoRedoItem);
    this.controlListItemMovementOptions();
    this.show.previewRule = false;
    //printStacks();
    this.undoRedoInProgress = false;
  }

  clearRedo() {
    this.redoStack.length = 0;
  }

  public setSelectedRule() {
    const selectionDetails = this.getSelectionDetails();
    const selectedCount = selectionDetails.count;
    const index = selectionDetails.index;
    if (selectedCount == 1) {
      this.selectedRule = this.virtualList[index];
    } else {
      this.selectedRule = undefined;
    }
  }

  processComplexData(auditRow, data, oldNewFlag) {
    const rules = data.split('~~');
    const processedRow = '';
    if (rules) {
      rules.forEach((rule) => {
        const ruleData = rule.split('~');
        let auditObject;

        auditObject = { 'ruleName': ruleData[4], 'ruleDescription': ruleData[5] };
        if (ruleData[8] !== 'null') {
          auditObject['customerLevelAction'] = ruleData[8];
        }

        if (ruleData && oldNewFlag === 'new') {
          auditRow.newRules.push(auditObject);
        } else {
          auditRow.oldRules.push(auditObject);
        }
      });
    }
    return processedRow;
  }

  unCheckAll() {
    const length = this.virtualList.length;
    for (let i = 0; i < length; i++) {
      const rule = this.virtualList[i];
      if (rule.hasOwnProperty('CHECKED')) {
        rule.CHECKED = false;
      }
    }
  }

  displayChanges(undoRedoItem) {
    const length = this.virtualList.length;
    const ruleCount = undoRedoItem.value.length;
    let i, rule;

    if (undoRedoItem.action == 'ADD') {
      for (i = length - 1; i >= (length - ruleCount); i--) {
        rule = this.virtualList[i];
        this.setCheck(rule, true);
        this.setTopIndex(i);
      }
    } else if (undoRedoItem.action == 'INSERT') {
      for (i = 0; i < ruleCount; i++) {
        const index = undoRedoItem.value[i].RULE_SEQ_NUMBER;
        rule = this.virtualList[index];
        this.setCheck(rule, true);
        this.setTopIndex(index);
      }
    } else if (undoRedoItem.action == 'SWAP') {
      rule = this.virtualList[undoRedoItem.value[1]];
      this.setCheck(rule, true);
    }
  }

  setCheck(rule, value) {
    if (rule.hasOwnProperty('CHECKED')) {
      rule.CHECKED = value;
    }
  }

  closePreviewAndResetFilter() {
    this.filterString = '';
  }

  setRuleAssociationStatuses(ruleAssociationStatuses: RuleStatuses) {
    this.ruleAssociationStatuses = ruleAssociationStatuses
  }

  setOriginalRules(rules: any) {
    this.originalRuleAssociation = JSON.stringify(rules);
  }

  getOriginalRules(): string {
    return this.originalRuleAssociation;
  }

  private listOptionsEnabled(value: boolean) {
    this.enableMoveUp = value;
    this.enableMoveDown = value;
    this.enableMoveToTop = value;
    this.enableMoveToBottom = value;
  }
}
