import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../../core/navigation/services/navigation.service';
import { Sitemap } from '../../core/navigation/sitemap/models';

@Injectable({ providedIn: 'root' })
export class SitemapResolver implements Resolve<Sitemap> {
  constructor(private navigationService: NavigationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Sitemap>|Promise<Sitemap>|Sitemap {
    return this.navigationService.getSitemap()
  }
}
