import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActivityTrackerMapperService } from './activity-tracker-mapper.service';
import {IActivityTrackerService} from "./interfaces/activity-tracker-service.interface";
import {ActivityTrackerApiService} from "../../../common/services/activity-tracker-api.service";
import {ActivityLog} from "../../../common/models/activity-log.model";
import {ActivityLogResponse} from "../../../common/models/activity-log-response.model";


@Injectable({
  providedIn: 'root'
})
export class ActivityTrackerService implements IActivityTrackerService {

  constructor(protected activityTrackerApiService: ActivityTrackerApiService,
              protected activityTrackerMapperService: ActivityTrackerMapperService) { }


  getActivityLog(mid: string): Observable<ActivityLog> {
    return this.activityTrackerApiService.getActivityLog(mid)
      .pipe(
        map((activityLogResponse: ActivityLogResponse) => {
          return {
            count: {
              rulesCount: activityLogResponse.count.rules,
              errorsCount: activityLogResponse.count.errors,
              auditCount: activityLogResponse.count.audit
            },
            items: this.activityTrackerMapperService.mapActivityLogItemResponseList(activityLogResponse.items)
          };
        } )
      );

  }

}
