import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CreateSession } from '../../core/store/session/action/session.action';
import { SessionState } from '../../core/store/session/state/session.state';
import { Token } from '../models/token.model';
import { UserData } from '../models/user-data.model';
import { AuthenticationConfigService } from './authentication-config.service';
import { IAuthenticationService } from './interfaces/authentication-service.interface';

@Injectable()
export class AuthenticationService implements IAuthenticationService {

  get token(): Token {
    return this.store.selectSnapshot<Token>(SessionState.token);
  }

  get accessToken(): string {
    return this.store.selectSnapshot(SessionState.accessToken);
  }

  get refreshToken(): string {
    return this.store.selectSnapshot(SessionState.refreshToken);
  }

  get userData(): UserData {
    return this.store.selectSnapshot(SessionState.userData);
  }

  get isUserDataSet(): boolean {
    return this.store.selectSnapshot(SessionState.isUserDataSet);
  }

  get sessionId(): string {
    return this.store.selectSnapshot(SessionState.sessionId);
  }

  constructor(private store: Store,
              private authenticationConfigService: AuthenticationConfigService) {
    this.handleSSO();
  }

  private handleSSO(): void {
    const ssoElement = document.getElementById('sso_credentials_object');
    if (ssoElement == null) {
      return;
    }
    const userData = JSON.parse(ssoElement.innerText || '');
    if (userData) {
      this.authenticationConfigService.setSSOMode();
      this.store.dispatch(new CreateSession({ userData }));
    }
  }
}
