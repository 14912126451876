import { ComponentRef, Directive, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AbstractControlComponent,
  BFEvents, ButtonEventBuilder, CanvasEventBuilder,
  ComponentManagerService, DataService, DialogEventBuilder,
  DrilldownComponent,
  DrilldownControlData, DrilldownEventBuilder,
  DrilldownResponseInterface, FormEventBuilder, FormFieldControlData, FormManager,
  FormService, LayoutEventBuilder,
  NotificationService, NotificationType, SchemaValidator,
  TabChangeEvent, TabEventBuilder,
} from '@fgpp-ui/components';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, forkJoin, from, Observable, of, Subject, Subscription, take} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import { IValueChange } from '../../interfaces/form-value-change.interface';
import { IDynamicModalDialogData } from '../../interfaces/dynamic-modal-dialog-data.interface';
import { FnUiDialogService } from '../../../../shared/fn-ui-dialog/services/fn-ui-dialog.service';
import { flatten, unflatten } from 'flat';
import { UserSettingsService } from '../../../../core/user-settings/services/user-settings.service';
import { UserPreferences } from '../../../../core/user-settings/models/user-preferences.model';
import { EMetadataCategory } from '../../enums/metadata-category.enum';
import { IEndpoint } from '../../interfaces/endpoint.interface';
import { MetadataService } from '../../services/metadata.service';
import { NavigationService } from '../../../../core/navigation/services/navigation.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { v4 as uuid } from 'uuid';
import { ErrorResponse } from '../../interfaces/error-response.interface';
import {AuthenticationService} from '../../../../authentication/services/authentication.service';
import {HTTPMethods} from '../../enums/http-methods.enum';
import {IData} from '../../interfaces/data.interface';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import DialogEvent = BFEvents.DialogEvent;
import FormEvent = BFEvents.FormEvent;

export const NOTIFICATION_TIMEOUT = 10000;

@Directive()
export abstract class AbstractFormComponent implements OnInit, OnDestroy {
  public userPreferences: UserPreferences;
  protected userSettingsService: UserSettingsService;
  protected formService: FormService;
  protected route: ActivatedRoute;
  protected router: Router;
  protected translate: TranslateService;
  protected authenticationService: AuthenticationService;
  protected componentManager: ComponentManagerService;
  protected valueChangesSub: Subscription;
  protected drilldownSub: Subscription;
  protected snackBar: NotificationService;
  protected dialogService: FnUiDialogService;
  private _domain: string;
  public form: FormGroup = new FormGroup({});
  public isFormReadyForRendering = false;
  public isLoading = false;
  protected stateParams;
  protected tenantId = 'gpp';
  private contract;
  private layout;
  private data;
  private originalData;
  public formMetadata;
  protected subscription = new Subscription();
  protected valueChangesListenerList: string[] = null;
  protected exitedState = false;
  private _office: string;
  protected drillDownDataMap: Map<string, BFEvents.DrillDownEvent> = new Map<string, BFEvents.DrillDownEvent>();
  private eTagValue;
  private prefetchDrilldownList = [];
  protected invalidComponent: AbstractControlComponent<FormFieldControlData>;
  protected metadataService: MetadataService;
  protected navigationService: NavigationService;
  protected notificationService: NotificationService;
  protected endpoints: { [action: string]: IEndpoint };
  protected navigationMapping: string;
  protected navigationCategory: EMetadataCategory;
  protected navigationTypeId: string;
  protected dataService: DataService;
  protected canvasEvent: CanvasEventBuilder;
  protected layoutEvent: LayoutEventBuilder;
  protected dialogEvent: DialogEventBuilder;
  protected formEvent: FormEventBuilder;
  protected tabEvent: TabEventBuilder;
  protected buttonEvent: ButtonEventBuilder;
  protected drilldownEvent: DrilldownEventBuilder;
  protected destroy$ = new Subject<void>();
  protected formMgr: FormManager;
  protected nextState: RouterStateSnapshot;
  protected readonly dialogOkBtn = 'ok';
  get domain() {
    return this._domain;
  }

  set domain(domain: string) {
    this._domain = domain;
  }

  get office() {
    return this._office;
  }

  set office(office: string) {
    this._office = office;
  }

  constructor(protected injector: Injector) {
    this.formService = injector.get(FormService);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.translate = injector.get(TranslateService);
    this.componentManager = injector.get(ComponentManagerService);
    this.snackBar = injector.get(NotificationService);
    this.dialogService = injector.get(FnUiDialogService);
    this.userSettingsService = injector.get(UserSettingsService);
    this.stateParams = this.route.snapshot.queryParams;
    this.userPreferences = this.userSettingsService.getPreferences();
    this.metadataService = this.injector.get(MetadataService);
    this.navigationService = this.injector.get(NavigationService);
    this.notificationService = this.injector.get(NotificationService);
    this.dataService = this.injector.get(DataService);
    this.authenticationService = this.injector.get(AuthenticationService);
    this.canvasEvent = this.formService.getEventBus().getCanvasEventBuilder();
    this.layoutEvent = this.formService.getEventBus().getLayoutEventBuilder();
    this.formEvent = this.formService.getEventBus().getFormEventBuilder();
    this.tabEvent = this.formService.getEventBus().getTabEventBuilder();
    this.buttonEvent = this.formService.getEventBus().getButtonEventBuilder();
    this.drilldownEvent = this.formService.getEventBus().getDrilldownEventBuilder();
    this.dialogEvent = this.formService.getEventBus().getDialogEventBuilder();
  }

  protected abstract beforeOnInit();
  protected abstract createForm(): void;
  protected abstract buttonClickHandler(actionPayload: BFEvents.ButtonEvent): void;
  protected abstract tabChangeHandler(payload: TabChangeEvent): void;
  protected abstract valueChangesHandler(change: IValueChange);
  protected abstract beforeFormLoadStart(): void;
  protected abstract beforeFormReady(): void;
  protected abstract afterFormReady(): void;

  ngOnInit(): void {
    this.showSpinner();
    this.beforeOnInit();
    this.subscribeBusEvents();
    this.executeHook('fgppOnInit').pipe(take(1)).subscribe(() => {
      this.loadMetadata(this._domain, this.navigationCategory, this.navigationMapping, this.navigationTypeId);
    });
  }

  private subscribeBusEvents() {
    this.buttonEvent.listen().pipe(takeUntil(this.destroy$)).subscribe((event: BFEvents.ButtonEvent) => {
      this.buttonClickHandler(event);
    });

    this.canvasEvent.listen()
      .pipe(filter((e) => e.value.state === 'renderingComplete'), takeUntil(this.destroy$))
      .subscribe( () => {
        this.formRenderingComplete();
      });


  }

  protected getVersion(): string {
    return 'v1';
  }

  protected loadMetadata(domain: string, category: EMetadataCategory, mapping: string, typeId: string): void {
    this.metadataService.
    getMetadata(domain || 'bff', category, mapping, typeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: { [action: string]: IEndpoint }) => {
          this.endpoints = response;
          this.createForm();
        },
        error: error => {
          this.notificationService.error(this.translate.instant('business-framework.profiles.errors.unableToLoadMetadata'));
          console.error(error);
          this.returnToPreviousRoute();
        }
      });
  }

  protected returnToPreviousRoute() {
    let popoutWindow = this.stateParams.isStandAlone === 'true';
    if(popoutWindow){
      window.close();
      return;
    }
    this.exitedState = true;
    if(this.nextState) {
      this.router.navigateByUrl(this.nextState.url);
      return;
    }
    setTimeout(() => {
      const currentUrl = this.router.url;
      const queryParamsIndex = currentUrl.indexOf('?');
      this.router.navigate([queryParamsIndex > -1 ? currentUrl.substring(0, queryParamsIndex) : currentUrl], {
        state: {
          fromForm: true
        }
      })
    }, 0);
  }

  protected navigateToNextState(nextState: RouterStateSnapshot ) {
    this.router.navigateByUrl(nextState.url);
  }

  protected getCachedObservableFromEndpoint(endpoint: IEndpoint, params?: any): Observable<any> {
    return this.dataService.getFromCache(endpoint.endpoint, params);
  }

  protected getObservableFromEndpoint(endpointInfo: IEndpoint, params?: any): Observable<Object> {
    const headers = new HttpHeaders().set('X-Request-ID', uuid());
    return this.dataService.get(endpointInfo.endpoint, new HttpParams({ fromObject: params || {} }), headers);
  }

  ngOnDestroy(): void {
    this.executeHook('fgppOnDestroy').pipe(take(1)).subscribe((result) => {});

    if (this.valueChangesSub) {
      this.valueChangesSub.unsubscribe();
    }

    if (this.drilldownSub) {
      this.drilldownSub.unsubscribe();
    }

    this.subscription.unsubscribe();

    this.formService.close();

    if(this.endpoints){
      Object.keys(this.endpoints).forEach(key => {
        this.endpoints[key].idempotencyKey = null;
      });
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  protected getETagValue() {
    return this.eTagValue;
  }

  protected setETagValue(value) {
    if (value) {
      value = value.replace(/["]/g, '').replace('W/', '');
      this.eTagValue = value;
    }
  }

  protected getPrefetchDrilldownList(): string[] {
    return this.prefetchDrilldownList;
  }

  protected setPrefetchDrilldownList(...arr): void {
    this.prefetchDrilldownList = arr;
  }

  protected loadDefaultValues() {
    this.data = unflatten({ ...flatten(this.formService.defaultValues), ...flatten(this.data) });
  }

  protected doDynamicFormCreate(isSaveAs = false): void {
    this.form = new FormGroup({});
    this.formService.setForm(this.form);
    this.canvasEvent.setTarget(this.formService.getId()).set('state', 'notReady').emit();
    this.formMetadata = this.formService.createForm(this.form, this.getLayout(), this.getContract(), this.getData());
    this.formMgr = this.formService.getFormManager();
    this.setFormRenderingStatus(true);
  }

  private formRenderingComplete() {
    this.subscribeDrilldownChanges();

    this.loadDrillDownData({...this.formService.defaultValues, ...this.data}).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.formLoadStart();
    });
  }

  protected getServerActionRequestBody(endpoint: IEndpoint, buttonId: string) {
    const opts = { excludeReadOnly: true, excludeImmutable: false };
    if (endpoint.method === HTTPMethods.PUT) {
      opts.excludeImmutable = true;
    }
    return this.formMgr.getValue(opts);
  }

  private formLoadStart() {
    this.formMgr.markAsUntouched();
    this.beforeFormLoadStart();
    this.executeHook('fgppFormLoadStart').pipe(take(1)).subscribe(() => {
      this.formLoadComplete();
    });
  }

  protected formLoadComplete() {
    this.executeHook('fgppFormLoadComplete').pipe(take(1)).subscribe((result) => {
      this.beforeValueChanges();
      this.subscribeValueChanges();
      this.beforeFormReady();
      this.canvasEvent.setTarget(this.formService.getId()).set('state', 'ready').emit();
      this.hideSpinner();
      this.afterFormReady();
    });
  }

  protected beforeValueChanges(): void {}

  protected subscribeValueChanges(): void {
    this.valueChangesSub = this.formEvent.listen().pipe(filter((e: FormEvent) => e.name === 'valueChange'),takeUntil(this.destroy$), debounceTime(250)).subscribe((payload: BFEvents.FormEvent) => {
      this.valueChangesHandler({ control: payload.target, value: payload.value });
    });
  }

  protected unsubscribeValueChanges(): void {
    this.valueChangesSub.unsubscribe();
  }

  protected executeHook(hookName: string, ...args: any): Observable<any> {
    if (Object.getPrototypeOf(this).hasOwnProperty(hookName) && typeof this[hookName] === 'function') {
      return this.transformToObservable(this[hookName].apply(this, args));
    } else {
      return of(true);
    }
  }

  protected transformToObservable<T = any>(resultOrDeffered: any): Observable<T> {
    if (resultOrDeffered instanceof Promise) {
      return from(resultOrDeffered);
    } else if (resultOrDeffered && typeof (resultOrDeffered.subscribe) === 'function') {
      return resultOrDeffered;
    } else {
      return of(resultOrDeffered);
    }
  }

  public isNullUndefinedOrEmpty(value: any) {
    return value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0) || (typeof value === 'undefined');
  }

  protected checkRequired(showError = true): boolean {
    let counter = 0;
    const emptyFieldsList = this.formService.getRequired().filter((id) => this.isNullUndefinedOrEmpty(this.formMgr.get(id).getValue()))
      .map((id) => {
        const invalidComponent = this.formMgr.get(id).getLayoutControl();
        if (counter === 0) {
          this.invalidComponent = invalidComponent;
        }
        counter++;
        return invalidComponent ?
          invalidComponent.get('title') : `Couldn't find component ${id}`;
      });

    if (emptyFieldsList.length > 0 && showError) {
      const message = this.translate.instant('errors.mandatoryFieldMissing', { 'missingList': emptyFieldsList.join(', ') });
      this.snackBar.error(message, NOTIFICATION_TIMEOUT);
    }
    return emptyFieldsList.length === 0;
  }

  protected checkInvalid(showError = false): boolean {
    let counter = 0;
    const invalidFieldsList = this.formService.getFormControlsList().filter((id) => {
      return this.formMgr.get(id).isInValid();
    }).map((id) => {
      const invalidComponent = this.formMgr.get(id).getLayoutControl();
      if (counter === 0) {
        this.invalidComponent = invalidComponent;
      }
      counter++;
      return invalidComponent?.get('title');
    });

    if (invalidFieldsList.length > 0 && showError) {
      const message = 'Invalid fields: ' + invalidFieldsList.join(',');
      this.snackBar.error(message, NOTIFICATION_TIMEOUT);
    }
    return invalidFieldsList.length === 0;
  }

  protected checkDirty(): boolean {
    return this.formMgr.isDirty();
  }

  protected checkSchemaErrors(showError = true): boolean {
    const schema = this.clone(this.getContract());
    delete schema.id;
    delete schema.$id;
    const schemaErrors: any[] = new SchemaValidator().validate(schema, this.formMgr.getValue());

    if (schemaErrors) {
      schemaErrors.forEach(err => {
        console.warn(`Schema validation failed for ${err.instancePath} : ${err.message}`, err.params)
      });

      schemaErrors.forEach(err => {
        console.warn(`Schema validation failed for ${err.instancePath} : ${err.message}`, err.params)
      });
      if (showError) {
        this.snackBar.error('errors.schemaValidationError', NOTIFICATION_TIMEOUT);
      }
      return false;
    }
    return true;
  }

  protected setValueChangesListenerList(...columns): void {
    this.valueChangesListenerList = columns;
  }

  protected getContract(): any {
    return this.contract;
  }

  protected setContract(contract): void {
    this.contract = Object.freeze((contract));
  }

  protected getLayout(): any {
    return this.layout;
  }

  protected setLayout(layout): void {
    this.layout = Object.freeze(layout);
  }

  protected getData(): any {
    return this.data;
  }

  protected setData(data: any): void {
    this.data = Object.freeze(data);
  }

  protected getOriginalData(): Partial<IData> {
    return this.originalData;
  }

  protected setOriginalData(originalData: Partial<IData>): void {
    this.originalData = originalData;
  }

  protected setTranslations(locale, localeData): void {
    this.translate.setTranslation(locale, localeData, true);
  }

  protected setFormRenderingStatus(status: boolean): void {
    this.isFormReadyForRendering = status;
  }

  protected showSpinner(): void {
    this.isLoading = true;
  }

  protected hideSpinner(): void {
    this.isLoading = false;
  }

  protected openModal(config: IDynamicModalDialogData, target?: string): Observable<DialogEvent> {
    this.dialogEvent
      .setEvent('dialogOpen')
      .setTarget(target)
      .set('config', config)
      .emit();

    return this.dialogEvent.listen().pipe(filter(e => e.target === target && e.name === 'dialogClose'));
  }

  private subscribeDrilldownChanges() {
    if (!this.drilldownSub) {
      this.drillDownDataMap.clear();
      this.drilldownSub = this.drilldownEvent.listen()
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: BFEvents.DrillDownEvent) => this.drillDownDataMap.set(event.target, event));
    }
  }

  protected getDrilldownData(control: string): any | any[] {
    if (this.drillDownDataMap.has(control)) {
      const drillDownEvent = this.drillDownDataMap.get(control);
      const data = drillDownEvent.value.data;

      if (drillDownEvent.value.inputValue === this.formMgr.get(control).getValue()) {
        if (Array.isArray(data) && data.length === 1) {
          return data[0];
        } else if (Array.isArray(data) && data.length > 1) {
          return data;
        }
      }

      return null;
    }
  }

  protected findInSchema(schema: any, key) {
    let schemaObj = null;
    const tokens = key?.split('.');

    if (Array.isArray(tokens)) {
      tokens.forEach((t, index) => {
        const inner = schema.properties;
        if (inner.hasOwnProperty(t) && index !== tokens.length - 1) {
          schemaObj = this.findInSchema(inner[t], tokens.slice(index + 1).join('.'));
        } else if (inner.hasOwnProperty(t) && index === tokens.length - 1) {
          schemaObj = inner[t];
        }
      });
    }

    return schemaObj;
  }

  protected loadDrillDownData(serverData: any): Observable<any> {
    if (serverData) {
      const flatServerData = flatten(serverData);
      const componentListMap: Map<string, any> = this.componentManager.getStore();
      let componentArr = [];

      componentListMap.forEach((compRef: ComponentRef<AbstractControlComponent<DrilldownControlData>>) => {
        const comp = compRef.instance;
        const interestedControlsList = typeof comp['getInterestedControls'] === 'function' ? comp.getInterestedControls() : [];

        // Process only controls that have dependant fields in filters
        // Set the tokens to generate the URL's
        if (interestedControlsList.length > 0) {
          const tokens = {};
          interestedControlsList.forEach((controlId) => {
            tokens[controlId] = flatServerData[controlId];
          });
          comp.setTokens(tokens);

          if (this.prefetchDrilldownList.indexOf(comp.controlData.formMapping) >= 0 && comp.controlData.controlType === 'drilldown') {
            this.formMgr.get(comp.controlData.formMapping).setValue(flatServerData[comp.controlData.formMapping], { emitEvent: false });
            componentArr.push(comp);
          }
        } else if (comp.controlData.controlType === 'drilldown' && this.prefetchDrilldownList.indexOf(comp.controlData.formMapping) >= 0) {
          this.formMgr.get(comp.controlData.formMapping).setValue(flatServerData[comp.controlData.formMapping], { emitEvent: false });
          componentArr.push(comp);
        }
      });

      let obsArr$: Observable<any>[] = [];
      if (componentArr.length > 0) {
        if (this.prefetchDrilldownList.length > 0) {
          componentArr = componentArr.filter((comp) => this.prefetchDrilldownList.indexOf(comp.controlData.formMapping) >= 0);
        } else {
          return of([]);
        }
        obsArr$ = componentArr.map((comp) => {
          if (flatServerData[comp.controlData.formMapping] && flatServerData[comp.controlData.formMapping] !== '') {
            return comp.resolveData(flatServerData[comp.controlData.formMapping]);
          }
          return of([]);
        });
      } else {
        obsArr$.push(of([]));
      }

      componentListMap.forEach((compRef: ComponentRef<AbstractControlComponent<DrilldownControlData>>) => {
        const comp = compRef.instance;
        if (comp.controlData.filters) {
          delete comp.controlData.filters[comp.controlData.dataKey];
        }
      });

      return forkJoin(obsArr$).pipe(map((drillDownDataArr: DrilldownResponseInterface[]) => {
        drillDownDataArr.forEach(data => {
          if (data.isValueMissing) {
            setTimeout(() => {
              if (this.componentManager.getComponent<DrilldownComponent>(data.id).controlData.checkManualEntry === true) {
                this.componentManager.getComponent<DrilldownComponent>(data.id).setValueNotInList(true);
              }
            });
          }
          this.drillDownDataMap.set(data.id, {
            target: data.id,
            type: BFEvents.Type.DRILLDOWN,
            name: 'selectionChange',
            value: {
              data: data.matchedRows,
              inputValue: flatServerData[data.id]
            }
          });
        });
      }));
    }

    return new Observable<void>(observer => observer.next());
  }

  showServerError(errorResponse: ErrorResponse, type = 'error') {
    const notification = type === 'warn' ? this.notificationService.warning : this.notificationService.error;
    const duration = 3000;
    if (!errorResponse.causes || errorResponse.causes.length === 0) {
      this.notificationService.error(errorResponse.title, duration);
    } else {
      const causes = errorResponse.causes || [];
      let errorMsg = '';
      causes.filter((c, i) => i < 5 ? c : null).forEach((cause, index) => {
        errorMsg += cause.title;
        if (index < causes.length - 1) {
          errorMsg += '\n';
        }
      });

      if (causes.length > 5) {
        errorMsg += this.translate.instant('business-framework.errors.more-error-cause', {count: causes.length - 5});
      }

      this.notificationService.open(
        {
          data:
            {
              type: NotificationType.ERROR, message: errorMsg
            },
          duration: NOTIFICATION_TIMEOUT,
          panelClass: 'pre-wrap-line'
        });
    }
  }

  protected setL10NData(language: string, localeData: any) {
    this.translate.setTranslation(language, localeData, true);
  }

  protected checkDirtyOnSave(): boolean {
    const dirty = this.checkDirty();
    if (!dirty) {
      const message = this.translate.instant('errors.no_changes');
      this.notificationService.warning(message);
    }
    return dirty;
  }

  protected clone(obj) {
    return structuredClone(obj);
  }
}
