import { Injectable } from '@angular/core';
import { NavigationService } from '../../../core/navigation/services/navigation.service';
import { CallerCallbackApiService } from '../../components/messages-center-callback/services/caller-callback-api.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchCondition } from '../../../shared/search/models/classes/search-condition.model';
import { MainTab } from '../../../core/navigation/models';
import { Operators } from '../../../shared/search/models/enums/operators.enum';
import { BaseNavigationService } from './base-navigation.service';
import { MessagesType } from '../../../messages/models/enums/messages-type.enum';
import { MessagesQueue } from '../../../messages/models/messages-queue.model';
import { FlatTreeNode } from '../models/flat-tree-node.model';
import { OfficesCounts } from '../models/offices-counts.model';
import { MessagesManagementService } from '../../../messages/services/messages-management.service';
import { CallerQueuesTree } from '../../../core/navigation/sitemap/models';

@Injectable()
export class MessagesNavigationService extends BaseNavigationService {

  callbackQueuesTree: MessagesQueue;
  templatesQueuesTree: MessagesQueue;

  flattedTemplatesQueuesTree: FlatTreeNode = {};

  protected get systemQueueType() {
    return 'system_queues_messages';
  }

  protected get customQueueType() {
    return 'custom_queues_messages';
  }

  protected get entityTypeGrid() {
    return '.entity.messages.grid';
  }

  get type() {
    return MessagesType.MESSAGES;
  }

  get isSupportTemplates(): boolean {
    return true;
  }

  constructor(protected navigationService: NavigationService,
              protected translateService: TranslateService,
              private callerCallbackApiService: CallerCallbackApiService,
              protected entityService: MessagesManagementService) {
    super(navigationService, translateService, entityService);
  }

  initCallbackQueuesTree(callerTree: CallerQueuesTree): MessagesQueue {
    this.navigationService.addCallbackQueuesToNavigationMap(callerTree);
    const specialData = ['phone1', 'phone2', 'authType', 'superCaller', 'uid'];
    this.callbackQueuesTree = this.walkNode(callerTree, {}, 'callback', specialData);
    return this.callbackQueuesTree;
  }

  updateCallbackQueuesTree(): Promise<MessagesQueue> {
    return this.callerCallbackApiService.getCallerTree().then((callerTree: CallerQueuesTree) => {
      return this.initCallbackQueuesTree(callerTree);
    });
  }

  updateSystemQueuesTree(): Promise<MessagesQueue> {
    if (!this.systemQueuesTree) {
      return Promise.resolve(null);
    }
    const excludeTemplates = this.createTemplatesExpression(true);
    return this.entityService.getSystemQueuesCounts(this.type, excludeTemplates).then((response) => {
      this.putCounts(response, this.flattedSystemQueuesTree);
      return Promise.resolve(this.systemQueuesTree);
    }, (reason) => {
      return Promise.reject(reason);
    });
  }

  updateCustomQueuesTree(): Promise<MessagesQueue | MessagesQueue[]> {
    if (!this.customQueuesTree) {
      return Promise.resolve(null);
    }
    const udqNames = this.customQueuesTree.nodes.map((node) => node.id);
    const excludeTemplates = this.createTemplatesExpression(true);

    return this.entityService.getMultiCustomQueuesCounts(this.type, excludeTemplates, udqNames).then((response: Array<OfficesCounts>) => {
      this.customQueuesTree.count = 0;
      for (let jj = 0; jj < response.length; jj++) {
        const node = this.customQueuesTree.nodes[jj];
        const result = response[jj];
        this.resolveSingleNode(node, result);
      }
      return Promise.resolve(this.customQueuesTree.nodes);
    }, reason => {
      return Promise.reject(reason);
    });
  }

  initTemplatesQueuesTree(): MessagesQueue {
    if (this.templatesQueuesTree) {
      return this.templatesQueuesTree;
    }
    const module = this.navigationService.getSitemapComponent(MainTab.MESSAGES_CENTER).modules['template_queues_messages'];
    if (!module) {
      return null;
    }
    return this.templatesQueuesTree = this.walkNode(module, this.flattedTemplatesQueuesTree, 'templates', null);
  }

  updateTemplatesQueuesTree(): Promise<MessagesQueue> {
    if (!this.templatesQueuesTree) {
      return Promise.resolve(null);
    }
    const onlyTemplates = this.createTemplatesExpression();
    return this.entityService.getSystemQueuesCounts(this.type, onlyTemplates).then((response) => {
      this.putCounts(response, this.flattedTemplatesQueuesTree);
      return Promise.resolve(this.templatesQueuesTree);
    }, (reason) => {
      return Promise.reject(reason);
    });
  }

  private createTemplatesExpression(notTemplate?: boolean): SearchCondition {
    notTemplate = !!notTemplate;
    return new SearchCondition(notTemplate, 'P_IS_HISTORY', Operators.equal, ['2']);
  }

  private resolveSingleNode(node: MessagesQueue, response: OfficesCounts) {
    if (!response) {
      return;
    }
    this.setCounts(node, response);
    this.customQueuesTree.count += node.count;
  }
}
