import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { GridEntityConfigService } from '../../../messages/services/grid-entity-config.service';
import { MessagesManagementService } from '../../../messages/services/messages-management.service';
import { Entity } from '../../../shared/models/enums/entity.enum';

@Injectable()
export class GridContainerConfigResolver implements Resolve<GridContainerConfig> {
  constructor(private messageManagementService: MessagesManagementService, private gridEntityConfigService: GridEntityConfigService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GridContainerConfig>|Promise<GridContainerConfig>|GridContainerConfig {
    return this.gridEntityConfigService.getMessagesConfig(route.parent.url[0].path as Entity);
  }
}
