import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'projects/gpp/src/app/core/navigation/services/navigation.service';
import { FnUiDrilldownFilterParams } from 'projects/gpp/src/app/shared/fn-ui-drilldown/models/fn-ui-drill-down-filter-params.model';
import { TableRow } from '@fgpp-ui/components';
import { RuleTypeService } from '../../../services/rule-type.service';
import { Rule, RuleMetadata, RuleStatuses, RuleTypeDetails } from '../../models';
import { Dates, RuleTypesToDisableLayerClassification } from '../../models/consts/rule-config.const';
import { RuleCommonService } from '../../services/rule-common.service';
import { RuleConditionsService } from '../../services/rule-conditions.service';
import { RuleService } from '../../services/rule.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-rule-details',
  templateUrl: './rule-details.component.html',
  styleUrls: ['./rule-details.component.scss']
})
export class RuleDetailsComponent implements OnInit {

  @Input() metadata: RuleMetadata;
  @Input() rule: Rule;
  @Input() ruleStatuses: RuleStatuses;
  @Input() isEditable: boolean;

  @Output() panelOpened = new EventEmitter<void>();
  @Output() afterPanelExpanded = new EventEmitter<void>();
  @Output() afterPanelCollapsed = new EventEmitter<void>();

  departmentOptions: Array<string>;
  actionTypeOptions = [] as Array<string>;

  inParamsRuleTypeFilter: FnUiDrilldownFilterParams;
  inParamsSubTypeFilter: FnUiDrilldownFilterParams;
  inParamsLayerClassificationFilter: FnUiDrilldownFilterParams;
  inParamsActionFilter: FnUiDrilldownFilterParams;
  inParamsSecActionFilter: FnUiDrilldownFilterParams;

  ruleTypesProfileId = 543;
  layerClassificationProfileId = 645;

  MIN_EFFECTIVE_DATE: string | number;
  MIN_EXPIRY_DATE: string | number;

  ruleActionType: string;
  ruleActionContentSource: string;

  isRuleInCreateMode: boolean;
  isDisabledExpiryDate: boolean;
  isFirstActionMissing = true;
  shouldRenderDatePicker = true;

  disableFormFields = true;
  disableAssociationDetails = true;
  disableActions = false;
  disableLayerClassification = false;
  disableActionType: boolean;

  showRuleSubType: boolean;
  showRuleAction: boolean;
  showRuleSecAction: boolean;

  constructor(public ruleCommonService: RuleCommonService,
              public ruleConditionsService: RuleConditionsService,
              private translateService: TranslateService,
              private navigationService: NavigationService,
              private ruleTypeService: RuleTypeService,
              private ruleService: RuleService) { }

  ngOnInit(): void {
    this.isRuleInCreateMode = this.ruleStatuses.isCreate;
    this.isDisabledExpiryDate = this.rule.BASE_CONDITION_IND === '1';

    this.getDepartments();
    this.getRuleTypeInParams();
    this.initActionDetails();

    if (this.ruleTypeService.getCount() === 1 && this.isRuleInCreateMode) {
      this.onRuleTypeSelected([this.ruleTypeService.get(this.ruleTypeService.toArray()[0])]);
    }
    if (this.rule.RULE_SUB_TYPE !== '' || this.rule.RULE_SUB_TYPE !== null) {
      this.handleRuleSubType();
    }

    this.MIN_EFFECTIVE_DATE = this.rule.businessDate;
    if (!this.ruleStatuses.isCreate) {
      this.initEffectiveExpiryDates();
    }
  }

  onPanelOpen(): void {
    this.panelOpened.emit();
  }

  afterPanelExpand(): void {
    this.afterPanelExpanded.emit();
  }

  afterPanelCollapse(): void {
    this.afterPanelCollapsed.emit();
  }

  onRuleTypeSelected(selectedRows: Array<TableRow>): void {
    if (!selectedRows || !selectedRows.length) {
      return;
    }
    this.disableActions = true;
    this.disableFormFields = false;
    this.disableAssociationDetails = false;
    this.rule.LAYER_CLASSIFICATION = '';
    this.rule.LAYER_CLASSIFICATION_ALIAS = '';
    this.rule.ALL_LAYER = '';
    const selected = selectedRows[0];
    this.ruleCommonService.rule.RULE_TYPE_ID = selected['PRULE_TYPES-RULE_TYPE_ID'];
    this.rule.RULE_TYPE_ID = selected['PRULE_TYPES-RULE_TYPE_ID'];
    this.rule.BASE_CONDITION_IND = this.rule.RULE_TYPE_ID === '45' ? '1' : '0';//number or string
    this.rule.RULE_TYPE_NAME = selected['PRULE_TYPES-RULE_TYPE_NAME'];
    this.rule.CLASSIFICATION_LEVEL = selected['PRULE_TYPES-CLASSIFICATION_LEVEL'];
    this.handleLayerClassificationForSpecificRuleType(this.rule.RULE_TYPE_ID);

    this.ruleService.getRuleTypeDetails(selected['PRULE_TYPES-RULE_TYPE_ID'], this.rule.OFFICE)
      .subscribe({
        next: (data: RuleTypeDetails) => this.resolveRuleTypeDetails(data),
        error: (error: HttpErrorResponse) => this.rejectRuleTypeDetails(error)
      });
  }

  onSubTypeSelect(selectedRows: Array<TableRow>): void {
    this.rule.LAYER_CLASSIFICATION = '';
    this.rule.LAYER_CLASSIFICATION_ALIAS = '';
    this.rule.ALL_LAYER = '';
    this.rule.RULE_ACTION = '';
    this.disableFormFields = false;
    this.handleOutputParams(this.rule.subType.outputParams, selectedRows);
    this.enableActions();
  }

  onLayerClassificationSelected(selectedRows: Array<TableRow>): void {
    this.rule.RULE_ACTION = '';
    this.rule.SEC_RULE_ACTION = '';
    this.disableActions = false;
    this.isFirstActionMissing = true;
    const selected = selectedRows[0];
    this.rule.LAYER_CLASSIFICATION = selected['LAYER_CLASSIFICATION_RELATIONS-CLASSIFICATION_KEY'];
    this.rule.LAYER_CLASSIFICATION_ALIAS = selected['LAYER_CLASSIFICATION_RELATIONS-ALIAS'];
    this.ruleCommonService.LAYER_CLASSIFICATION_RELATION = selected['LAYER_CLASSIFICATION_RELATIONS-CLASSIFICATION_RELATIONS'];
    this.rule.ALL_LAYER = this.rule.LAYER_CLASSIFICATION + ',' + this.ruleCommonService.LAYER_CLASSIFICATION_RELATION;
    this.getActionFilter();
  }

  onRuleActionTypeChange(actionType: string): void {
    if (actionType === 'SET') {
      this.showRuleAction = true;
      this.showRuleSecAction = this.rule.actionDetails.secActionContentSource !== null;
      this.clearRuleActions();
      this.initActionDetails();
      return;
    }

    if (actionType === 'STOP') {
      this.rule.RULE_ACTION = 'STOP';
      this.rule.RULE_ACTION_UID = 'STOP';
      this.hideActionsControls();
      return;
    }

    this.hideActionsControls();
    this.clearRuleActions();
  }

  onActionSelect(selectedRows: Array<TableRow>): void {
    this.handleOutputParams(this.rule.actionDetails.actionOutputParams, selectedRows);
    this.isFirstActionMissing = false;
    this.checkSecAction();
  }

  onSecActionSelect(selectedRows: Array<TableRow>): void {
    this.handleOutputParams(this.rule.actionDetails.secActionOutputParams, selectedRows);
  }

  private getDepartments(): void {
    this.departmentOptions = this.navigationService.getOfficeDepartments(this.rule.OFFICE);
  }

  private getRuleTypeInParams(): void {
    this.inParamsRuleTypeFilter = {
      searchFilter: {
        'PRULE_TYPES.VISIBLE_IN_LIST': '1'
      },
      ExternalParams: 'RULE_TYPE=WRITE',
      Office: this.rule.OFFICE
    };
  }

  private getSubTypeFilter(): void {
    this.inParamsSubTypeFilter = {
      type: 'SUB_TYPE',
      searchFilter: this.handleInputParams(this.rule.subType.inputParams)
    };
  }

  private getLayerClassificationInParams(): void {
    this.inParamsLayerClassificationFilter = {
      ExternalParams: this.rule.RULE_TYPE_ID
    };
  }

  private getActionFilter(): void {
    if (this.rule.ALL_LAYER !== undefined && this.rule.ALL_LAYER !== '' && this.rule.actionDetails.actionContentSource?.indexOf('P_=') === 0) {
      if ((this.rule.subType.profileId === '' && this.rule.actionDetails.actionInputParams.INPUT_PARAMS_ORDER.length === 1) ||
        (this.rule.subType.profileId !== '' && this.rule.actionDetails.actionInputParams.INPUT_PARAMS_ORDER.length === 2)) {
        this.rule.actionDetails.actionInputParams.INPUT_PARAMS_ORDER.push('PRULES.LAYER_CLASSIFICATION');
      }
      const layerclassifications = this.rule.ALL_LAYER.split(',');
      this.rule.actionDetails.actionInputParams['PRULES.LAYER_CLASSIFICATION'] = layerclassifications;
    }
    this.inParamsActionFilter = {
      type: 'ACTION',
      PROFILE_OFFICE: this.rule.OFFICE,
      searchFilter: this.handleInputParams(this.rule.actionDetails.actionInputParams)
    };
  }

  private getSecActionFilter(): void {
    this.inParamsSecActionFilter = {
      type: 'SEC_ACTION',
      PROFILE_OFFICE: this.rule.OFFICE,
      searchFilter: this.handleInputParams(this.rule.actionDetails.secActionInputParams)
    };
  }

  private resolveRuleTypeDetails(data: RuleTypeDetails): void {
    this.initVisibilityEffectiveExpiryDates();
    this.rule.EFFECTIVE_DATE = data.businessDate;
    this.rule.EXPIRY_DATE = Dates.DEFAULT_MAX_DATE_TIMESTAMP;
    this.rule.actionDetails = data.ruleAction || this.rule.actionDetails;
    this.rule.businessDate = data.businessDate;
    this.clearRuleActions();
    this.renderDatePickers();
    this.getRuleSubType(data);
    this.initActionDetails();
    this.getLayerClassificationInParams();
    this.MIN_EFFECTIVE_DATE = dayjs(+this.rule.businessDate).startOf('day').valueOf();

    const minExpiryDate = this.rule.EFFECTIVE_DATE < this.rule.businessDate ? this.rule.businessDate : this.rule.EFFECTIVE_DATE;
    this.MIN_EXPIRY_DATE = dayjs(+minExpiryDate).startOf('day').valueOf();
  }

  private renderDatePickers(): void {
    this.shouldRenderDatePicker = false;
    setTimeout(() => this.shouldRenderDatePicker = true);
  }

  private getRuleSubType(data: RuleTypeDetails): void {
    this.rule.subType = data.ruleSubType || this.rule.subType;
    this.rule.RULE_SUB_TYPE = '0';
    this.handleRuleSubType();
  }

  private rejectRuleTypeDetails(error: HttpErrorResponse): void {
    console.error('Exception', error);
  }

  private handleRuleSubType(): void {
    if (this.rule.subType.profileId !== '') {
      this.showRuleSubType = true;
      this.disableFormFields = true;
      if (this.rule.subType.profileId.indexOf('P_=') === 0) {
        this.rule.subType.profileId = this.rule.subType.profileId.substring(3);
      }
      this.getSubTypeFilter();
      if (this.isRuleInCreateMode) {
        this.rule.RULE_SUB_TYPE = '';
        this.blockActionsUntilSubTypeChosen();
      }
    } else {
      this.rule.RULE_SUB_TYPE = '0';
      this.showRuleSubType = false;
    }
  }

  private handleLayerClassificationForSpecificRuleType(ruleTypeId: string): void {
    if (RuleTypesToDisableLayerClassification.indexOf(ruleTypeId) !== -1) {
      this.enableActions();
      this.disableActions = false;
      this.rule.LAYER_CLASSIFICATION_ALIAS = 'Core';
      this.disableLayerClassification = true;
    } else {
      this.disableActions = true;
      this.disableLayerClassification = false;
    }
  }

  private initEffectiveExpiryDates(): void {
    if (!this.rule.EXPIRY_DATE) {
      this.rule.EXPIRY_DATE = Dates.DEFAULT_MAX_DATE_TIMESTAMP;
    }
    this.initVisibilityEffectiveExpiryDates();
  }

  private initVisibilityEffectiveExpiryDates(): void {
    //TODO fix this after merge, we need to get an indication from the server about the effective date
    // this.isDisabledEffectiveDate = this.metadata['PRULES-EFFECTIVE_DATE'].isReadOnly;
    // this.isDisabledExpiryDate = this.metadata['PRULES-EXPIRY_DATE'].isReadOnly;
  }

  private initActionDetails(): void {
    this.actionTypeOptions.length = 0;

    if (this.rule.RULE_TYPE_ID === '45') { //Base Condition
      this.actionTypeOptions = ['MET'];
      this.ruleActionType = 'MET';
      this.disableActionType = true;
      this.hideActionsControls();
      return;
    }

    if (this.rule.RULE_ACTION_UID === 'STOP' || this.rule.RULE_ACTION === 'STOP') {
      this.ruleActionType = 'STOP';
      this.actionTypeOptions = ['STOP'];
      if (this.rule.actionDetails.actionContentSource === null) {
        this.disableActionType = true;
      } else {
        this.actionTypeOptions.push('SET');
      }
      this.hideActionsControls();
      return;
    }

    const actionContentSource = this.rule.actionDetails.actionContentSource;

    if (actionContentSource != null) {
      this.ruleActionContentSource = actionContentSource.substring(3);

      if (actionContentSource.indexOf('V_=') === 0) {
        this.ruleActionType = actionContentSource.substring(3);
        this.actionTypeOptions.push(actionContentSource.substring(3));
        this.disableActionType = true;
        this.rule.RULE_ACTION_UID = this.ruleActionType;
        this.hideActionsControls();
      } else if (actionContentSource.indexOf('P_=') === 0) {
        this.ruleActionType = 'SET';
        this.actionTypeOptions.push('SET');
        this.checkAction();
        this.checkSecAction();
      } else if (actionContentSource === 'SELECT') {
        this.ruleActionType = 'SET';
        this.actionTypeOptions.push('SET');
        this.ruleActionContentSource = 'SELECT';
        this.checkAction();
        this.checkSecAction();
      }
    }
    this.checkStopActionType();
  }

  private checkAction(): void {
    this.disableActionType = false;
    this.showRuleAction = true;
    if (this.rule.actionDetails.actionLabel === null) {
      this.rule.actionDetails.actionLabel = this.translateService.instant('rules.details.action.label');
    }
    this.getActionFilter();
  }

  private checkSecAction(): void {
    if (this.rule.actionDetails.secActionContentSource !== null) {
      if (this.rule.actionDetails.secActionContentSource.indexOf('P_=') === 0) {
        this.rule.actionDetails.secActionContentSource =
          this.rule.actionDetails.secActionContentSource.substring(3);
      }
      this.showRuleSecAction = true;
      if (!this.isRuleInCreateMode && this.rule.SEC_RULE_ACTION !== '') {
        //enable second action if rule is in edit mode and there is a sec action
        this.isFirstActionMissing = false;
      }
      this.getSecActionFilter();
    } else {
      this.showRuleSecAction = false;
    }
  }

  private checkStopActionType(): void {
    if (this.rule.actionDetails.stopEnabled) {
      this.actionTypeOptions.push('STOP');
      if (this.ruleActionType === undefined || this.ruleActionType == null ||
        this.actionTypeOptions.length === 1) {
        this.ruleActionType = 'STOP';
        this.showRuleAction = false;
      }
    }
  }

  private hideActionsControls(): void {
    this.showRuleAction = false;
    this.showRuleSecAction = false;
  }

  private handleOutputParams(outputParams: { [key: string]: any }, selectedRows: Array<TableRow>): void {
    const keys = Object.keys(outputParams);
    for (let i = 0; i < keys.length; i++) {
      let ruleParam;
      if (keys[i].indexOf('.') > -1) {
        ruleParam = keys[i].split('.')[1];
      }

      let value = selectedRows[0][outputParams[keys[i]]];
      if (typeof value === 'undefined') {
        value = selectedRows[0][outputParams[keys[i]].replace('.', '-')];
      }

      this.rule[ruleParam] = value;
    }
  }

  private handleInputParams(inputParams: { [key: string]: any }): { [key: string]: any } {
    const inputParamsCopy = JSON.parse(JSON.stringify(inputParams)); //we do not want to override original data
    for (const key in inputParamsCopy) {
      if (inputParamsCopy[key]) {
        const index = inputParamsCopy[key].indexOf('.');
        if (index > -1) {
          const param = inputParamsCopy[key].substring(index + 1);
          inputParamsCopy[key] = this.rule[param];
          //workaround - in case the filter has the "RULE_TYPE_ID" field, we push it into array so the operator will be "in" instead of "like"
          if ('RULE_TYPE_ID' === param) {
            const arr = [];
            arr.push(inputParamsCopy[key]);
            inputParamsCopy[key] = arr;
          }
        }
      }
    }
    return inputParamsCopy;
  }

  private blockActionsUntilSubTypeChosen(): void {
    this.disableActions = true;
  }

  private enableActions(): void {
    this.initActionDetails();
    if (this.rule.LAYER_CLASSIFICATION != null && this.rule.LAYER_CLASSIFICATION !== '' && this.isFirstActionMissing === true) {
      this.disableActions = false;
    } else {
      this.disableActions = true;
    }
  }

  private clearRuleActions(): void {
    this.rule.RULE_ACTION = '';
    this.rule.RULE_ACTION_UID = '';
    this.rule.SEC_RULE_ACTION = '';
    this.rule.SEC_RULE_ACTION_UID = '';
  }

}
