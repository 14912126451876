import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ProfilesManagementService} from "../../services/profiles-management.service";
import {Observable} from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoadTypeResolver implements Resolve<void> {
  constructor(private profilesManagementService: ProfilesManagementService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean | any {
    if(route.params.profileId !== this.profilesManagementService.profileId)
      this.profilesManagementService.loadType = null;
  }
}
