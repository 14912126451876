import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { FormsService } from '../../../forms/services/forms.service';

@Injectable()
export class CreateFormDataResolver implements Resolve<any> {
  constructor(private formsService: FormsService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    const params = route.queryParams;
    return this.formsService.getCreatePaymentForm(params).then(
      (res) => res.payload.MODEL,
      (error: HttpErrorResponse) => {
        console.log(error);
        this.router.navigate(['/home/page-not-found'], { replaceUrl: true });
      }
      );
  }
}
