import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainViewComponent } from '../base-view/components/main-view/main-view.component';
import { TabTaskBarResolver } from '../resolvers/tab-task-bar.resolver';
import { TaskBarItemComponentResolver } from '../resolvers/task-bar-item-component-resolver';
import { InsightTaskbarGuard } from './guards/insight-task-bar.guard';
import { PowerBIReportGuard } from './guards/power-bi-report.guard';
import { PowerBITaskbarGuard } from './guards/power-bi-task-bar.guard';
import { InsightTaskbarResolver } from './resolvers/insight-task-bar.resolver';
import {DashboardComponent} from "./components/dashboard/dashboard.component";

export const InsightRoutes: Routes = [
    { path: '', canActivate: [InsightTaskbarGuard], component: MainViewComponent },
    {
        path: ':taskBarItem',
        component: MainViewComponent,
        canActivate: [PowerBITaskbarGuard],
        canDeactivate: [PowerBITaskbarGuard],
        resolve: {
            taskBar: TabTaskBarResolver,
            insightTaskBar: InsightTaskbarResolver,
            taskBarComponent: TaskBarItemComponentResolver,
        },
        children: [
            {
                path: '',
                canActivate: [PowerBIReportGuard],
                component: DashboardComponent,
                runGuardsAndResolvers: 'always',
            },
        ]
    },
]

@NgModule({
    imports: [
        RouterModule.forChild(InsightRoutes)
    ],
    providers: [

    ],
    exports: [RouterModule]
})
export class InsightRoutingModule { }
