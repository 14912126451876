import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { ComponentManagerService, FormService } from '@fgpp-ui/components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDynamicModalDialogData } from '../../interfaces/dynamic-modal-dialog-data.interface';
import { Base64 } from 'js-base64';

@Injectable()
@Component({
  selector: 'app-profile-audit-trail',
  templateUrl: './profile-audit-trail.component.html',
  styleUrls: ['./profile-audit-trail.component.scss'],
  providers: [FormService, ComponentManagerService]
})
export class ProfileAuditTrailComponent {
  @Output() onClose = new EventEmitter<void>();
  @Input() dataEndpoint: string;
  public isGridView = true;
  public detailEndpoint: string = null;
  public changeUserId: string;
  public changeDateTime: string;
  public endpoint;
  constructor(
              @Inject(MAT_DIALOG_DATA) public dialogConfig: IDynamicModalDialogData,
              private matDialogRef: MatDialogRef<ProfileAuditTrailComponent>) {
    this.endpoint = dialogConfig;
  }

  rowClick(row) {
    this.detailEndpoint = this.endpoint + '/' + Base64.encodeURL(row[0]['VIRTUAL.UID']);
    this.changeDateTime = row['PROFILE_UPDATE.TIME_STAMP'];
    this.changeUserId = row['PROFILE_UPDATE-INITIATOR'];
    this.isGridView = false;
  }

  returnToGrid() {
    this.isGridView = true;
    this.changeDateTime = undefined;
    this.changeUserId = undefined;
  }

  close(): void {
    this.onClose.emit();
    this.matDialogRef.close();
  }

}
