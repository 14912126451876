import { Component, ComponentFactoryResolver, Injector, ApplicationRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormatsService } from '@fgpp-ui/components';
import { ExternalFilterGridComponent } from '../external-filter-grid/external-filter-grid.component';
import { SearchQueryBuilderService } from '../../../shared/services/search-query-builder.service';
import { ExportService } from '../../../shared/fn-ui-export/services/export.service';
import { DrillDownGridConfig } from './models/consts/drill-down-grid-config.const';
import { DrillDownGridService } from '../../services/drill-down-grid.service';
import { ColumnSettingsService } from '../../services/column-settings.service';
import { FilterMapperService } from '../../services/filter-mapper.service';
import { ActionFilterService } from '@fgpp-ui/components';
import { ActionFilterMapperService } from '@fgpp-ui/components';
import { GridEventsService } from '../../../azure-insight/services/grid-events.service';
import { UserSettingsService } from '../../../core/user-settings/services/user-settings.service';

@Component({
  selector: 'app-drill-down-grid',
  templateUrl: './drill-down-grid.component.html',
  styleUrls: ['./drill-down-grid.component.scss']
})
export class DrillDownGridComponent extends ExternalFilterGridComponent {

  constructor(route: ActivatedRoute,
              userSettingsService: UserSettingsService,
              public drillDownGridService: DrillDownGridService,
              gridEventsService: GridEventsService,
              searchQueryBuilderService: SearchQueryBuilderService,
              columnSettingsService: ColumnSettingsService,
              exportService: ExportService,
              filterMapperService: FilterMapperService,
              actionFilterMapperService: ActionFilterMapperService,
              actionFilterService: ActionFilterService, resolver: ComponentFactoryResolver,
              injector: Injector, app: ApplicationRef,
              cdr: ChangeDetectorRef, protected formatsService: FormatsService) {
    super(route, userSettingsService, gridEventsService, drillDownGridService, searchQueryBuilderService, columnSettingsService,
      exportService, filterMapperService, actionFilterMapperService, actionFilterService, resolver, injector, app, cdr, formatsService);
  }

  setContainerConfig() {
    this.gridContainerConfig = DrillDownGridConfig;
  }
}
