import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslationsHandlerService } from '../../core/translations/services/translations-handler.service';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';

@Injectable({ providedIn: 'root' })
export class TranslationsResolver implements Resolve<Object> {
  constructor(private userSettingsService: UserSettingsService, private translationsHandlerService: TranslationsHandlerService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Object>|Promise<Object>|Object {
    return this.translationsHandlerService.setTranslation(this.userSettingsService.getPreferences().language);
  }
}
