import { Routes } from '@angular/router';
import { RedirectRulesGuard } from '../../rules/routing/guards/redirect-rules.guard';
import { RuleTypesGuard } from '../../rules/routing/guards/rule-types.guard';
import { MainViewComponent } from '../components/main-view/main-view.component';
import { TabTaskBarResolver } from '../../resolvers/tab-task-bar.resolver';
import { TaskBarItemComponentResolver } from '../../resolvers/task-bar-item-component-resolver';
import { MainTab } from '../../core/navigation/models';
import { NavigationTreeResolver } from '../../profiles/routing/resolvers/navigation-tree.resolver';
import { LoadTypeResolver } from '../../profiles/routing/resolvers/load-type.resolver';
import { ProfilesCenterViewComponent } from '../../profiles/routing/components/profiles-center-view/profiles-center-view.component';
import { DeactivateProfileGuard } from '../../profiles/routing/guards/deactivate-profile.guard';
import { ProfileGridConfigResolver } from '../../profiles/routing/resolvers/profile-grid-config.resolver';
import { IsNewUiProfileResolver } from '../../profiles/routing/resolvers/is-new-ui-profile.resolver';
import { Entity } from '../../shared/models/enums/entity.enum';
import { RedirectTaskBarItemGuard } from '../../profiles/routing/guards/redirect-task-bar-item.guard';
import { RuleTaskBarComponentResolver } from '../../resolvers/rule-task-bar-component-resolver';
import { RulesMainViewComponent } from './rules-main-view/rules-main-view.component';
import { RulesLandingPageComponent } from '../../rules/components/landing-page/landing-page.component';
import { RuleFormComponent } from '../../rules/single-rule/components/rule-form/rule-form.component';
import { RuleMetadataResolver } from '../../rules/routing/resolvers/rule-metadata.resolver';
import { RuleResolver } from '../../rules/routing/resolvers/rule.resolver';
import { RuleTypesResolver } from '../../rules/routing/resolvers/rule-types.resolver';
import { SingleRuleDeactivateGuard } from '../../rules/routing/guards/single-rule-deactivate.guard';
import { IsSingularProfileResolver } from '../../profiles/routing/resolvers/is-singular-profile.resolver';
import { RulesAssociationSingularProfileResolver } from '../../profiles/routing/resolvers/rules-association-singular-profile.resolver';
import {DeactivateRulesAssociationProfileGuard} from "../../rules/routing/guards/deactivate-rules-association-profile.guard";
import { NavigationGuardService } from '../../core/navigation/services/navigation-guard.service';
import { RuleGridContainerConfigResolver } from '../../rules/routing/resolvers/rule-grid-container-config.resolver';

export const rulesRoutes: Routes = [
  {
    path: '',
    canActivate: [RedirectRulesGuard],
    data: {
      tab: MainTab.RULES
    },
    component: RulesLandingPageComponent
  },
  {
    path: 'business-rules',
    pathMatch: 'prefix',
    component: RulesMainViewComponent,
    canActivate: [RuleTypesGuard, NavigationGuardService],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    data: {
      tab: MainTab.RULES,
      entityType: Entity.RULES,
    },
    resolve: {
      taskBar: TabTaskBarResolver,
      taskBarComponent: RuleTaskBarComponentResolver,
      loadType: LoadTypeResolver,
      navigationTree: NavigationTreeResolver,
      gridEntityConfig: RuleGridContainerConfigResolver,
      isNewUIProfile: IsNewUiProfileResolver,
    },
    children: [
      {
        path: 'single',
        pathMatch: 'full',
        component: RuleFormComponent,
        resolve: {
          metadata: RuleMetadataResolver,
          ruleContainer: RuleResolver,
          ruleTypes: RuleTypesResolver
        },
        canDeactivate: [SingleRuleDeactivateGuard]
      }
    ]
  },
  {
    path: ':taskBarItem',
    component: MainViewComponent,
    canActivate: [RedirectTaskBarItemGuard],
    runGuardsAndResolvers: 'always',
    data: {
      tab: MainTab.RULES,
      type: Entity.PROFILES
    },
    resolve: {
      taskBar: TabTaskBarResolver,
      taskBarComponent: TaskBarItemComponentResolver,
      loadType: LoadTypeResolver,
      navigationTree: NavigationTreeResolver,
    },
    children: [
      {
        path: ':profileId',
        data: {
          entityType: Entity.PROFILES
        },
        component: ProfilesCenterViewComponent,
        canActivate: [NavigationGuardService],
        canDeactivate: [DeactivateProfileGuard, DeactivateRulesAssociationProfileGuard],
        resolve: {
          gridEntityConfig: ProfileGridConfigResolver,
          isNewUIProfile: IsNewUiProfileResolver,
          isSingularProfile: IsSingularProfileResolver,
          rulesAssociationSingleView: RulesAssociationSingularProfileResolver,
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
      }
    ]
  }
];
