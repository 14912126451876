import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { GridContainerConfig } from '../../../grid/models/grid-container-config.model';
import { ProfileGridConfigResolver } from '../../../profiles/routing/resolvers/profile-grid-config.resolver';
import { RULE_TYPE_DELIMITER } from '../../models/consts/rule-type.consts';
import { RuleTypePermissions } from '../../models/rule-type-permissions.model';
import { RuleTypesAccessService as RuleTypesAccessService } from '../../services/rule-types-access.service';

@Injectable()
export class RuleGridContainerConfigResolver implements Resolve<GridContainerConfig> {

  constructor(private ruleTypesAccessService: RuleTypesAccessService,
              private profileGridConfigResolver: ProfileGridConfigResolver) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GridContainerConfig> {
    return this.getRuleTypePermissions().pipe(map(() => this.getGridContainerConfig(route, state)));
  }

  private getRuleTypePermissions(): Observable<RuleTypePermissions> {
    return this.ruleTypesAccessService.getPermissions();
  }

  private getGridContainerConfig(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): GridContainerConfig {
    const ruleTypes = route.queryParams.ruleType?.split(RULE_TYPE_DELIMITER);
    const disableCreate = this.shouldDisableCreate(ruleTypes);
    const config = JSON.parse(JSON.stringify(this.profileGridConfigResolver.resolve(route, state)));
    config.gridHeaderConfig.disableCreate = disableCreate;
    return config;
  }

  private shouldDisableCreate(ruleTypes: Array<string>): boolean {
    if (ruleTypes?.length) {
      return ruleTypes.every((ruleType: string) => !this.ruleTypesAccessService.hasWritePermission(ruleType));
    }
    return false;
  }
}
