import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { NavigationService } from '../../../core/navigation/services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class IsSingularProfileResolver implements Resolve<boolean> {

  constructor(private navigationService: NavigationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const navigationItem = this.navigationService.getNavigationItem(route.params.profileId);
      const action = navigationItem.data.action;
      return action !== 'navigateToProfileList';
    }
}
