import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPreferences } from '../../core/user-settings/models/user-preferences.model';
import { UserSettingsService } from '../../core/user-settings/services/user-settings.service';

@Injectable({ providedIn: 'root' })
export class UserPreferencesResolver implements Resolve<UserPreferences> {
  constructor(private userSettingsService: UserSettingsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserPreferences>|Promise<UserPreferences>|UserPreferences {
    return this.userSettingsService.get();
  }
}
